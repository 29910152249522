import { Card, CardContent, CardFooter, CardHeader } from '@/components/Card'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useForm, UseFormReturn } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { JobNote } from '@/pages/common/Schedule/Job/JobNote'
import { JobType } from '@/pages/common/Schedule/Job/JobType'
import { OneOffJobSchedule } from '../../../../common/Schedule/Job/OneOffJobSchedule'
import {
    CreateJobWithQuoteType,
    CreateJobWithoutQuoteType,
    manualJobSchema,
} from '@/api/job/schema'
import { Form } from '@/components/Forms'
import CalendarComponent from '../../../../common/Schedule/Job/FullCalendar'
import { AssignCrew } from '@/pages/common/Schedule/Job/AssignCrew'
import { Button } from '@/components/Button'
import { NotesAndAttachments } from '@/pages/common/Schedule/Job/NotesAndAttachments'
import { useEffect, useState } from 'react'
import { convertQuoteToJob, getJobNumber } from '@/api/job'
import { formatRecordNumber } from '@/utils/helper'
import dayjs from 'dayjs'
import {
    ISO_8601_WITH_UTC_OFFSET,
    APPOINTMENT_TYPES,
    RECURRING_TYPES,
    BREADCRUMBS_PADDING_STYLE,
    SHOULD_VALIDATE_TRUE,
    DEFAULT_PRODUCT_SERVICE_GROUP_ITEM,
    FEATURE_RESTRICTIONS,
} from '@/constants'
import { useAtom, useSetAtom } from 'jotai'
import CustomerDetailsWithoutQuote from '@/pages/common/Schedule/Job/CustomerDetailsWithoutQuote'
import { customerQuoteAtom } from '@/store/owner'
import ProductServiceWithoutQuote from '@/pages/common/Schedule/Job/ProductServiceWithoutQuote'
import { saveQuote } from '@/api/quoting'
import { useAppNavigate } from '@/hooks/useAppNavigate'
import { useToast } from '@/hooks/useToast'
import RecurringJobSchedule from '@/pages/common/Schedule/Job/RecurringJobSchedule'
import BreadCrumbs from '@/components/BreadCrumbs'
import { AxiosError } from 'axios'
import { validateSchedule } from '@/api/appointment'
import { expenseAtomToNewJobAtom, productServiceAtomToNewJobAtom } from '@/store/job'
import { formatToCustomerTime, formatToUTCWithOffset } from '@/utils/time'
import useValidateFormTime from '@/hooks/useValidateFormTime'
import { getQuoteNumber } from '@/api/quoting'
import JobCrewMemberModal from '@/components/JobCrewMemberModal'
import { ValidateScheduleType } from '@/api/appointment/schema'
import { checkSubscriptionFeatureRestriction } from '@/api/subscription'
import useViewedAsUser from '@/hooks/useViewedAsUser'

const CreateJobWithoutQuote = () => {
    const queryClient = useQueryClient()
    const { toast } = useToast()
    const { navigateTo } = useAppNavigate()
    const [crewMemberModal, setCrewMemberModal] = useState<boolean>(false)
    const [isScheduleAvailable, setIsScheduleAvailable] = useState<boolean>(false)
    const [customer, setCustomer] = useAtom(customerQuoteAtom)

    const setProductServiceToNewJob = useSetAtom(productServiceAtomToNewJobAtom)
    const setExpensesToNewJob = useSetAtom(expenseAtomToNewJobAtom)
    const [submitting, setSubmitting] = useState<boolean>(false)

    const user = useViewedAsUser()

    const { data: jobNumber } = useQuery({
        queryKey: ['jobNumber', user?.businessId],
        queryFn: () => getJobNumber(user?.businessId as string),
        enabled: !!user?.businessId,
    })

    const { data: quoteNumber } = useQuery({
        queryKey: ['quoteNumber', user?.businessId],
        queryFn: () => getQuoteNumber(user?.businessId as string),
        enabled: !!user?.businessId,
    })

    const methods = useForm<CreateJobWithoutQuoteType>({
        defaultValues: {
            appointment: {
                appointmentType: APPOINTMENT_TYPES.ONEOFF_JOB,
                occurrences: 1,
                repeat: RECURRING_TYPES.WEEKLY,
                startDateTime: dayjs()
                    .hour(9)
                    .minute(0)
                    .second(0)
                    .millisecond(0)
                    .format(ISO_8601_WITH_UTC_OFFSET),
                endDateTime: dayjs()
                    .hour(17)
                    .minute(0)
                    .second(0)
                    .millisecond(0)
                    .format(ISO_8601_WITH_UTC_OFFSET),
            },
            description: '',
            jobImages: [],
            note: '',
            quote: {
                expense: [],
                productServiceGroup: [DEFAULT_PRODUCT_SERVICE_GROUP_ITEM],
                laborCost: 0,
                subTotal: 0,
                totalAmount: 0,
            },
        },
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(manualJobSchema),
    })

    const {
        clearErrors,
        handleSubmit,
        setError,
        setValue,
        watch,
        formState: { errors, isValid },
    } = methods

    const watchedFields = watch()

    const [
        appointmentType,
        occurrences,
        crewId,
        startDT,
        endDT,
        customerProfile,
        repeat,
        availableAnytime,
    ] = watch([
        'appointment.appointmentType',
        'appointment.occurrences',
        'crewId',
        'appointment.startDateTime',
        'appointment.endDateTime',
        'profile',
        'appointment.repeat',
        'appointment.availableAnytime',
        'quote.laborCost',
        'quote.productServiceGroup',
        'quote.subTotal',
        'quote.totalAmount',
    ])

    const hasErrors = !!Object.keys(errors)?.length

    const validateScheduleMu = useMutation<boolean, AxiosError, ValidateScheduleType>({
        mutationFn: validateSchedule,
        onSuccess: (data) => setIsScheduleAvailable(data),
        onError: () =>
            toast({
                description: 'ERROR: Unable to save job',
                variant: 'destructive',
            }),
    })

    const jobToQuoteMu = useMutation<undefined, undefined, CreateJobWithQuoteType>({
        mutationFn: convertQuoteToJob,
        onSuccess: () => {
            setCustomer(null)
            navigateTo('/schedule/jobs')
            toast({
                description: 'Job successfully created!',
                variant: 'default',
            })
            queryClient.invalidateQueries({ queryKey: ['Notifications'] })
            setSubmitting(false)
            setCrewMemberModal(false)
        },
    })

    const { data: isFeatureAvailable, isLoading: featureLoading } = useQuery<boolean>({
        queryKey: ['createJobFeatureRestriction', user?.businessId],
        queryFn: () =>
            checkSubscriptionFeatureRestriction(
                user?.businessId as string,
                FEATURE_RESTRICTIONS.customFields,
            ),
    })

    const createQuoteMU = useMutation({
        mutationFn: saveQuote,
    })

    const handleCancelClick = () => {
        setProductServiceToNewJob(null)
        setExpensesToNewJob(null)
        navigateTo('/schedule/jobs')
    }

    const createJob = async (data: CreateJobWithoutQuoteType | CreateJobWithQuoteType) => {
        try {
            const parsedData = manualJobSchema.parse(data)
            parsedData.quote.productServiceGroup?.forEach((item) => {
                item.cost = item?.cost ? item?.cost * 100 : 0
            })

            const occ = occurrences as number

            const laborCost = parsedData?.quote?.laborCost ? parsedData.quote.laborCost * occ : 0
            const res = await createQuoteMU.mutateAsync({
                ...parsedData.quote,
                laborCost,
                subTotal: parsedData?.quote?.subTotal
                    ? parsedData.quote.subTotal * occ + laborCost
                    : 0,
                totalAmount: parsedData?.quote?.totalAmount
                    ? parsedData.quote.totalAmount * occ + laborCost
                    : 0,
            })

            const formatStart = formatToCustomerTime(
                parsedData.appointment.startDateTime,
                customerProfile?.address.timezone,
            )
            const formatEnd = formatToCustomerTime(
                parsedData.appointment.endDateTime,
                customerProfile?.address.timezone,
            )

            const jobDataWithQuoteId: CreateJobWithQuoteType = {
                ...parsedData,
                quoteId: res.content.quoteId,
                address: res.content.address,
                appointment: {
                    ...parsedData.appointment,
                    startDateTime: formatToUTCWithOffset(
                        formatStart,
                        res.content.address.timezone,
                        ISO_8601_WITH_UTC_OFFSET,
                    ),
                    endDateTime: formatToUTCWithOffset(
                        formatEnd,
                        res.content.address.timezone,
                        ISO_8601_WITH_UTC_OFFSET,
                    ),
                },
            }
            await jobToQuoteMu.mutateAsync(jobDataWithQuoteId)
        } catch (err) {
            toast({
                description: 'ERROR: Unable to save job',
                variant: 'destructive',
            })
        }
    }

    const onSubmit = (data: CreateJobWithoutQuoteType | CreateJobWithQuoteType) => {
        setSubmitting(true)
        const isServiceExist = watchedFields.quote.productServiceGroup?.some(
            (service) => service?.type === 'service',
        )

        if (crewId && isServiceExist) {
            createJob(data)
        } else {
            if (!isServiceExist) {
                setSubmitting(false)
                toast({
                    description: 'Must have at least one service',
                    variant: 'default',
                })
            } else {
                setCrewMemberModal(true)
            }
        }
    }

    useEffect(() => {
        if (crewId && !availableAnytime) {
            validateScheduleMu.mutateAsync({
                crewId: crewId as string,
                profile: customerProfile,
                startDateTime: formatToUTCWithOffset(
                    formatToCustomerTime(startDT, customerProfile?.address.timezone),
                    customerProfile?.address.timezone,
                ),
                endDateTime: formatToUTCWithOffset(
                    formatToCustomerTime(endDT, customerProfile?.address.timezone),
                    customerProfile?.address.timezone,
                ),
                repeat: repeat,
                appointmentType: appointmentType,
                availableAnytime: availableAnytime,
            })
        } else {
            setIsScheduleAvailable(true)
        }
    }, [crewId, customerProfile, startDT, endDT, appointmentType, availableAnytime])

    useEffect(() => {
        if (user) {
            setValue('addressId', user.addressId, SHOULD_VALIDATE_TRUE)
            setValue('businessId', user.businessId, SHOULD_VALIDATE_TRUE)
        }
    }, [user])
    useEffect(() => {
        if (customer) {
            setValue('quote.addressId', customer.addressId, SHOULD_VALIDATE_TRUE)
            setValue('quote.businessId', user?.businessId, SHOULD_VALIDATE_TRUE)
            setValue('quote.profileId', customer.profileId, SHOULD_VALIDATE_TRUE)
            setValue('quote.status', 'approved', SHOULD_VALIDATE_TRUE)
            setValue('quote.isQuoteCreated', false, SHOULD_VALIDATE_TRUE)
            setValue(
                'quote.name',
                `${customer.firstName} ${customer.lastName}`,
                SHOULD_VALIDATE_TRUE,
            )
        }
    }, [customer])
    useEffect(() => {
        setValue(
            'appointment.startDateTime',
            dayjs().hour(9).minute(0).second(0).millisecond(0).format(ISO_8601_WITH_UTC_OFFSET),
        )
    }, [appointmentType])

    useValidateFormTime<CreateJobWithoutQuoteType>({
        endDateTime: endDT,
        endField: 'appointment.endDateTime',
        startDateTime: startDT,
        startField: 'appointment.startDateTime',
        clearErrors,
        setError,
        additionalDeps: [appointmentType, errors],
    })
    return (
        <Form {...methods}>
            <JobCrewMemberModal
                open={crewMemberModal}
                setOpen={setCrewMemberModal}
                submit={createJob}
                methods={
                    methods as UseFormReturn<CreateJobWithoutQuoteType | CreateJobWithQuoteType>
                }
                onClose={() => setSubmitting(false)}
            />
            <form
                className={`${BREADCRUMBS_PADDING_STYLE}`}
                onSubmit={handleSubmit(onSubmit)}
                encType='multipart/form-data'
            >
                <BreadCrumbs titleName='New Job' />
                <Card className='bg-zentive-bg-100 mt-4'>
                    <div className='border-4 border-zentive-green-dark'></div>
                    <CardHeader>
                        <h1 className='font-bold text-2xl'>Job For</h1>
                    </CardHeader>
                    <CardContent className='flex flex-col gap-5'>
                        <section className='flex flex-row gap-5 w-full'>
                            <CustomerDetailsWithoutQuote />
                            <JobNote
                                jobNumber={jobNumber ? formatRecordNumber(jobNumber) : ''}
                                quoteNumber={quoteNumber ? formatRecordNumber(quoteNumber) : 1}
                                featureAvailable={isFeatureAvailable as boolean}
                                isLoading={featureLoading}
                            />
                        </section>

                        <JobType />

                        <section className='flex flex-row gap-5'>
                            {appointmentType === APPOINTMENT_TYPES.ONEOFF_JOB ? (
                                <OneOffJobSchedule />
                            ) : (
                                <RecurringJobSchedule />
                            )}

                            <CalendarComponent />
                        </section>

                        <AssignCrew schedAvailable={isScheduleAvailable} />
                        <ProductServiceWithoutQuote />
                        <NotesAndAttachments />
                    </CardContent>
                    <CardFooter className='gap-5 bg-zentive-bg-100 justify-end'>
                        <Button
                            className='w-[6rem] h-[3rem]'
                            variant='outline'
                            onClick={handleCancelClick}
                        >
                            Cancel
                        </Button>
                        <Button
                            className='w-[6rem] h-[3rem]'
                            type='submit'
                            disabled={
                                hasErrors ||
                                !isValid ||
                                !isScheduleAvailable ||
                                jobToQuoteMu.isPending ||
                                validateScheduleMu.isPending ||
                                createQuoteMU.isPending ||
                                methods.formState.isSubmitting ||
                                submitting
                            }
                        >
                            {jobToQuoteMu.isPending || createQuoteMU.isPending ? 'Saving' : 'Save'}
                        </Button>
                    </CardFooter>
                </Card>
            </form>
        </Form>
    )
}

export default CreateJobWithoutQuote
