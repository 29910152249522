import { BulkProfileUpdateStatusType } from '@/api/profile/schema'
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { Button } from '@/components/Button'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { useMutation } from '@tanstack/react-query'
import {
    bulkProfileStatusRestore,
    bulkProfileStatusUpdate,
    checkForemanByProfileIds,
} from '@/api/profile'
import { useToast } from '@/hooks/useToast'
import { Dispatch, SetStateAction, useState } from 'react'
import { FaRegTrashCan } from 'react-icons/fa6'
import { cn } from '@/utils/helper'
import { useAtom } from 'jotai'

import { selectedEmployeeTabStatusAtom } from '@/store/employee'
import { USER_STATUS } from '@/constants'
import { useAppNavigate } from '@/hooks/useAppNavigate'
import DeleteProfileJobRestrictionModal from '@/components/DeleteProfileJobRestrictionModal/indexs'
import ListIncludesFormanModal from '../ListIncludesForemanModal'

interface DeleteModalProps {
    profilesToUpdate: BulkProfileUpdateStatusType
    setOpen: Dispatch<SetStateAction<boolean>>
    onSuccessRestore: Dispatch<SetStateAction<BulkProfileUpdateStatusType>>
    isEmployeeTable?: boolean
}

const getActionVerbiage = (status: string) => {
    // Apply the appropriate status verbiage
    // Even when a user is restored to ACT from INA/DEL
    switch (status) {
        case USER_STATUS.ACTIVATED:
            return 'restored'
        default:
            return 'deleted'
    }
}

const DeleteModal: React.FC<DeleteModalProps> = ({
    profilesToUpdate,
    setOpen,
    onSuccessRestore,
    isEmployeeTable,
}) => {
    const { toast } = useToast()
    const [selected, setSelected] = useAtom(selectedEmployeeTabStatusAtom)
    const { navigateTo } = useAppNavigate()
    const [restrictionModal, setRestrictionModal] = useState<boolean>(false)
    const [foremanIncludedModal, setForemanIncludedModal] = useState<boolean>(false)

    const { mutate: deleteProfilesMu, isPending: updateLoading } = useMutation({
        mutationFn: (data: BulkProfileUpdateStatusType) => bulkProfileStatusUpdate(data),
        onSuccess: () => {
            toast({
                description: `Employee profile has been successfully ${getActionVerbiage(
                    profilesToUpdate.status as string,
                )}`,
                variant: 'default',
            })
            navigateTo('/schedule/employee')

            onSuccessRestore({
                profileIds: [],
                status: '',
            })

            setSelected(
                selected === USER_STATUS.INACTIVE || selected === USER_STATUS.DELETED
                    ? USER_STATUS.ACTIVATED
                    : USER_STATUS.DELETED,
            )
        },
        onError: () => {
            onSuccessRestore({
                profileIds: [],
                status: '',
            })
            setRestrictionModal(true)
        },
    })

    const { mutate: restoreProfilesMu } = useMutation({
        mutationFn: (data: BulkProfileUpdateStatusType) => bulkProfileStatusRestore(data),
        onSuccess: () => {
            toast({
                description: `Employee profile has been successfully ${getActionVerbiage(
                    profilesToUpdate.status as string,
                )}`,
                variant: 'default',
            }),
                navigateTo('/schedule/employee')

            onSuccessRestore({
                profileIds: [],
                status: '',
            })

            setSelected(
                selected === USER_STATUS.INACTIVE || selected === USER_STATUS.DELETED
                    ? USER_STATUS.ACTIVATED
                    : USER_STATUS.DELETED,
            )
        },
        onError: (err) => console.log(err),
    })

    const { mutate: checkForemanMu } = useMutation({
        mutationFn: (data: BulkProfileUpdateStatusType) => checkForemanByProfileIds(data),
        onSuccess: () => {
            setForemanIncludedModal(true)
        },
        onError: () => {
            deleteProfilesMu(profilesToUpdate)
        },
    })

    const handleDelete = () => {
        if (selected === 'ACT') {
            // delete employee
            checkForemanMu(profilesToUpdate)
        } else {
            // restore employee
            restoreProfilesMu(profilesToUpdate)
        }
    }

    const isDirty = () => {
        if (!profilesToUpdate.profileIds?.length) return true
        return false
    }

    return (
        <AlertDialog onOpenChange={(o) => setOpen(o)}>
            <DeleteProfileJobRestrictionModal
                open={restrictionModal}
                setOpen={setRestrictionModal}
                type='employee'
            />
            <ListIncludesFormanModal
                open={foremanIncludedModal}
                setOpen={setForemanIncludedModal}
            />
            <AlertDialogTrigger asChild>
                <Button
                    variant='ghost'
                    disabled={isDirty()}
                    className={cn(
                        isEmployeeTable
                            ? 'border border-zentive-green-dark text-zentive-green-dark text-base font-semibold hover:none px-10'
                            : 'px-14 py-6 my-4 justify-center bg-red-600 border shadow-md text-white hover:none',
                    )}
                >
                    {(selected === 'ACT' || selected === 'INA') && (
                        <FaRegTrashCan className='mr-2' />
                    )}
                    {selected === 'ACT' || selected === 'INA' ? 'Delete' : 'Restore'}
                    {!isEmployeeTable && ' Account'}
                </Button>
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-2xl p-0'>
                <div className='flex gap-5 px-6 pt-10'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-16 w-16 rounded-full'>
                        <HiOutlineExclamationTriangle className='h-6 w-6 mx-5  yx-5 text-red-500' />
                    </div>
                    <div className=' overflow-auto'>
                        <AlertDialogTitle className='text-left font-semibold text-2xl text-gray-900 mt-2.5'>
                            {selected === 'ACT' || selected === 'INA' ? 'Delete' : 'Restore'}{' '}
                            Account
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-sm text-gray-500 mt-5'>
                            {selected === 'ACT' || selected === 'INA'
                                ? `This action is irreversible and will remove all user's account
                                information, settings, and data associated with user's account.`
                                : `By restoring these accounts, user will have access to services, content or features associated with the subscription plan.`}
                        </AlertDialogDescription>
                        <AlertDialogDescription className='text-left text-sm text-gray-500 mt-5'>
                            {`Are you sure you want to ${
                                selected === 'ACT' || selected === 'INA' ? 'delete' : 'restore'
                            } this account?`}
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='mx-0 bg-gray-100 rounded-b-4 pr-6 pb-4 pt-1.5'>
                    <div className='w-full flex justify-end items-end gap-4'>
                        <AlertDialogCancel className='w-97 h-11 text-base font-semibold text-zentive-green-dark ring-zentive-green-dark'>
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            disabled={updateLoading}
                            onClick={handleDelete}
                            className='w-97 h-11 text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-dark/90'
                        >
                            {selected === 'ACT' ? 'Delete' : 'Restore'}
                        </AlertDialogAction>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default DeleteModal
