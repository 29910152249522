import { Card, CardContent, CardFooter } from '@/components/Card'
import { Checkbox } from '@/components/Checkbox'
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/Table'
import Tooltip from '@/components/Tooltip'
import {
    employeeSortByAtom,
    employeeSortOrderAtom,
    selectedEmployeeTabAtom,
    selectedEmployeeTabStatusAtom,
    toExportEmployeeAtom,
} from '@/store/employee'
import { useSetAtom, useAtomValue, useAtom } from 'jotai'
import { FaArrowDownLong, FaArrowUpLong } from 'react-icons/fa6'
import { NavLink } from 'react-router-dom'
import dayjs from 'dayjs'
import { formatPhoneNumber, cn, formatRecordNumber } from '@/utils/helper'
import { EmployeeListType, EmployeeType } from '@/api/employee/schema'
import { useEffect, useState } from 'react'
import { BulkProfileUpdateStatusType } from '@/api/profile/schema'
import { USER_STATUS } from '@/constants'
import { E164Number } from 'libphonenumber-js/core'
import NoteModal from '@/components/NotesModal'

const tableHeader = [
    'Employee Number',
    'Name',
    'Pay Rate Per Hour',
    'Email Address',
    'Address',
    'Contact Number',
    'Birthday',
    'Date Hired',
    'Chemical Applicator License',
    'Notes',
]

const tableHeaderWithArrow = [
    'Employee Number',
    'Name',
    'Commission',
    'Pay Rate Per Hour',
    'Email Address',
    'Address',
    'Birthday',
    'Date Hired',
]

interface EmployeeTableProps {
    setProfilesToUpdate: React.Dispatch<
        React.SetStateAction<{
            profileIds: string[]
            status?: string | undefined
        }>
    >
    employeeListData: EmployeeListType
    profilesToUpdate: BulkProfileUpdateStatusType
}

const EmployeeTable: React.FC<EmployeeTableProps> = ({
    setProfilesToUpdate,
    profilesToUpdate,
    employeeListData,
}) => {
    const setToExportEmployee = useSetAtom(toExportEmployeeAtom)
    const selected = useAtomValue(selectedEmployeeTabStatusAtom)
    const [isCheckAll, setIsCheckAll] = useState<boolean>(false)
    const setSelectedEmployeeTab = useSetAtom(selectedEmployeeTabAtom)
    const [sortBy, setSortBy] = useAtom(employeeSortByAtom)
    const [sortOrder, setSortOrder] = useAtom(employeeSortOrderAtom)

    const handleCheckClick = (employee: EmployeeType, isCheck: boolean) => {
        if (isCheck) {
            const updatedProfileIds = [...profilesToUpdate.profileIds, employee.profileId]
            setProfilesToUpdate({
                profileIds: updatedProfileIds,
                status:
                    selected === USER_STATUS.ACTIVATED || selected === USER_STATUS.INACTIVE
                        ? USER_STATUS.DELETED
                        : USER_STATUS.ACTIVATED,
            })
            setIsCheckAll(
                profilesToUpdate.profileIds?.length + 1 === employeeListData?.content?.length,
            )

            const profilesToExport: EmployeeListType = { content: [] }
            updatedProfileIds.forEach((id) => {
                const employee = employeeListData.content.find((emp) => emp.profileId === id)
                if (employee) {
                    profilesToExport.content.push(employee)
                }
            })

            setToExportEmployee(profilesToExport)
        } else {
            const updatedProfileIds = profilesToUpdate.profileIds.filter(
                (id) => id !== employee.profileId,
            )

            setProfilesToUpdate((prev) => ({
                ...prev,
                profileIds: updatedProfileIds,
            }))
            setIsCheckAll(
                profilesToUpdate.profileIds?.length - 1 === employeeListData?.content?.length,
            )
            const profilesToExport: EmployeeListType = { content: [] }
            updatedProfileIds.forEach((id) => {
                const employee = employeeListData.content.find((emp) => emp.profileId === id)
                if (employee) {
                    profilesToExport.content.push(employee)
                }
            })
            setToExportEmployee(profilesToExport)
        }
    }

    const handleCheckAll = (checked: boolean) => {
        setIsCheckAll(!isCheckAll)
        if (employeeListData && checked) {
            const updatedProfileIds = [...profilesToUpdate.profileIds]
            employeeListData.content.forEach((employee) => {
                updatedProfileIds.push(employee.profileId)
            })
            setProfilesToUpdate({
                profileIds: updatedProfileIds,
                status:
                    selected === USER_STATUS.ACTIVATED || selected === USER_STATUS.INACTIVE
                        ? USER_STATUS.DELETED
                        : USER_STATUS.ACTIVATED,
            })

            const profilesToExport: EmployeeListType = { content: [] }
            setToExportEmployee(profilesToExport)
            updatedProfileIds.forEach((id) => {
                const employee = employeeListData.content.find((emp) => emp.profileId === id)
                if (employee) {
                    profilesToExport.content.push(employee)
                }
            })

            setToExportEmployee(profilesToExport)
        } else {
            setToExportEmployee({ content: [], meta: {} })
            setProfilesToUpdate((prev) => ({
                ...prev,
                profileIds: [],
            }))
        }
    }

    const onHandleSorting = (column: string) => {
        if (sortBy === column) {
            setSortOrder(sortOrder === 'ASC' ? 'DESC' : 'ASC')
        } else {
            setSortBy(column)
            setSortOrder('ASC')
        }
    }

    const getSortIcon = (column: string) => {
        if (sortBy === column) {
            return sortOrder === 'ASC' ? (
                <FaArrowUpLong className='mt-1 w-3 h-3' />
            ) : (
                <FaArrowDownLong className='mt-1 w-3 h-3' />
            )
        }
        return <FaArrowDownLong className='mt-1 w-3 h-3' />
    }

    useEffect(() => {
        if (Array.isArray(profilesToUpdate.profileIds) && !profilesToUpdate.profileIds?.length) {
            setIsCheckAll(false)
        }
    }, [profilesToUpdate])

    useEffect(() => {
        setToExportEmployee({ content: [], meta: {} })
    }, [])

    return (
        <Card className='overflow-x-scroll green-scrollbar'>
            <CardContent>
                <Table className='whitespace-normal w-full'>
                    <TableHeader style={{ marginBottom: '10px' }}>
                        <TableRow>
                            {tableHeader?.map((header) => (
                                <TableHead
                                    key={`${header}-head`}
                                    className={cn(
                                        'font-semibold text-zentive-gray-medium text-base whitespace-nowrap',
                                    )}
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    <div className='inline-flex whitespace-nowrap mr-[5px] mt-[5px]'>
                                        {header === 'Employee Number' && (
                                            <Checkbox
                                                id={'message-checked-status'}
                                                className='mr-[5px] mt-[5px]'
                                                onCheckedChange={handleCheckAll}
                                                checked={isCheckAll}
                                            />
                                        )}
                                        {header}
                                        {tableHeaderWithArrow.includes(header) && (
                                            <button onClick={() => onHandleSorting(header)} className='ml-3'>
                                                {getSortIcon(header)}
                                            </button>
                                        )}
                                    </div>
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody className='mt-10'>
                        {employeeListData &&
                            employeeListData?.content &&
                            employeeListData?.content?.map((employee) => (
                                <TableRow key={employee.employeeNumber}>
                                    <TableCell>
                                        <div>
                                            <div className='inline-flex whitespace-nowrap text-base'>
                                                <Checkbox
                                                    className='mr-[5px] mt-[2px]'
                                                    checked={profilesToUpdate?.profileIds?.some(
                                                        (p) => p === employee.profileId,
                                                    )}
                                                    onCheckedChange={(val) => {
                                                        handleCheckClick(employee, val as boolean)
                                                    }}
                                                />
                                                <NavLink
                                                    to={`/schedule/employee/update/${employee.profileId}`}
                                                    onClick={() => setSelectedEmployeeTab('PI')}
                                                >
                                                    {formatRecordNumber(
                                                        Number(employee.employeeNumber),
                                                    )}
                                                </NavLink>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell className='whitespace-nowrap text-base'>
                                        <NavLink
                                            to={`/schedule/employee/update/${employee.profileId}`}
                                            onClick={() => setSelectedEmployeeTab('PI')}
                                        >
                                            <p>{employee.firstName + ' ' + employee.lastName}</p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className='whitespace-nowrap text-base'>
                                        <NavLink
                                            to={`/schedule/employee/update/${employee.profileId}`}
                                            onClick={() => setSelectedEmployeeTab('PI')}
                                        >
                                            <p>
                                                $
                                                {employee.compensation?.toLocaleString(undefined, {
                                                    maximumFractionDigits: 2,
                                                    minimumFractionDigits: 2,
                                                }) ?? '0'}
                                            </p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className='whitespace-nowrap text-base'>
                                        <NavLink
                                            to={`/schedule/employee/update/${employee.profileId}`}
                                            onClick={() => setSelectedEmployeeTab('PI')}
                                        >
                                            <p>{employee.email}</p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className='whitespace-nowrap text-base'>
                                        <Tooltip
                                            position='bottom'
                                            content={employee.address.streetAddress}
                                        >
                                            <NavLink
                                                to={`/schedule/employee/update/${employee.profileId}`}
                                                onClick={() => setSelectedEmployeeTab('PI')}
                                            >
                                                <p>{employee.address.streetAddress}</p>
                                            </NavLink>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell className='whitespace-nowrap text-base'>
                                        <NavLink
                                            to={`/schedule/employee/update/${employee.profileId}`}
                                            onClick={() => setSelectedEmployeeTab('PI')}
                                        >
                                            <p>
                                                {formatPhoneNumber(
                                                    employee.phoneNumber as E164Number,
                                                )}
                                            </p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className='whitespace-nowrap text-base'>
                                        <NavLink
                                            to={`/schedule/employee/update/${employee.profileId}`}
                                            onClick={() => setSelectedEmployeeTab('PI')}
                                        >
                                            {!employee.dateOfBirth ? (
                                                <p>--</p>
                                            ) : (
                                                <p>
                                                    {dayjs(employee.dateOfBirth).format(
                                                        'MM/DD/YYYY',
                                                    )}
                                                </p>
                                            )}
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className='whitespace-nowrap text-base'>
                                        <NavLink
                                            to={`/schedule/employee/update/${employee.profileId}`}
                                            onClick={() => setSelectedEmployeeTab('PI')}
                                        >
                                            <p>{dayjs(employee.createdAt).format('MM/DD/YYYY')}</p>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className='whitespace-nowrap text-base'>
                                        <NavLink
                                            to={`/schedule/employee/update/${employee.profileId}`}
                                            onClick={() => setSelectedEmployeeTab('PI')}
                                        >
                                            {employee.caLicense === '' ? (
                                                <p>--</p>
                                            ) : (
                                                <p>{employee.caLicense}</p>
                                            )}
                                        </NavLink>
                                    </TableCell>
                                    <TableCell>
                                        <NoteModal
                                            profileId={employee.profileId}
                                            isDisabled={
                                                employee.status === USER_STATUS.INACTIVE ||
                                                employee.status === USER_STATUS.DELETED
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </CardContent>
            <CardFooter></CardFooter>
        </Card>
    )
}
export default EmployeeTable
