import AppSkeletonLoadingState from '@/components/LoadingState/TableLoadingState'
import { getBusinessCoOwners } from '@/api/profile'
import {
    BulkProfileUpdateStatusType,
    BusinessOwnerLisType,
    BusunessCoOwnerType,
} from '@/api/profile/schema'
import { Button } from '@/components/Button'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import { timezoneAtom } from '@/store/auth'
import { PlusIcon } from '@radix-ui/react-icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useAtomValue } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { formatToUTCWithOffset } from '@/utils/time'
import { COMPLETE_FORMAT_WITHOUT_TIME, FEATURE_RESTRICTIONS, ROLE, USER_STATUS } from '@/constants'
import { Pagination } from '@/components/Pagination'
import { useState } from 'react'
import { PaginationType } from '@/components/Pagination/schema'
import { Checkbox } from '@/components/Checkbox'
import DeleteModal from '../DeleteModal'
import { AxiosError } from 'axios'
import { checkSubscriptionFeatureRestriction } from '@/api/subscription'
import FeatureRestrictionModal from '@/components/FeatureRestrictionModal'
import BreadCrumbs from '@/components/BreadCrumbs'
import useViewedAsUser from '@/hooks/useViewedAsUser'

export const CoOwnerTable = () => {
    const navigate = useNavigate()
    const [restrictionModal, setRestrictionModal] = useState<boolean>(false)
    const user = useViewedAsUser()
    const tz = useAtomValue(timezoneAtom)
    const [pagination, setPagination] = useState<PaginationType>({
        page: 1,
        pageSize: 5,
        itemsPerPage: 5,
    })

    const [isCheckAll, setIsCheckAll] = useState<boolean>(false)

    const [profilesToUpdate, setProfilesToUpdate] = useState<BulkProfileUpdateStatusType>({
        profileIds: [],
        status: USER_STATUS.DELETED,
        businessId: user?.businessId,
    })

    const { data: coOwners, isPending: isLoading } = useQuery<BusinessOwnerLisType>({
        queryKey: ['coOwnersList', user?.businessId, pagination],
        queryFn: () => getBusinessCoOwners(user?.businessId as string, pagination),
    })

    const { mutate: checkSubsRestrictionMu } = useMutation<
        boolean,
        AxiosError,
        { businessId: string; name: string }
    >({
        mutationFn: ({ businessId, name }) => checkSubscriptionFeatureRestriction(businessId, name),
        onSuccess: (res) => {
            setRestrictionModal(!res)
            if (res) {
                navigate('/settings/add-co-owner')
            }
        },
    })

    const handleCheckClick = (subscriber: BusunessCoOwnerType, isCheck: boolean) => {
        if (isCheck) {
            const updatedProfileIds = [...profilesToUpdate.profileIds, subscriber.profileId]
            setProfilesToUpdate({
                ...profilesToUpdate,
                profileIds: updatedProfileIds,
            })
        } else {
            const updatedProfileIds = profilesToUpdate.profileIds.filter(
                (id) => id !== subscriber.profileId,
            )

            setProfilesToUpdate({
                ...profilesToUpdate,
                profileIds: updatedProfileIds,
            })
            if (updatedProfileIds.length === coOwners?.content?.length) {
                setIsCheckAll(true)
            } else {
                setIsCheckAll(false)
            }
        }
    }

    const handleCheckAll = (checked: boolean) => {
        setIsCheckAll(!isCheckAll)
        if (coOwners?.content && checked) {
            const updatedProfileIds = [...profilesToUpdate.profileIds]
            coOwners.content?.forEach((subscriber) => {
                if (subscriber.profileId !== user?.profileId) {
                    updatedProfileIds.push(subscriber.profileId)
                }
            })
            setProfilesToUpdate({
                ...profilesToUpdate,
                profileIds: updatedProfileIds,
            })
        } else {
            setProfilesToUpdate({
                ...profilesToUpdate,
                profileIds: [],
            })
        }
    }

    const handleAddUser = () => {
        checkSubsRestrictionMu({
            businessId: user?.businessId as string,
            name: FEATURE_RESTRICTIONS.userCount,
        })
    }

    return (
        <div className='flex flex-col'>
            <BreadCrumbs titleName='Add User' label='Settings' />
            {user?.role?.roleName === ROLE.OWNER && (
                <div className='flex flex-row items-end justify-end gap-5 p-5'>
                    <DeleteModal
                        profilesToUpdate={profilesToUpdate}
                        onSuccessRestore={() => {
                            setProfilesToUpdate({
                                profileIds: [],
                                status: USER_STATUS.DELETED,
                                businessId: user?.businessId,
                            })
                        }}
                    />
                    <Button onClick={handleAddUser}>
                        <PlusIcon className='h-5 w-5' />
                        Add User
                    </Button>
                </div>
            )}

            <div className='bg-white w-full h-full'>
                <Table>
                    <TableHeader>
                        <TableRow className='whitespace-nowrap'>
                            <TableHead className='flex flex-row gap-2 items-center font-semibold text-zentive-gray-medium text-base px-10'>
                                {user?.role?.roleName === ROLE.OWNER && (
                                    <Checkbox
                                        id={'business-name'}
                                        className=''
                                        onCheckedChange={handleCheckAll}
                                        checked={isCheckAll}
                                    />
                                )}
                                Date Invited
                            </TableHead>
                            <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                Name
                            </TableHead>
                            <TableHead className='font-semibold text-zentive-gray-medium text-base'>
                                Email Address
                            </TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {isLoading && <AppSkeletonLoadingState />}
                        {coOwners?.content &&
                            coOwners?.content?.map((data, index) => (
                                <TableRow
                                    key={data?.firstName + '-' + index}
                                    className='whitespace-nowrap'
                                >
                                    <TableCell className='flex flex-row items-center text-start text-zentive-black text-base overflow-hidden text-ellipsis pl-10 gap-2'>
                                        {user?.role?.roleName === ROLE.OWNER && (
                                            <Checkbox
                                                id={'business-name' + ' ' + index}
                                                checked={profilesToUpdate?.profileIds?.some(
                                                    (p) => p === data.profileId,
                                                )}
                                                onCheckedChange={(val) => {
                                                    setIsCheckAll(
                                                        profilesToUpdate.profileIds.length + 1 ===
                                                            coOwners?.content.length,
                                                    )
                                                    handleCheckClick(data, val as boolean)
                                                }}
                                                disabled={user?.email === data.email}
                                            />
                                        )}
                                        {formatToUTCWithOffset(
                                            data.createdAt as string,
                                            tz?.timeZoneId as string,
                                            COMPLETE_FORMAT_WITHOUT_TIME,
                                        )}
                                    </TableCell>
                                    <TableCell className='flex-row items-start text-start text-zentive-black text-base overflow-hidden text-ellipsis'>
                                        {data.firstName} {data.lastName}
                                    </TableCell>
                                    <TableCell className='flex-row items-start text-start text-zentive-black text-base overflow-hidden text-ellipsis'>
                                        {data.email} {user?.email === data.email ? '(You)' : ''}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                <div className='px-5'>
                    <Pagination
                        itemsPerPage={5}
                        setPagination={setPagination}
                        totalRecords={coOwners?.meta?.totalRecords ?? 0}
                        pagination={pagination}
                    />
                </div>
                <FeatureRestrictionModal open={restrictionModal} setOpen={setRestrictionModal} />
            </div>
        </div>
    )
}
