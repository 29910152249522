import { ProfileSelfResponseType } from '@/api/business/schema'
import { getBusinessProfile, getSubscriptions } from '@/api/profile'
import { SubscriptionsInfoType } from '@/api/profile/schema'
import { getPricingById } from '@/api/subscription'
import { SubscriptionType } from '@/api/subscription/schema'
import { Alert, AlertDescription } from '@/components/Alert'
import { USER_STATUS, SUBSCRIPTION_TYPES } from '@/constants'
import { manageCurrentSubTypeAtom } from '@/store/manageSubs'
import { cn } from '@/utils/helper'
import { Label } from '@radix-ui/react-label'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtomValue, useSetAtom } from 'jotai'
import UpdateBilingInfo from './UpdateBillingInfo'
import UpdateCardDetails from './UpdateCardDetails'
import UpdatePlan from './UpdatePlan'
import { isFreeTrialEndedAtom } from '@/store/owner'
import { useEffect } from 'react'
import useNextAutoBillDate from '@/hooks/useNextAutoBillDate'
import useViewedAsUser from '@/hooks/useViewedAsUser'

const OwnerBillingInfo = () => {
    const user = useViewedAsUser()
    const setSubID = useSetAtom(manageCurrentSubTypeAtom)
    const isFreeTrialEnded = useAtomValue(isFreeTrialEndedAtom)

    const { data: businessProfileData } = useQuery<unknown, AxiosError, ProfileSelfResponseType>({
        queryKey: ['businessProfile', user?.profileId],
        queryFn: () => getBusinessProfile(user?.profileId as string),
    })
    const { data: nextAutoBillDate, isSuccess } = useNextAutoBillDate(
        businessProfileData?.businessId as string,
    )

    const { data: plan } = useQuery<unknown, AxiosError, SubscriptionType>({
        queryKey: ['Plan'],
        enabled: !!businessProfileData?.business?.subscriptionTypeId,
        queryFn: () => getPricingById(businessProfileData?.business?.subscriptionTypeId ?? ''),
    })

    const { data: subscriptionsData, isLoading } = useQuery<
        unknown,
        AxiosError,
        SubscriptionsInfoType[]
    >({
        queryKey: ['subscriptionsInfo'],
        queryFn: () => getSubscriptions(user?.customerId as string),
        enabled: !!user?.customerId,
    })

    useEffect(() => {
        if (businessProfileData?.business?.subscriptionTypeId) {
            setSubID(businessProfileData.business.subscriptionTypeId)
        }
    }, [businessProfileData])

    return (
        <div>
            {isLoading && <> Loading . . .</>}
            {!isLoading && (
                <div className='bg-white'>
                    {plan?.name !== SUBSCRIPTION_TYPES.FREE_TRIAL && (
                        <div className='pt-[26px] mb-[32px] mx-[40px]'>
                            <Alert
                                className={
                                    businessProfileData?.business.status === USER_STATUS.ACTIVATED
                                        ? 'bg-zentive-green-dark'
                                        : 'bg-zentive-gray-bg'
                                }
                            >
                                <AlertDescription
                                    className={cn(
                                        'text-base ',
                                        businessProfileData?.business.status ===
                                            USER_STATUS.ACTIVATED
                                            ? 'text-zentive-gray-bg'
                                            : 'text-zentive-green-dark',
                                    )}
                                >
                                    <Label className='text-base font-semibold'>
                                        Next auto-bill date:
                                    </Label>
                                    {businessProfileData?.business?.status ===
                                        USER_STATUS.ACTIVATED &&
                                        nextAutoBillDate &&
                                        isSuccess &&
                                        ` ${nextAutoBillDate}`}

                                    {businessProfileData?.business?.status ===
                                        USER_STATUS.BILLING_PAUSED &&
                                        isSuccess &&
                                        ' Billing is Paused'}
                                </AlertDescription>
                            </Alert>
                        </div>
                    )}
                    <div className='pt-6 mb-8 pb-2 mx-[40px]'>
                        {!isFreeTrialEnded && (
                            <UpdatePlan
                                businessProfileData={businessProfileData as ProfileSelfResponseType}
                                plan={plan as SubscriptionType}
                                recentSubscriptions={subscriptionsData as SubscriptionsInfoType[]}
                            />
                        )}
                        <UpdateCardDetails
                            businessProfileData={businessProfileData as ProfileSelfResponseType}
                        />
                        <UpdateBilingInfo
                            businessProfileData={businessProfileData as ProfileSelfResponseType}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default OwnerBillingInfo
