import { useQuery } from '@tanstack/react-query'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { getJobById, getLastJobByClient } from '@/api/job'
import { IoLocationSharp } from 'react-icons/io5'
import { FaPhoneAlt, FaRegCalendar } from 'react-icons/fa'
import { BsEnvelopeFill } from 'react-icons/bs'
import ViewJobStatus from './ViewJobStatus'
import MoreJobDetails from './MoreJobDetails'
import ViewAddress from './VewJobAddress'
import ProgressButtons from './ProgressButtons'
import { useAtomValue } from 'jotai'
import { userAtom } from '@/store/auth'
import BackButtonTopBar from '../../Common/BackButtonTopBar'
import Spinner from '@/components/Spinner'
import { useTranslation } from 'react-i18next'
import { Button } from '@/components/Button'
import { PiDotsThreeCircleFill } from 'react-icons/pi'
import {
    JOB_STATUS,
    SPANISH_FORMAT,
    COMPLETE_FORMAT_WITHOUT_TIME,
    FEATURE_RESTRICTIONS,
} from '@/constants'
import dayjs from 'dayjs'
import { cn } from '@/utils/helper'
import { FullProfileType } from '@/api/profile/schema'
import { checkSubscriptionFeatureRestriction } from '@/api/subscription'

const ViewCustomerJob = () => {
    const { t, i18n } = useTranslation(['view-job'])
    const { jobId } = useParams()
    const user = useAtomValue(userAtom) as FullProfileType
    const navigate = useNavigate()
    const businessId = user?.businessId ?? ''
    const crewMemberId = user?.crewMemberId ?? ''
    const profileId = user?.profileId ?? ''

    const {
        data: job,
        isLoading: isJobByIdLoading,
        error: jobByIdError,
    } = useQuery({
        refetchInterval: 3000,
        refetchIntervalInBackground: true,
        enabled: !!jobId,
        queryKey: ['jobById', jobId],
        queryFn: () => getJobById(jobId),
    })

    const { data: isFeatureAvailable, isLoading: featureLoading } = useQuery<boolean>({
        queryKey: ['crewViewJobFeatureRestrictions', user?.businessId],
        queryFn: () =>
            checkSubscriptionFeatureRestriction(
                user?.businessId as string,
                FEATURE_RESTRICTIONS.timeTracking,
            ),
    })

    const { data: lastJob, isSuccess } = useQuery({
        enabled: !!(businessId && crewMemberId && profileId && job?.quote?.profileId),
        queryKey: ['lastJob', businessId, crewMemberId, profileId, job?.quote?.profileId],
        queryFn: () =>
            getLastJobByClient({
                businessId: businessId,
                crewMemberId: crewMemberId,
                jobId: jobId as string,
                profileId: profileId,
                customerId: job?.quote?.profileId as string,
            }),
        retry: 0,
    })

    if (isJobByIdLoading) {
        return (
            <div className='fixed inset-0 flex items-center justify-center bg-white'>
                <Spinner variant='normal' size='10' />
            </div>
        )
    }

    if (jobByIdError || !job) {
        return <Navigate to='/404' />
    }

    const isUserForeman = crewMemberId === job?.foreman?.crewMemberId

    const isBeforeDate = () => {
        const currentDate = dayjs().format('YYYY-MM-DD')
        const isBefore = dayjs(currentDate).isBefore(
            dayjs(job.appointment.startDateTime).format('YYYY-MM-DD'),
        )
        return isBefore
    }

    return (
        <div className='flex flex-col'>
            <div className='fixed w-full max-w-[1024px] top-0 z-20'>
                <BackButtonTopBar
                    title={job?.quote?.profile?.firstName + ' ' + job?.quote?.profile?.lastName}
                    options={isUserForeman}
                    redirectTo='/crew/jobs'
                    showUpgradeBadge={!isFeatureAvailable}
                    isLoading={featureLoading}
                />
                {job?.status !== JOB_STATUS.COMPLETED && isUserForeman && (
                    <div className='flex flex-row items-center'>
                        <Button
                            variant='ghost'
                            className='absolute top-1 right-1 z-50 hover:bg-bg-white'
                            onClick={() => navigate(`/crew/job/options/${jobId}`)}
                        >
                            <PiDotsThreeCircleFill className='h-5 w-5 text-zentive-green-dark rounded-full' />
                        </Button>
                    </div>
                )}
            </div>

            {isUserForeman && (
                <div className='flex items-center justify-center w-full relative mt-12 bg-zentive-gray-bg rounded-md shadow-md border px-3 mb-4 py-2'>
                    <p className='text-zentive-gray-medium text-sm mt-5'>
                        {t('You are viewing this as a foreman')}
                    </p>
                </div>
            )}

            <div
                className={cn(
                    'w-10/12 mx-auto bg-white py-2 rounded-md p-2 shadow-md border border-gray-300 px-3',
                    isUserForeman ? 'mt-2' : 'mt-20',
                )}
            >
                <div className='flex flex-row mb-4'>
                    <div className='relative w-full flex flex-col items-center justify-center'>
                        <p className='absolute right-0 top-0 text-xs rounded-full bg-gray-200 px-1 text-gray-500'>
                            {t('Customer')}
                        </p>
                        {job?.quote?.profile?.profileImage ? (
                            <img
                                src={
                                    typeof job?.quote?.profile?.profileImage === 'string'
                                        ? job?.quote?.profile?.profileImage
                                        : ''
                                }
                                alt={`Profile Image`}
                                className='h-[60px] w-[60px] rounded-full mr-1 border border-zentive-green-dark'
                            />
                        ) : (
                            <div className='h-[60px] w-[60px] rounded-full bg-zentive-blue-dark flex justify-center items-center mr-1'>
                                <span className='text-white text-lg'>
                                    {`${
                                        job?.quote?.profile?.firstName?.charAt(0)?.toUpperCase() ??
                                        ''
                                    }${
                                        job?.quote?.profile?.lastName?.charAt(0)?.toUpperCase() ??
                                        ''
                                    }`}
                                </span>
                            </div>
                        )}
                        <p className='text-zentive-green-dark font-semibold mt-4'>
                            {job?.quote?.profile?.firstName + ' ' + job?.quote?.profile?.lastName}
                        </p>
                    </div>
                </div>
                <div className='flex flex-col mt-2 text-sm gap-y-4'>
                    <div className='flex flex-row gap-x-1'>
                        <IoLocationSharp className='text-zentive-blue-dark h-3 w-3 mt-1 ml-1' />
                        <p className='text-sm'>{`${job?.address?.streetAddress + ','} ${
                            job?.address?.streetTwoAddress !== 'undefined' &&
                            job?.address?.streetTwoAddress !== undefined
                                ? job?.address?.streetTwoAddress + ', '
                                : ''
                        }${job?.address?.city + ','} ${job?.address?.state + ','} ${job?.address
                            ?.zipCode}`}</p>
                    </div>
                    <div className='flex flex-row'>
                        <BsEnvelopeFill className='text-zentive-blue-dark h-3 w-3 mt-1 ml-1' />
                        <p className='ml-1 text-sm'>{job?.quote?.profile?.email}</p>
                    </div>
                    <div className='flex flex-row'>
                        <FaPhoneAlt className='text-zentive-blue-dark h-2 w-2 mt-1 ml-1' />
                        <p className='ml-1 text-sm'>{job?.quote?.profile?.phoneNumber ?? '--'}</p>
                    </div>
                    {lastJob && isSuccess && (
                        <div className='flex flex-row'>
                            <FaRegCalendar className='text-zentive-blue-dark h-2 w-2 mt-1 ml-1' />
                            <p className='ml-1 text-sm'>{`${t('Last job date is')} ${dayjs(
                                lastJob?.appointment?.startDateTime,
                            ).format(
                                i18n.language === 'es'
                                    ? SPANISH_FORMAT
                                    : COMPLETE_FORMAT_WITHOUT_TIME,
                            )}`}</p>
                        </div>
                    )}
                </div>
            </div>

            <div>
                <ViewAddress job={job} />
                <ViewJobStatus job={job} isBeforeDate={isBeforeDate()} />
                <MoreJobDetails />

                <ProgressButtons
                    job={job}
                    featureAvailable={isFeatureAvailable as boolean}
                    isBeforeDate={isBeforeDate()}
                />
            </div>
        </div>
    )
}

export default ViewCustomerJob
