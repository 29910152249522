import { Card } from '@/components/Card'
import { QuoteType } from '@/api/quoting/schema'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Navigate, useNavigate } from 'react-router-dom'
import ViewQuotingContent from './CardContent'
import ViewQuotingHeader from './CardHeader'
import { getJobQuote, getQuote } from '@/api/quoting'
import { Button } from '@/components/Button'
import { BREADCRUMBS_PADDING_STYLE, QUOTE_STATUS } from '@/constants'
import BreadCrumbs from '@/components/BreadCrumbs'

const ViewQuoting = () => {
    const planParam = new URLSearchParams(window.location.search)
    const quoteId = planParam.get('quoteId')

    const navigate = useNavigate()

    const { data: quote, isLoading: isQuoteLoading } = useQuery<unknown, AxiosError, QuoteType>({
        queryKey: ['quote', quoteId],
        queryFn: () => getQuote(quoteId as string),
    })

    const { data: jobQuote, isLoading: isJobQuoteLoading } = useQuery({
        queryKey: ['quoteExist', quoteId],
        queryFn: () => getJobQuote(quoteId as string),
    })

    if (isJobQuoteLoading || isQuoteLoading) {
        return null
    }

    // TO DO: update error redirection once 1550 and 1546 are merged
    if (!quote) {
        return <Navigate to='/error' />
    }

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <BreadCrumbs titleName={quote?.name} pathId={`${quote?.quoteId}`} label={quote?.name} />
            <div className='mt-10'>
                {quote?.status === QUOTE_STATUS.APPROVED && !jobQuote?.isJobExist ? (
                    <div className='w-full flex justify-end '>
                        <Button
                            type='button'
                            className='mr-4 font-semibold mb-2 text-base hover:bg-zentive-green-medium bg-zentive-green-medium'
                            onClick={() => navigate(`/schedule/jobs/new-job/${quoteId}`)}
                        >
                            Convert to Job
                        </Button>
                    </div>
                ) : quote?.status === QUOTE_STATUS.APPROVED ? (
                    <div className='w-full flex justify-end '>
                        <Button
                            type='button'
                            className='mr-4 font-semibold mb-2 text-base hover:bg-zentive-green-medium bg-zentive-green-medium'
                            onClick={() => navigate(`/schedule/jobs/${jobQuote?.jobId}`)}
                        >
                            View Job
                        </Button>
                    </div>
                ) : quote?.status === QUOTE_STATUS.PENDING ? (
                    <div className='w-full flex justify-end '>
                        <Button
                            type='button'
                            className='mr-4 font-semibold mb-2 text-base hover:bg-zentive-green-medium bg-zentive-green-medium'
                            onClick={() => navigate(`/schedule/jobs/${jobQuote?.jobId}`)}
                            disabled
                        >
                            Pending Approval
                        </Button>
                    </div>
                ) : (
                    <div className='w-full flex justify-end '></div>
                )}

                <Card className='border-0 border-t-8 border-zentive-green-dark '>
                    <ViewQuotingHeader quote={quote as QuoteType} />
                    <ViewQuotingContent quote={quote as QuoteType} />
                </Card>
            </div>
        </div>
    )
}

export default ViewQuoting
