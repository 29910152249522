import { customerInvoiceAtom } from '@/store/owner'
import { useAtomValue } from 'jotai'
import ClientDialog from '../ClientModal'
import { jobGenerateInvoiceAtom } from '@/store/job'

const HeaderClientDetails = () => {
    const customer = useAtomValue(customerInvoiceAtom)
    const generateInvoiceData = useAtomValue(jobGenerateInvoiceAtom)

    return (
        <div className='mx-5 mt-6'>
            <div className='text-zentive-green-darker font-semibold text-2xl flex flex-row'>
                {generateInvoiceData
                    ? (generateInvoiceData?.quote?.profile?.firstName as string)
                    : customer?.profile.firstName}{' '}
                {generateInvoiceData
                    ? (generateInvoiceData?.quote?.profile?.lastName as string)
                    : customer?.profile.lastName}
                {customer ? <ClientDialog isEditing={true} /> : <ClientDialog isEditing={false} />}
            </div>
            <div className='flex flex-row'>
                <div className='flex flex-col mr-16'>
                    <div className='flex flex-row pt-6 font-bold mb-4'>
                        <span className='font-bold text-base'>Property Address</span>
                    </div>
                    <p className='text-zentive-gray-medium'>
                        {generateInvoiceData && generateInvoiceData.address
                            ? generateInvoiceData.address.streetAddress
                            : customer?.profile.address?.streetAddress}
                    </p>
                    <p className='text-zentive-gray-medium'>
                        {generateInvoiceData && generateInvoiceData.address
                            ? generateInvoiceData.address.city
                            : customer?.profile.address?.city}
                        ,{' '}
                        {generateInvoiceData && generateInvoiceData.address
                            ? generateInvoiceData.address.state
                            : customer?.profile.address?.state}{' '}
                        {generateInvoiceData && generateInvoiceData.address
                            ? generateInvoiceData.address.zipCode
                            : customer?.profile.address?.zipCode}
                    </p>
                </div>
                <div className='flex flex-col mb-8'>
                    <span className=' pt-6 font-bold mb-4 text-base'>Contact Details</span>
                    <p className='text-zentive-gray-medium'>
                        {generateInvoiceData && generateInvoiceData.quote
                            ? generateInvoiceData.quote?.profile?.phoneNumber
                            : customer?.profile.phoneNumber}
                    </p>
                    <p className='text-zentive-gray-medium'>
                        {generateInvoiceData && generateInvoiceData.quote
                            ? generateInvoiceData.quote?.profile?.email
                            : customer?.profile.email}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default HeaderClientDetails
