import { createOrUpdateDealStage, getDealStagesByBusinessId } from '@/api/deal_stage'
import { Button } from '@/components/Button'
import { Drawer, DrawerClose, DrawerContent } from '@/components/Drawer'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import { cn } from '@/utils/helper'
import { zodResolver } from '@hookform/resolvers/zod'
import { Cross1Icon } from '@radix-ui/react-icons'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { ChangeEvent, ComponentProps, FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaPencilAlt } from 'react-icons/fa'
import { z } from 'zod'



const CreateOrUpdateDealStage: FC<ComponentProps<typeof Drawer>> = (props) => {
    const currentUser = useViewedAsUser()
    const queryClient = useQueryClient()

    const { businessId = '' } = currentUser

    // const [newDealStage, setNewDealStage] = useState<string>('')
    // const [existingDealStage, setExistingDealStage] = useState<string>('')
    const [selectedDealStageId, setSelectedDealStageId] = useState<string>('')

    // const dealStagesArray = queryClient.getQueryData<DealStages[]>(['dealStages', businessId])
    const {
        data: dealStages,
        isLoading,
        isSuccess,
    } = useQuery({
        enabled: !!(businessId && props?.open),
        queryKey: ['dealStages', businessId],
        queryFn: () => getDealStagesByBusinessId(businessId),
    })

    
    const labelsArray = dealStages?.map((deal) => {
        return deal.label
    })

    const newDealStageSchema = z.object({
        newDealStage: z
            .string()
            .min(1, {message: "Deal stage cannot be empty"})
            .refine((stage) => !labelsArray?.includes(stage), { // Check for duplicates
                message: "Duplicate labels are not allowed"
            })
            .optional(),
    });

    const existingDealStageSchema = z.object({
        existingDealStage: z
        .string()
        .min(1, {message: "Deal stage cannot be empty"})
        .refine((stage) => !labelsArray?.includes(stage), { // Check for duplicates
            message: "Duplicate labels are not allowed"
        }),
    })

    const { handleSubmit, reset, register, setValue, watch, clearErrors, formState: { errors } } = useForm({
        resolver: zodResolver(selectedDealStageId ? existingDealStageSchema : newDealStageSchema),
        defaultValues:{
            newDealStage: '',
            existingDealStage: ''
        },
        mode: 'onChange'
    });
    const newDealStage = watch('newDealStage');
    const existingDealStage = watch('existingDealStage')


    const resetStates = () => {
        setSelectedDealStageId('')
        reset()
    }
    const { mutate: newDealsStageMu, isPending: isNewDealsPending } = useMutation({

        
        mutationFn: () =>
            createOrUpdateDealStage({
                dealStageId: selectedDealStageId,
                businessId,
                label: !!selectedDealStageId ? existingDealStage : newDealStage,
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['dealStages', businessId] })
            resetStates()
        },
    })

    const handleNewDealStage = (evt: ChangeEvent<HTMLInputElement>) => {
        setValue("newDealStage", evt.target.value)
        clearErrors('newDealStage')
    }

    const handleExistingDealStage = (evt: ChangeEvent<HTMLInputElement>) => {
        setValue("existingDealStage", evt.target.value)
        clearErrors('existingDealStage')
        clearErrors('newDealStage')
    }

    useEffect(() => {
        if (!props?.open) resetStates()
    }, [props?.open])

    const onSubmit = handleSubmit(() => {
        newDealsStageMu()
    })
    
    return (
        <Drawer {...props} direction='right'>
            <DrawerContent className='bg-white h-full w-[506px] rounded-b-[10px]' hideSlider>
                <div className='px-[36px]'>
                    <div className='h-fit w-full relative flex flex-col'>
                        <DrawerClose asChild>
                            <button
                                className='w-fit flex-none place-self-end mt-[36px]'
                                // onClick={() => setViewedLead(null)}
                                type='button'
                            >
                                <Cross1Icon />
                            </button>
                        </DrawerClose>
                    </div>

                    <p className='mt-[36px] text-lg text-zentive-black font-semibold'>
                        Edit Labels
                    </p>

                    <div className='my-[21px] h-[2px] bg-zentive-gray-light'></div>

                    {isLoading && <p>Retrieving Deal Stages...</p>}

                    {isSuccess && dealStages.length === 0 && <p>No Deal Stages found</p>}

                    {isSuccess && dealStages.length > 0 && (
                        <div className='flex flex-col gap-[22px]'>
                            {dealStages.map((dealStage) => {
                                if (dealStage.dealStageId !== selectedDealStageId) {
                                    return (
                                        <div
                                            className='flex flex-row gap-[23px]'
                                            key={dealStage.dealStageId}
                                        >
                                            <p>{dealStage.label}</p>
                                            <button
                                                onClick={() => {
                                                    setValue("existingDealStage",dealStage.label)
                                                    setSelectedDealStageId(dealStage.dealStageId)
                                                    setValue("newDealStage", "")
                                                }}
                                                type='button'
                                            >
                                                <FaPencilAlt className='h-4 w-4 cursor-pointer text-indigo-900' />
                                            </button>
                                        </div>
                                    )
                                }

                                return (
                                    <div
                                        key={dealStage.dealStageId}
                                    >
                                        <form onSubmit={onSubmit} className="flex flex-row gap-[16px]">
                                        <input
                                            className={cn(
                                                'flex h-[45px] w-full rounded-sm border border-zentive-gray-medium bg-transparent px-3 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-[#ebebeb] disabled:text-zentive-gray-medium',
                                            )}
                                            {...register('existingDealStage')}
                                            onChange={handleExistingDealStage}
                                            placeholder='Label'
                                            required
                                            type='text'
                                            value={existingDealStage}
                                        />
                                        <Button
                                            className='w-3/5'
                                            disabled={
                                                existingDealStage.length === 0 || isNewDealsPending
                                            }
                                            onClick={() => clearErrors('newDealStage')}
                                            type='submit'
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            className='w-3/5'
                                            onClick={() => {
                                                setSelectedDealStageId('')
                                            }}
                                            type='button'
                                        >
                                            Cancel
                                        </Button>
                                        </form>
                                        {errors.existingDealStage && (
                                                <span className="text-red-500">{errors.existingDealStage.message}</span>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                    )}

                    <p className='mt-[36px] text-lg text-zentive-black font-semibold'>
                        Add Labels
                    </p>

                    <div className='my-[21px] h-[2px] bg-zentive-gray-light'></div>

                    <div>
                        <form onSubmit={onSubmit} className="flex flex-row gap-[16px] mb-3">
                            <input
                                className={cn(
                                    'flex h-[45px] w-full rounded-sm border border-zentive-gray-medium bg-transparent px-3 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-[#ebebeb] disabled:text-zentive-gray-medium',
                                )}
                                placeholder="Label"
                                {...register('newDealStage', {
                                    required: !selectedDealStageId && 'This field is required',
                                })}
                                onChange={handleNewDealStage}
                                value={newDealStage}
                                disabled={!!selectedDealStageId}
                            />
                            

                            <Button
                                className="w-3/5"
                                disabled={newDealStage.trim().length === 0 || isNewDealsPending || errors.newDealStage?.message == ""}
                                type="submit"
                            >
                                Add
                            </Button>
                        </form>
                        {errors.newDealStage && (
                                <span className="text-red-500">{errors.newDealStage.message}</span>
                        )}
                    </div>
                </div>
            </DrawerContent>
        </Drawer>
    )
}

export default CreateOrUpdateDealStage
