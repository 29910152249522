import BreadCrumbs from '@/components/BreadCrumbs'
import JobCompletionTable from './JobCompletionTable'
import { JobCompletionFilter } from './JobCompletionFIlter'
import { useQuery } from '@tanstack/react-query'
import { checkSubscriptionFeatureRestriction } from '@/api/subscription'
import { FEATURE_RESTRICTIONS } from '@/constants'
import { AxiosError } from 'axios'
import { SubscriptionsInfoType } from '@/api/profile/schema'
import { getSubscriptions } from '@/api/profile'
import { DowngradeNotification } from '@/components/DowngradeNotification'
import { UpgradeBadge } from '@/components/UpgradeBadge'
import useViewedAsUser from '@/hooks/useViewedAsUser'

export const JobCompletion = () => {
    const user = useViewedAsUser()

    const { data: isFeatureAvailable, isLoading: featureLoading } = useQuery<boolean>({
        queryKey: ['routingFeatureSubscription', user?.businessId],
        queryFn: () =>
            checkSubscriptionFeatureRestriction(
                user?.businessId as string,
                FEATURE_RESTRICTIONS.jobReports,
            ),
    })

    const { data: subscriptionsData, isLoading: subsDateLoading } = useQuery<
        unknown,
        AxiosError,
        SubscriptionsInfoType[]
    >({
        queryKey: ['subscriptionsInfo'],
        queryFn: () => getSubscriptions(user?.customerId as string),
        enabled: !!user?.customerId,
    })

    const isFirstPlan: boolean = (subscriptionsData?.length ?? 0) <= 1

    return (
        <div className='flex flex-col w-full h-full px-[70px] pt-[50px] gap-5'>
            <div className='flex flex-row gap-5'>
                <BreadCrumbs titleName='Job Completion' />
                {!isFirstPlan && !isFeatureAvailable && !featureLoading && !subsDateLoading && (
                    <span className='-mt-1'>
                        <UpgradeBadge />
                    </span>
                )}
            </div>
            {!isFirstPlan && !isFeatureAvailable && !featureLoading && !subsDateLoading && (
                <DowngradeNotification />
            )}
            <JobCompletionFilter />
            <JobCompletionTable isFeatureAvailable={isFeatureAvailable ?? false} />
        </div>
    )
}
