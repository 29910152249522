import { CardTitle } from '@/components/Card'
import { QuoteType } from '@/api/quoting/schema'
import { computeQuote } from '@/utils/calc'
import { Dialog, DialogTrigger } from '@/components/Dialog'
import AddDiscountDialog from '../../../AddQuoting/CardContent/BillingAmount/AddDiscountDialog'
import { useEffect, useState } from 'react'
import { CiTrash } from 'react-icons/ci'
import { useAtom, useSetAtom } from 'jotai'
import { invoiceTaxAtom, quoteContentAtom } from '@/store/owner'
import { DEFAULT_QUOTE_TAX_VALUE } from '@/constants'
import AddTaxDialog from '@/pages/private/FinancialManagement/Invoicing/AddInvoice/CardContent/BillingAmount/AddTaxDialog'
import FinancialManagementTaxes from '@/components/FinancialManagement/Taxes'
import { displayWithDecimals, displayCurrency } from '@/utils/helper'

const BillingAmount = ({ quote, isForInvoice }: { quote: QuoteType; isForInvoice: boolean }) => {
    const {
        cost,
        discount,
        estimate,
        estimatePercentage,
        laborCost,
        subTotal,
        total,
        taxName,
        totalExpenses,
        totalProducts,
        totalServices,
        totalExpensesIncluded,
        // setInvoiceDiscount,
    } = computeQuote(quote, isForInvoice)

    const [taxes, setTaxes] = useAtom(invoiceTaxAtom)
    const setQuoteContent = useSetAtom(quoteContentAtom)

    const productsInvoiceTaxAmount =
        totalProducts * (isForInvoice ? taxes.productTax / 100 : (quote.productTax ?? 0) / 100)
    const servicesInvoiceTaxAmount =
        totalServices * (isForInvoice ? taxes.serviceTax / 100 : (quote.serviceTax ?? 0) / 100)
    const expensesInvoiceTaxAmount =
        (isForInvoice ? totalExpensesIncluded : totalExpenses) *
        (isForInvoice ? taxes.expenseTax / 100 : (quote.expenseTax ?? 0) / 100)
    const totalInvoiceTaxAmount =
        productsInvoiceTaxAmount + servicesInvoiceTaxAmount + expensesInvoiceTaxAmount

    const [iDiscount, setIDiscount] = useState({
        percentage: 0,
        amount: 0,
        isPercent: false,
        totalAfterDiscount: 0,
    })

    const invoiceTotalAfterDiscount = subTotal - iDiscount.amount
    const invoiceTotal = invoiceTotalAfterDiscount + totalInvoiceTaxAmount

    const invoiceNetProfitEstimate = iDiscount.totalAfterDiscount - cost

    const invoiceNetProfitMargin =
        (Number(invoiceNetProfitEstimate?.toFixed(2) ?? 0.0) /
            Number(iDiscount?.totalAfterDiscount?.toFixed(2) ?? 0.0)) *
        100

    const handleRemoveDiscount = () => {
        const resetTotalAfterDiscount = subTotal
        setIDiscount({
            percentage: 0,
            amount: 0,
            isPercent: false,
            totalAfterDiscount: resetTotalAfterDiscount,
        })
        setQuoteContent((prevVal) => ({
            ...prevVal,
            discount: 0.0,
        }))
    }

    const handleRemoveTax = () => {
        setTaxes(DEFAULT_QUOTE_TAX_VALUE)
    }

    const getTaxesTotalPercentageInvoice = () => {
        return (totalInvoiceTaxAmount / iDiscount.totalAfterDiscount) * 100
    }

    const getTaxesTotalPercentageQuote = () => {
        const quoteTotalAfterDisount = subTotal - discount
        return ((quote?.tax as number) / quoteTotalAfterDisount) * 100
    }

    useEffect(() => {
        if (isForInvoice) {
            const newTotalAfterDiscount = subTotal - iDiscount.amount
            setIDiscount((prevDiscount) => ({
                ...prevDiscount,
                totalAfterDiscount: newTotalAfterDiscount,
            }))

            setQuoteContent((prevVal) => ({
                ...prevVal,
                subTotal: newTotalAfterDiscount,
            }))
        }
    }, [isForInvoice, iDiscount.amount, subTotal, taxes])

    return (
        <>
            <div className='border border-[#EBEBEB] p-6 my-8 rounded-md'>
                <CardTitle className='text-lg font-semibold mb-6'>Billing Amount</CardTitle>
                <div className='flex flex-row justify-between px-4 border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                    <div className='font-semibold'>Labor Cost</div>
                    <div className=''>{displayCurrency(laborCost)}</div>
                </div>
                <div className='flex flex-row justify-between px-4 border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                    <div className='font-semibold'>Subtotal</div>
                    <div className=''>{displayCurrency(subTotal)}</div>
                </div>

                {isForInvoice && iDiscount.amount == 0 && iDiscount.percentage == 0 ? (
                    <div className='flex flex-row justify-between border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                        <Dialog>
                            <div className='font-semibold px-4'>Discount</div>
                            <DialogTrigger asChild>
                                <button
                                    type='button'
                                    className='px-4 pb-2 text-zentive-blue-dark font-semibold'
                                >
                                    Add Discount
                                </button>
                            </DialogTrigger>
                            <AddDiscountDialog
                                setDiscount={setIDiscount}
                                subTotal={subTotal}
                                handleDiscountTypeChange={(isPercent: boolean) => {
                                    setIDiscount((prevDiscount: any) => ({
                                        ...prevDiscount,
                                        isPercent,
                                    }))
                                }}
                            />
                        </Dialog>
                    </div>
                ) : (
                    <div className='flex flex-row justify-between px-4 border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                        <div className='font-semibold'>
                            Discount{' '}
                            {quote?.isDiscountPercent && (
                                <span className='text-zentive-blue-dark'>
                                    ({isForInvoice ? iDiscount.percentage : quote?.discount!}
                                    %)
                                </span>
                            )}
                        </div>
                        {!isForInvoice ? (
                            <div className='flex items-center'>
                                <div>{`-${displayCurrency(discount)}`}</div>
                            </div>
                        ) : (
                            <div className='flex items-center'>
                                <div className='mb-4'>
                                    {`-${displayCurrency(iDiscount.amount)}`}
                                </div>
                                <button
                                    type='button'
                                    className='ml-1'
                                    onClick={handleRemoveDiscount}
                                >
                                    <CiTrash className='size-7 text-zentive-red-dark mb-4' />
                                </button>
                            </div>
                        )}
                    </div>
                )}

                <div className='flex flex-row justify-between px-4 border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                    <div className='font-semibold'>Total After Discount</div>
                    <div className=''>
                        {isForInvoice
                            ? displayCurrency(iDiscount.totalAfterDiscount ?? 0)
                            : displayCurrency(subTotal - discount)}
                    </div>
                </div>
                {isForInvoice &&
                taxes.productTax == 0 &&
                taxes.serviceTax == 0 &&
                taxes.expenseTax == 0 ? (
                    <div className='flex flex-row justify-between px-4 border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                        <Dialog>
                            <div className='font-semibold px-0'>Tax</div>
                            <DialogTrigger asChild>
                                <button
                                    type='button'
                                    className='px-0 pb-2 text-zentive-blue-dark font-semibold'
                                >
                                    Add Tax
                                </button>
                            </DialogTrigger>
                            <AddTaxDialog />
                        </Dialog>
                    </div>
                ) : isForInvoice ? (
                    <>
                        <div className='flex flex-row justify-between text-zentive-gray-medium'>
                            <div className='font-semibold px-4'>
                                Tax <span className='text-zentive-blue-dark'>{taxes.name}</span>
                                <Dialog>
                                    <DialogTrigger asChild>
                                        <button
                                            type='button'
                                            className='text-zentive-blue-dark font-semibold hover:underline'
                                        >
                                            &nbsp;{' '}
                                            {`(${displayWithDecimals(
                                                getTaxesTotalPercentageInvoice(),
                                                3,
                                            )}%)`}
                                        </button>
                                    </DialogTrigger>
                                    <AddTaxDialog />
                                </Dialog>
                            </div>
                            <div className='flex flex-row'>
                                <div className='mr-2'>{displayCurrency(totalInvoiceTaxAmount)}</div>
                                <button type='button' className='mr-4' onClick={handleRemoveTax}>
                                    <CiTrash className='size-7 text-zentive-red-dark mb-6' />
                                </button>
                            </div>
                        </div>
                        {taxes.productTax > 0 || taxes.serviceTax > 0 || taxes.expenseTax > 0 ? (
                            <FinancialManagementTaxes
                                productsTaxAmount={productsInvoiceTaxAmount}
                                servicesTaxAmount={servicesInvoiceTaxAmount}
                                expensesTaxAmount={expensesInvoiceTaxAmount}
                            />
                        ) : null}
                    </>
                ) : (
                    <>
                        <div className='flex flex-row justify-between px-4 h-10 text-zentive-gray-medium'>
                            <div className='font-semibold'>
                                Tax <span className='text-zentive-blue-dark'>{taxName}</span>
                                <span className='text-zentive-blue-dark'>
                                    {`(${displayWithDecimals(getTaxesTotalPercentageQuote(), 3)}%)`}
                                </span>
                            </div>
                            <div className=''>{displayCurrency(quote?.tax as number)}</div>
                        </div>
                        {(quote.productTax && quote.productTax > 0) ||
                        (quote.serviceTax && quote?.serviceTax > 0) ||
                        (quote.expenseTax && quote?.expenseTax > 0) ? (
                            <div className='flex flex-col justify-between h-auto text-zentive-gray-medium'>
                                {quote.productTax && quote.productTax > 0 ? (
                                    <div className='flex flex-row justify-between px-6'>
                                        <p>
                                            - Products{' '}
                                            {`${displayWithDecimals(quote.productTax, 2)}%`}
                                        </p>
                                        <p>{displayCurrency(productsInvoiceTaxAmount)}</p>
                                    </div>
                                ) : null}
                                {quote.serviceTax && quote.serviceTax > 0 ? (
                                    <div className='flex flex-row justify-between px-6'>
                                        <p>
                                            - Services{' '}
                                            {`${displayWithDecimals(quote.serviceTax, 2)}%`}
                                        </p>
                                        <p>{displayCurrency(servicesInvoiceTaxAmount)}</p>
                                    </div>
                                ) : null}
                                {quote.expenseTax && quote.expenseTax > 0 ? (
                                    <div className='flex flex-row justify-between px-6'>
                                        <p>
                                            - Expenses{' '}
                                            {`${displayWithDecimals(quote.expenseTax, 2)}%`}
                                        </p>
                                        <p>{displayCurrency(expensesInvoiceTaxAmount)}</p>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </>
                )}
                <div className='flex flex-row justify-between px-4 pt-4 border-t-[6px] border-zentive-gray-medium h-16 text-zentive-gray-medium'>
                    <div className='font-semibold'>Total</div>
                    {isForInvoice ? displayCurrency(invoiceTotal) : displayCurrency(total)}
                </div>
            </div>
            <div className='border border-[#EBEBEB] p-6 my-8 rounded-md'>
                <CardTitle className='text-lg mb-6'>Cost and Estimated Margin</CardTitle>
                <div className='flex flex-row justify-between px-4 border-b-2 border-dotted border-zentive-gray-medium h-10 mb-4 text-zentive-gray-medium'>
                    <div className='font-semibold'>Product and Service Cost</div>
                    <div className=''>{displayCurrency(Math.floor(cost))}</div>
                </div>
                <div className='flex flex-row justify-between px-4  h-10 mb-4 text-zentive-gray-medium'>
                    <div className='flex flex-col'>
                        <div className='font-semibold'>Net Profit Margin</div>
                        <div className='font-semibold'>(Excluded of Tax)</div>
                    </div>
                    <div className='text-zentive-green-dark font-semibold'>
                        {`${displayCurrency(
                            isForInvoice ? invoiceNetProfitEstimate : estimate,
                        )} (${displayWithDecimals(
                            isForInvoice
                                ? Math.round(invoiceNetProfitMargin * 100) / 100
                                : estimatePercentage,
                            2,
                        )}%)`}
                    </div>
                </div>
            </div>
        </>
    )
}

export default BillingAmount
