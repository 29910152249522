import { updateBusinessSubscriptionStatus as updateBusinessSubsStat } from '@/api/owner'
import { BusinessStatusType } from '@/api/business/schema'
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '@/components/AlertDialog'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { HiOutlineExclamationTriangle } from 'react-icons/hi2'
import { useNavigate } from 'react-router-dom'
import { useToast } from '@/hooks/useToast'
import { USER_STATUS } from '@/constants'
import { useState } from 'react'
import OwnerJobRestrictionModal from '@/components/OwnerJobRestrictionModal'
import { AxiosError } from 'axios'

const DeleteModal = () => {
    const params = new URLSearchParams(window.location.search)

    const profileIdData = params.get('profileid')
    const businessIdData = params.get('corporateid')
    const [ownerJobModal, setOwnerJobModal] = useState<boolean>(false)

    const navigate = useNavigate()

    const { toast } = useToast()

    const queryClient = useQueryClient()

    const { mutate: updateProfileMu, isPending: updateLoading } = useMutation<
        unknown,
        AxiosError,
        BusinessStatusType
    >({
        mutationFn: (data: BusinessStatusType) => updateBusinessSubsStat(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['businessStatus'] })
            toast({
                description: 'Successfully Deleted',
                variant: 'default',
            })
            navigate('/manage-subs/trash')
        },
        onError: () => {
            setOwnerJobModal(true)
        },
    })

    return (
        <AlertDialog>
            <OwnerJobRestrictionModal open={ownerJobModal} setOpen={setOwnerJobModal} />
            <AlertDialogTrigger className='bg-zentive-red-dark mt-[16px] ml-[16px] h-[43px] w-[250px] mb-[16px] text-base text-white rounded-[4px]'>
                Delete Account
            </AlertDialogTrigger>
            <AlertDialogContent className='bg-white h-[285px] w-[577px] rounded-[15px] p-0'>
                <div className='flex gap-5 px-[26.5px] pt-[40px]'>
                    <div className='flex justify-center items-center bg-zentive-red-light h-[62px] w-[62px] rounded-full'>
                        <HiOutlineExclamationTriangle className='h-[26px] w-[26px] mx-[17px]  yx-[17px] text-zentive-red-dark' />
                    </div>
                    <div className=' overflow-auto'>
                        <AlertDialogTitle className='text-left font-semibold text-2xl text-zentive-black mt-[10px]'>
                            Delete Account
                        </AlertDialogTitle>
                        <AlertDialogDescription className='text-left text-sm text-zentive-gray-medium mt-[18px]'>
                            This action is irreversible and will remove all user's account
                            information, settings, and data associated with user's account.
                        </AlertDialogDescription>
                        <AlertDialogDescription className='text-left text-sm text-zentive-gray-medium mt-[18px]'>
                            Are you sure you want to terminate this account?
                        </AlertDialogDescription>
                    </div>
                </div>
                <AlertDialogFooter className='mx-[0px] bg-[#EBEBEB] rounded-b-[15px] pr-[26px] pb-[15px] pt-[6px]'>
                    <div className='w-full flex justify-end items-end gap-[16px]'>
                        <AlertDialogCancel className='w-97 h-[43px] text-base font-semibold text-zentive-green-dark ring-zentive-green-dark'>
                            Cancel
                        </AlertDialogCancel>
                        <AlertDialogAction
                            disabled={updateLoading}
                            onClick={() => {
                                updateProfileMu({
                                    profileId: profileIdData,
                                    businessId: businessIdData,
                                    status: USER_STATUS.DELETED,
                                } as BusinessStatusType)
                            }}
                            className='w-97 h-[43px] text-base font-semibold bg-zentive-green-dark hover:bg-zentive-green-medium'
                        >
                            Delete
                        </AlertDialogAction>
                    </div>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default DeleteModal
