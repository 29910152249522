import { getNetGrossReport } from '@/api/reports'
import { NetGrossRecordType, NetGrossReportType } from '@/api/reports/schema'
import { Button } from '@/components/Button'
import { Card, CardContent } from '@/components/Card'
import { Checkbox } from '@/components/Checkbox'
import { Pagination } from '@/components/Pagination'
import { PaginationType } from '@/components/Pagination/schema'
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/Table'
import { RECORD_PREFIX, US_FORMAT } from '@/constants'
import { isViewedAsAtom, timezoneAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { netGrossExportAtom, netGrossFilterAtom } from '@/store/reports'
import { cn, displayCurrency, formatRecordNumber } from '@/utils/helper'
import { formatToUTCWithOffset } from '@/utils/time'
import { useQuery } from '@tanstack/react-query'
import { useAtom, useAtomValue } from 'jotai'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'

const tableHeader = [
    'Job Number',
    'Job Type',
    'Customer Name',
    'Date Completed',
    'Gross Income',
    'Paid Via',
    'Invoice Number',
    'Quote Number',
]

const GrossTable = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    const tz = useAtomValue(timezoneAtom)

    const [isAllChecked, setIsAllChecked] = useState(false)

    const netGrossFilter = useAtomValue(netGrossFilterAtom)
    const [exportAtom, setExportAtom] = useAtom(netGrossExportAtom)

    const [pagination, setPagination] = useState<PaginationType>({
        page: 1,
        pageSize: 5,
    })

    const { data: report } = useQuery<NetGrossReportType>({
        queryKey: ['netGrossReport', user?.businessId, netGrossFilter, pagination],
        queryFn: () => getNetGrossReport(user?.businessId as string, netGrossFilter, pagination),
    })

    const handleCheckAllChange = (isChecked: boolean) => {
        setIsAllChecked(isChecked)

        if (isChecked) {
            // Add all available records to exportAtom
            const allRecords = report?.content?.report ?? []

            setExportAtom({
                records: allRecords,
                totalGrossIncome: allRecords.reduce(
                    (total, record) => total + record.grossIncome,
                    0,
                ),
                totalExternalExpense: allRecords.reduce(
                    (total, record) => total + record.externalExpenses,
                    0,
                ),
                totalInternalExpense: allRecords.reduce(
                    (total, record) => total + record.internalExpenses,
                    0,
                ),
                totalNetIncome: allRecords.reduce((total, record) => total + record.netIncome, 0),
            })
        } else {
            // Clear all records from exportAtom
            setExportAtom({
                records: [],
                totalGrossIncome: 0,
                totalExternalExpense: 0,
                totalInternalExpense: 0,
                totalNetIncome: 0,
            })
        }
    }

    const handleCheckChange = (invoice: NetGrossRecordType) => {
        setExportAtom((prev) => {
            if (!prev) {
                // If there is no existing filter, create one with the new invoice and its totals
                return {
                    records: [invoice],
                    totalGrossIncome: invoice.grossIncome,
                    totalExternalExpense: invoice.externalExpenses,
                    totalInternalExpense: invoice.internalExpenses,
                    totalNetIncome: invoice.netIncome,
                }
            }

            const isInvoiceSelected = prev.records?.some(
                (record) => record.invoiceId === invoice.invoiceId,
            )

            // Update records based on whether the invoice is being added or removed
            const updatedInvoices = isInvoiceSelected
                ? prev.records.filter((record) => record.invoiceId !== invoice.invoiceId) // Remove if exists
                : [...(prev.records || []), invoice] // Add if it doesn't exist

            return {
                records: updatedInvoices,
                totalGrossIncome: isInvoiceSelected
                    ? prev.totalGrossIncome - invoice.grossIncome // Subtract if unselected
                    : prev.totalGrossIncome + invoice.grossIncome, // Add if selected
                totalExternalExpense: isInvoiceSelected
                    ? prev.totalExternalExpense - invoice.externalExpenses // Subtract if unselected
                    : prev.totalExternalExpense + invoice.externalExpenses, // Add if selected
                totalInternalExpense: isInvoiceSelected
                    ? prev.totalInternalExpense - invoice.internalExpenses // Subtract if unselected
                    : prev.totalInternalExpense + invoice.internalExpenses, // Add if selected
                totalNetIncome: isInvoiceSelected
                    ? prev.totalNetIncome - invoice.netIncome // Subtract if unselected
                    : prev.totalNetIncome + invoice.netIncome, // Add if selected
            }
        })
    }

    return (
        <Card>
            <CardContent className='overflow-x-scroll green-scrollbar rounded-t-none pb-0'>
                <Table className='table-auto whitespace-normal mx-auto text-zentive-black text-base'>
                    <TableHeader className='whitespace-nowrap'>
                        <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                            {tableHeader.map((header, index) => (
                                <TableHead
                                    key={index}
                                    className={cn(
                                        'font-semibold text-zentive-gray-medium text-base whitespace-nowrap',
                                    )}
                                >
                                    <div className='flex flex-row items-center justify-start gap-2'>
                                        {' '}
                                        {index === 0 && (
                                            <Checkbox
                                                onCheckedChange={handleCheckAllChange}
                                                checked={
                                                    isAllChecked ||
                                                    exportAtom?.records?.length ===
                                                        report?.content?.report?.length
                                                }
                                                className='mt-[1px]'
                                            />
                                        )}
                                        {header}
                                    </div>
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {Array.isArray(report?.content?.report) &&
                            report?.content?.report?.length > 0 &&
                            report?.content?.report?.map((r: NetGrossRecordType) => (
                                <TableRow
                                    key={r.quoteNumber as number}
                                    className='whitespace-nowrap'
                                >
                                    <TableCell className='text-zentive-black text-base flex flex-row gap-1 items-center'>
                                        <Checkbox
                                            onCheckedChange={() => handleCheckChange(r)}
                                            checked={exportAtom?.records?.some(
                                                (record) => r?.invoiceId === record.invoiceId,
                                            )}
                                        />
                                        <NavLink
                                            to={r.jobNumber ? `/schedule/jobs/${r.jobId}` : '#'}
                                        >
                                            <Button
                                                className='text-base text-zentive-blue-dark font-semibold'
                                                variant={'link'}
                                            >
                                                {r.jobNumber
                                                    ? RECORD_PREFIX.job +
                                                      formatRecordNumber(r.jobNumber)
                                                    : 'N/A'}
                                            </Button>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell className='text-zentive-black text-base'>
                                        <p>{r.services.join(', ')}</p>
                                    </TableCell>
                                    <TableCell className='text-zentive-black text-base'>
                                        <p>{r.customerName}</p>
                                    </TableCell>
                                    <TableCell className='text-zentive-black text-base'>
                                        <p>
                                            {formatToUTCWithOffset(
                                                r.date,
                                                tz?.timeZoneId as string,
                                                US_FORMAT,
                                            )}
                                        </p>
                                    </TableCell>
                                    <TableCell className='text-right'>
                                        <p>{displayCurrency(r.grossIncome)}</p>
                                    </TableCell>
                                    <TableCell>
                                        <p>{r.paidVia}</p>
                                    </TableCell>
                                    <TableCell>
                                        <NavLink
                                            to={
                                                r.invoiceNumber
                                                    ? `/financial-management/invoicing/view-invoice/${r.invoiceId}`
                                                    : '#'
                                            }
                                        >
                                            <Button
                                                className='text-base text-zentive-blue-dark font-semibold'
                                                variant={'link'}
                                            >
                                                {r.invoiceNumber
                                                    ? RECORD_PREFIX.invoice +
                                                      formatRecordNumber(r.invoiceNumber)
                                                    : 'N/A'}
                                            </Button>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell>
                                        <NavLink
                                            to={
                                                r.invoiceNumber
                                                    ? `/financial-management/quoting/view?quoteId=${r.quoteId}`
                                                    : '#'
                                            }
                                        >
                                            <Button
                                                className='text-base text-zentive-blue-dark font-semibold'
                                                variant={'link'}
                                            >
                                                {r.quoteNumber
                                                    ? RECORD_PREFIX.quote +
                                                      formatRecordNumber(r.quoteNumber)
                                                    : 'N/A'}
                                            </Button>
                                        </NavLink>
                                    </TableCell>
                                </TableRow>
                            ))}
                        {report && report?.content?.report?.length > 0 && (
                            <TableRow>
                                <TableCell>
                                    <p className='font-semibold'>Total</p>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                    <p className='font-semibold text-right'>
                                        {displayCurrency(report?.content?.totalGrossIncome)}
                                    </p>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </CardContent>
            <div className='p-5 pb-0 flex flex-row items-center'>
                <Pagination
                    pagination={pagination}
                    setPagination={setPagination}
                    itemsPerPage={report?.meta?.pageSize ?? 5}
                    totalRecords={report?.meta?.totalRecords ?? 0}
                />
            </div>
        </Card>
    )
}
export default GrossTable
