import { FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { Textarea } from '@/components/TextArea'
import { useFormContext } from 'react-hook-form'

interface IHeaderClientDetails {
    invoiceNumber: number
}

const HeaderClientDetails = ({ invoiceNumber }: IHeaderClientDetails) => {
    const { watch, control } = useFormContext()

    const invoiceFields = watch()

    return (
        <div className='flex flex-row justify-between mx-5 mt-6'>
            <div className='flex flex-col mx-5 mt-2'>
                <p className='text-2xl font-bold leading-none tracking-tight'>Invoice for</p>
                <div className='text-zentive-green-darker font-bold text-lg flex flex-row mb-4'>
                    {invoiceFields?.profile?.firstName}

                    {invoiceFields?.profile?.lastName}
                </div>
                <div className='flex flex-row'>
                    <div className='flex flex-col mr-16'>
                        <div className='flex flex-row pt-6 font-bold mb-4'>
                            <span className='font-bold text-base'>Property Address</span>
                        </div>
                        <p className='text-zentive-gray-medium'>
                            {invoiceFields.address?.streetAddress}
                        </p>
                        <p className='text-zentive-gray-medium'>
                            {invoiceFields?.address?.city}, {invoiceFields?.address?.state}
                            {invoiceFields?.address?.zipCode}
                        </p>
                    </div>
                    <div className='flex flex-col mb-8'>
                        <span className=' pt-6 font-bold mb-4 text-base'>Contact Details</span>
                        <p className='text-zentive-gray-medium'>
                            {invoiceFields?.profile?.phoneNumber}
                        </p>
                        <p className='text-zentive-gray-medium'>{invoiceFields?.profile?.email}</p>
                    </div>
                </div>
            </div>
            <div className='flex flex-shrink-0'>
                <div>
                    <div className='flex flex-row'>
                        <p className='text-xl font-semibold'>Invoice Number: &nbsp;</p>
                        <p className='border-2 rounded-md pr-20 py-0'> 00{invoiceNumber}</p>
                    </div>
                    <div>
                        <p className='text-xl font-semibold'>Shared Notes:</p>
                        <FormField
                            control={control}
                            name='sharedNotes'
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Textarea
                                            className='border p-4 h-24 w-[350px] max-w-[350px] border-zentive-gray-light rounded-md text-zentive-black text-base focus-visible:ring-0 focus-visible:ring-offset-0 bg-white placeholder-transparent'
                                            placeholder='Please add a note'
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderClientDetails
