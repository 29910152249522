import { getJobCompletionReport } from '@/api/reports'
import { JobCompletionRecordType, JobCompletionReportType } from '@/api/reports/schema'
import { Button } from '@/components/Button'
import { Card, CardContent } from '@/components/Card'
import { Checkbox } from '@/components/Checkbox'
import FeatureRestrictionModal from '@/components/FeatureRestrictionModal'
import { Pagination } from '@/components/Pagination'
import { PaginationType } from '@/components/Pagination/schema'
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/Table'
import { RECORD_PREFIX, US_FORMAT } from '@/constants'
import { isViewedAsAtom, timezoneAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { jobCompletionExportAtom, jobCompletionFilterAtom } from '@/store/reports'
import { cn, displayCurrency, formatRecordNumber } from '@/utils/helper'
import { formatToUTCWithOffset, formatWorkingHours } from '@/utils/time'
import { useQuery } from '@tanstack/react-query'
import { useAtom, useAtomValue } from 'jotai'
import { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

const tableHeader = [
    'Job Number',
    'Crew Name',
    'Job Type',
    'Date Completed',
    'Invoice Amount',
    'Labor Cost',
    'Time Spent',
    'Address',
    'Invoice Number',
    'Quote Number',
]

interface JobCompletionProps {
    isFeatureAvailable: boolean
}

const JobCompletionTable: React.FC<JobCompletionProps> = ({ isFeatureAvailable }) => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)
    const navigate = useNavigate()
    const tz = useAtomValue(timezoneAtom)
    const [restrictionModal, setRestrictionModal] = useState<boolean>(false)
    const [isAllChecked, setIsAllChecked] = useState(false)
    const jobCompletionFilter = useAtomValue(jobCompletionFilterAtom)
    const [exportAtom, setExportAtom] = useAtom(jobCompletionExportAtom)
    const [pagination, setPagination] = useState<PaginationType>({
        page: 1,
        pageSize: 5,
    })

    const { data } = useQuery<JobCompletionReportType>({
        queryKey: ['jobCompletionReport', user?.businessId, jobCompletionFilter, pagination],
        queryFn: () =>
            getJobCompletionReport(user?.businessId as string, jobCompletionFilter, pagination),
    })

    const handleRowClick = (jobId: string) => {
        if (isFeatureAvailable) {
            navigate(`/reports/jobs-completion-report-details/${jobId}`)
        } else {
            setRestrictionModal(true)
        }
    }

    const handleCheckAllChange = (isChecked: boolean) => {
        setIsAllChecked(isChecked)

        if (isChecked) {
            // Add all available records to exportAtom
            const allRecords = data?.content?.report ?? []

            setExportAtom({
                records: allRecords,
                totalInvoiceAmount: allRecords.reduce(
                    (total, record) => total + (record.invoiceAmount ?? 0),
                    0,
                ),
                totalLaborCost: allRecords.reduce(
                    (total, record) => total + (record.laborCost ?? 0),
                    0,
                ),
                totalTimeSpent: allRecords.reduce(
                    (total, record) => total + (record.timeSpent ?? 0),
                    0,
                ),
            })
        } else {
            // Clear all records from exportAtom
            setExportAtom({
                records: [],
                totalInvoiceAmount: 0,
                totalLaborCost: 0,
                totalTimeSpent: 0,
            })
        }
    }

    const handleCheckChange = (job: JobCompletionRecordType) => {
        setExportAtom((prev) => {
            if (!prev) {
                // If there is no existing filter, create one with the new invoiceId
                return {
                    records: [job],
                    totalInvoiceAmount: job.invoiceAmount ?? 0,
                    totalLaborCost: job.laborCost ?? 0,
                    totalTimeSpent: job.timeSpent ?? 0,
                }
            }

            const isJobSelected = prev.records?.some((record) => record.jobId === job.jobId)

            // Update records based on whether the job is being added or removed
            const updatedJobs = isJobSelected
                ? prev.records.filter((record) => record.jobId === job.jobId) // Remove if exists
                : [...(prev.records || []), job] // Add if it doesn't exist

            return {
                records: updatedJobs,
                totalInvoiceAmount: isJobSelected
                    ? prev.totalInvoiceAmount - (job.invoiceAmount ?? 0)
                    : prev.totalInvoiceAmount + (job.invoiceAmount ?? 0),
                totalLaborCost: isJobSelected
                    ? prev.totalLaborCost - (job.laborCost ?? 0)
                    : prev.totalLaborCost + (job.laborCost ?? 0),
                totalTimeSpent: isJobSelected
                    ? prev.totalTimeSpent - (job.timeSpent ?? 0)
                    : prev.totalTimeSpent + (job.timeSpent ?? 0),
            }
        })
    }

    return (
        <Card>
            <CardContent className='overflow-x-scroll green-scrollbar rounded-t-none pb-0'>
                <Table className='table-auto whitespace-normal mx-auto text-zentive-black text-base'>
                    <TableHeader className='whitespace-nowrap'>
                        <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                            {tableHeader.map((header, index) => (
                                <TableHead
                                    key={index}
                                    className={cn(
                                        'font-semibold text-zentive-gray-medium text-base whitespace-nowrap',
                                    )}
                                >
                                    <div className='flex flex-row items-center justify-start gap-2'>
                                        {' '}
                                        {index === 0 && (
                                            <Checkbox
                                                onCheckedChange={handleCheckAllChange}
                                                checked={
                                                    isAllChecked ||
                                                    exportAtom?.records?.length ===
                                                        data?.content?.report?.length
                                                }
                                                className='mt-[1px]'
                                            />
                                        )}
                                        {header}
                                    </div>
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {Array.isArray(data?.content?.report) &&
                            data?.content?.report?.length > 0 &&
                            data?.content?.report?.map((r: JobCompletionRecordType) => (
                                <TableRow
                                    key={r.quoteNumber as number}
                                    className='whitespace-nowrap'
                                >
                                    <TableCell className='text-zentive-black cursor-pointer text-base flex flex-row gap-2 items-center'>
                                        <Checkbox
                                            onCheckedChange={() => handleCheckChange(r)}
                                            checked={exportAtom?.records?.some(
                                                (record) => r.jobId === record.jobId,
                                            )}
                                        />
                                        <NavLink
                                            to={r.jobNumber ? `/schedule/jobs/${r.jobId}` : '#'}
                                        >
                                            <Button
                                                className='text-base text-zentive-blue-dark font-semibold'
                                                variant={'link'}
                                            >
                                                {r.jobNumber
                                                    ? RECORD_PREFIX.job +
                                                      formatRecordNumber(r.jobNumber)
                                                    : 'N/A'}
                                            </Button>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell
                                        className='text-zentive-black cursor-pointer text-base'
                                        onClick={() => {}}
                                    >
                                        {r.crewName}
                                    </TableCell>
                                    <TableCell
                                        className='text-zentive-black cursor-pointer text-base'
                                        onClick={() => handleRowClick(r.jobId as string)}
                                    >
                                        <p>{r.services.join(', ')}</p>
                                    </TableCell>
                                    <TableCell
                                        className='text-zentive-black cursor-pointer text-base'
                                        onClick={() => handleRowClick(r.jobId as string)}
                                    >
                                        <p>
                                            {formatToUTCWithOffset(
                                                r.date,
                                                tz?.timeZoneId as string,
                                                US_FORMAT,
                                            )}
                                        </p>
                                    </TableCell>
                                    <TableCell
                                        onClick={() => handleRowClick(r.jobId as string)}
                                        className='text-right'
                                    >
                                        <p>{displayCurrency(r.invoiceAmount)}</p>
                                    </TableCell>
                                    <TableCell
                                        onClick={() => handleRowClick(r.jobId as string)}
                                        className='text-right'
                                    >
                                        <p>{displayCurrency(r.laborCost)}</p>
                                    </TableCell>
                                    <TableCell
                                        onClick={() => handleRowClick(r.jobId as string)}
                                        className='text-right'
                                    >
                                        <p>{formatWorkingHours(r.timeSpent)}</p>
                                    </TableCell>
                                    <TableCell onClick={() => handleRowClick(r.jobId as string)}>
                                        <p>{r.address}</p>
                                    </TableCell>
                                    <TableCell>
                                        <NavLink
                                            to={
                                                r.invoiceNumber
                                                    ? `/financial-management/invoicing/view-invoice/${r.invoiceId}`
                                                    : '#'
                                            }
                                        >
                                            <Button
                                                className='text-base text-zentive-blue-dark font-semibold'
                                                variant={'link'}
                                            >
                                                {r.invoiceNumber
                                                    ? RECORD_PREFIX.invoice +
                                                      formatRecordNumber(r.invoiceNumber)
                                                    : 'N/A'}
                                            </Button>
                                        </NavLink>
                                    </TableCell>
                                    <TableCell>
                                        <NavLink
                                            to={
                                                r.quoteId
                                                    ? `/financial-management/quoting/view?quoteId=${r.quoteId}`
                                                    : '#'
                                            }
                                        >
                                            <Button
                                                className='text-base text-zentive-blue-dark font-semibold'
                                                variant={'link'}
                                            >
                                                {r.quoteNumber
                                                    ? RECORD_PREFIX.quote +
                                                      formatRecordNumber(r.quoteNumber)
                                                    : 'N/A'}
                                            </Button>
                                        </NavLink>
                                    </TableCell>
                                </TableRow>
                            ))}
                        {data?.content?.report && (
                            <TableRow key='total-row' className='whitespace-nowrap'>
                                <TableCell>
                                    <p className='font-semibold text-base'>Total</p>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                    <p className='font-semibold text-base text-right'>
                                        {displayCurrency(data?.content?.totalInvoiceAmount ?? 0)}
                                    </p>
                                </TableCell>
                                <TableCell>
                                    <p className='font-semibold text-base text-right'>
                                        {displayCurrency(data?.content?.totalLaborCost ?? 0)}
                                    </p>
                                </TableCell>
                                <TableCell>
                                    <p className='font-semibold text-base text-right'>
                                        {formatWorkingHours(data?.content?.totalTimeSpent ?? 0)}
                                    </p>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </CardContent>
            <div className='p-5 pb-0 flex flex-row items-center'>
                <Pagination
                    pagination={pagination}
                    setPagination={setPagination}
                    itemsPerPage={data?.meta?.pageSize ?? 5}
                    totalRecords={data?.meta?.totalRecords ?? 0}
                />
            </div>
            <FeatureRestrictionModal open={restrictionModal} setOpen={setRestrictionModal} />
        </Card>
    )
}
export default JobCompletionTable
