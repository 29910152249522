import { useEffect, useState } from 'react'
import { Tabs, TabsList, TabsTrigger } from '@radix-ui/react-tabs'
import SearchBar from '@/components/InputSearchBox'
import { useAtom, useAtomValue } from 'jotai'
import { selectedStatus } from '@/store/customer'
import { useQuery } from '@tanstack/react-query'
import { getAllCustomers } from '@/api/profile'
import { AccountSortType, BulkProfileUpdateStatusType, FullProfileType } from '@/api/profile/schema'
import { BREADCRUMBS_PADDING_STYLE, USER_STATUS } from '@/constants'
import { useAppNavigate } from '@/hooks/useAppNavigate'
import CustomerTable from './CustomerTable'
import { Map } from 'lucide-react'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'
import { viewedAsUserAtom, isViewedAsAtom, userAtom } from '@/store/auth'
import AppSkeletonLoadingState from '@/components/LoadingState/TableLoadingState'
import DeleteModal from './DeleteModal'
import BreadCrumbs from '@/components/BreadCrumbs'
import { paginationAtom } from '@/store/manageSubs'
import { Pagination } from '@/components/Pagination'
import Tooltip from '@/components/Tooltip'
import { useMediaQuery } from 'react-responsive'
import { cn } from '@/utils/helper'
import SortAccountButton from '@/components/SortAccountButton'
import { AddCustomerButton } from './AddCustomerButton'
import AddCustomerDropdown from './ImportCustomerDropdown'

const ListView = () => {
    const { navigateTo } = useAppNavigate()

    const isViewedAsOwner = useAtomValue(isViewedAsAtom)

    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType

    const [profilesToUpdate, setProfilesToUpdate] = useState<BulkProfileUpdateStatusType>({
        profileIds: [],
        status: USER_STATUS.DELETED,
        businessId: user.businessId,
    })

    const [isCheckAll, setIsCheckAll] = useState<boolean>(false)
    const [sort, setSort] = useState<AccountSortType>('alphabetically')
    const [selected, setSelected] = useAtom(selectedStatus)
    const [searchKeyword, setSearchKeyword] = useState('')
    const [pagination, setPagination] = useAtom(paginationAtom)

    const {
        data: customersData,
        isPending,
        isSuccess,
    } = useQuery({
        queryKey: ['Customers', sort, searchKeyword, selected, sort, pagination],
        queryFn: () => getAllCustomers(user.businessId, selected, searchKeyword, sort, pagination),
    })
    const onSwitchTab = (status: string) => {
        setSelected(status)
        setProfilesToUpdate({ profileIds: [] })
        setIsCheckAll(false)
    }

    useEffect(() => {
        onSwitchTab('ACT')
    }, [])

    const renderEmptyState = () => {
        return (
            <div className='bg-white mt-4 rounded-lg border bg-card'>
                <div className='flex justify-center'>
                    <img
                        src={emptyPlaceholder}
                        alt='empty-table-placeholder'
                        className='my-12 h-60'
                    />
                </div>
                <p className='pb-4 text-center text-2xl text-gray-900'>Currently Empty</p>
            </div>
        )
    }

    const isTableEmpty = () => {
        if (!searchKeyword) {
            if (!customersData?.content?.length) return true
            else return false
        }
    }

    const xl_vw_already = useMediaQuery({ maxWidth: 1370 })
    const xxl_vw_already = useMediaQuery({ maxWidth: 1620 })

    const getCustomerCountForStatus = (status: string) => {
        return customersData?.content.filter((customer) => customer.status === status)?.length || 0
    }

    return (
        <div className={cn('w-full h-full', BREADCRUMBS_PADDING_STYLE)}>
            <BreadCrumbs titleName='Customer List' containerClassName='mb-5' />
            <div className='w-full flex justify-between mt-1'>
                <SearchBar
                    placeHolder='Search for...'
                    onSearchChange={(event) => setSearchKeyword(event.target.value)}
                    value={searchKeyword}
                    disabled={isTableEmpty()}
                />
                <div className='flex justify-end items-end gap-3'>
                    <div className='mx-3 h-full flex items-center gap-5'>
                        {isTableEmpty() ? null : (
                            <div className={cn('text-start text-zentive-black text-base')}>
                                <Tooltip position='top' content='Filter'>
                                    <p
                                        className={cn(
                                            xxl_vw_already ? 'w-24 truncate' : 'w-full',
                                            xl_vw_already && 'w-full',
                                        )}
                                    >
                                        <SortAccountButton sort={sort} setSort={setSort} />
                                    </p>
                                </Tooltip>
                            </div>
                        )}

                        <DeleteModal
                            profilesToUpdate={profilesToUpdate}
                            onSuccessRestore={() => {
                                setProfilesToUpdate({
                                    profileIds: [],
                                    status: USER_STATUS.DELETED,
                                    businessId: user?.businessId,
                                })
                            }}
                        />
                    </div>
                    <AddCustomerDropdown />
                    <AddCustomerButton businessId={user.businessId} />
                </div>
            </div>
            <Tabs
                defaultValue={USER_STATUS.ACTIVATED}
                onValueChange={(val) => setSelected(val)}
                className='bg-white rounded-md w-full'
            >
                <TabsList className='w-full flex flex-row mt-[26px] space-x-4'>
                    <TabsTrigger
                        onClick={() => onSwitchTab(USER_STATUS.ACTIVATED)}
                        value={USER_STATUS.ACTIVATED}
                        className={
                            'w-1/3 h-[54px] text-lg text-zentive-gray-medium data-[state=active]:text-zentive-blue-dark data-[state=active]: border-zentive-blue-dark data-[state=active]:font-bold data-[state=active]:border-b-4 data-[state=active]:bg-white sm:truncate ...'
                        }
                    >
                        Active ({getCustomerCountForStatus(USER_STATUS.ACTIVATED)})
                    </TabsTrigger>
                    <TabsTrigger
                        onClick={() => onSwitchTab(USER_STATUS.INACTIVE)}
                        value={USER_STATUS.INACTIVE}
                        className={
                            'w-1/3 h-[54px] text-lg text-zentive-gray-medium data-[state=active]:text-zentive-blue-dark data-[state=active]: border-zentive-blue-dark data-[state=active]:font-bold data-[state=active]:border-b-4 data-[state=active]:bg-white sm:truncate ...'
                        }
                    >
                        Inactive ({getCustomerCountForStatus(USER_STATUS.INACTIVE)})
                    </TabsTrigger>
                    <div className='h-[54px] w-1/3 flex flex-row justify-end items-center gap-[8.5px] pr-[16.5px] cursor-pointer'>
                        <button
                            className='flex flex-row'
                            onClick={() => {
                                navigateTo('/customers/customer-list/map-view')
                            }}
                            disabled={customersData?.content?.length === 0}
                        >
                            <Map className='text-zentive-blue-dark' />{' '}
                            <p className='text-lg text-zentive-gray-medium'>Map View</p>
                        </button>
                    </div>
                </TabsList>
            </Tabs>
            {isPending && !isSuccess && <AppSkeletonLoadingState />}
            {isSuccess && customersData!.content?.length > 0 && (
                <div className='mt-4'>
                    <CustomerTable
                        customers={customersData}
                        setProfilesToUpdate={setProfilesToUpdate}
                        profilesToUpdate={profilesToUpdate}
                        isCheckAll={isCheckAll}
                        setIsCheckAll={setIsCheckAll}
                    />
                </div>
            )}
            {isSuccess && customersData?.content?.length === 0 && renderEmptyState()}

            {!!customersData?.content?.length && (
                <div className='mb-5 mt-3'>
                    {pagination.pageSize !== undefined && (
                        <Pagination
                            itemsPerPage={pagination.pageSize}
                            setPagination={setPagination}
                            totalRecords={customersData?.meta.totalRecords ?? 1}
                            pagination={pagination}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default ListView
