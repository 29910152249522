import { CustomerProfileType } from '@/api/profile/schema'
import InvoiceStatusBar from '@/components/InvoiceStatusBar'

const HeaderClientDetails = ({
    profile,
    status,
    invoiceNumber,
    sharedNotes,
}: {
    profile: CustomerProfileType
    status?: string
    invoiceNumber?: number
    sharedNotes?: string
}) => {
    return (
        <div className='flex flex-row justify-between'>
            <div className='flex flex-col mx-5 mt-2'>
                <p className='text-2xl font-bold leading-none tracking-tight'>Invoice for</p>
                <div className='text-zentive-green-darker font-semibold text-2xl mt-5'>
                    {profile?.firstName} {profile?.lastName}
                </div>
                <div className='flex flex-row gap-x-5'>
                    <div className='flex flex-col whitespace-no-wrap'>
                        <div className='pt-6'>
                            <span className='font-semibold text-base'>Property Address</span>
                        </div>
                        <div>
                            <p className='text-zentive-gray-medium'>
                                {profile?.address?.streetAddress}
                            </p>
                            <p className='text-zentive-gray-medium'>
                                {profile?.address?.city}, {profile?.address?.state}{' '}
                            </p>
                            <p className='text-zentive-gray-medium'>{profile?.address?.zipCode}</p>
                        </div>
                    </div>
                    <div className='flex flex-col mb-4'>
                        <span className='font-semibold pt-6 text-base'>Contact Details</span>
                        <p className='text-zentive-gray-medium'>{profile?.phoneNumber}</p>
                        <p className='text-zentive-gray-medium'>{profile?.email}</p>
                    </div>
                    <div className='flex flex-row'>
                        <div className='font-semibold text-base mr-4 pt-6'> Status: </div>{' '}
                        <InvoiceStatusBar status={status as string} />
                    </div>
                </div>
            </div>
            <div className='flex flex-shrink-0'>
                <div>
                    <div className='flex flex-row'>
                        <p className='text-2xl font-bold'>Invoice Number: &nbsp;</p>
                        <p className='text-2xl font-bold border-2 rounded-md pr-20 py-0'>
                            {' '}
                            000{invoiceNumber}
                        </p>
                    </div>
                    <div className=' max-w-[700px] md:w-[400px] lg:w-[600px]'>
                        <p className='text-lg mt-5 font-semibold'>Shared Notes:</p>
                        <p className='border-2 h-24 break-words rounded-md overflow-hidden text-ellipsis overflow-y-auto'>
                            {sharedNotes ?? 'No notes attached to display'}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderClientDetails
