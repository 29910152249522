import { Button } from '@/components/Button'
import SearchBar from '@/components/InputSearchBox'
import { CiTrash } from 'react-icons/ci'
import InvoicingTableContents from './InvoicingTable'
import { useNavigate } from 'react-router-dom'
import BreadCrumbs from '@/components/BreadCrumbs'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { getAllInvoices } from '@/api/invoicing'
import { useQuery } from '@tanstack/react-query'
import emptyPlaceholder from '@/assets/private/empty_placeholder.svg'
import { userAtom } from '@/store/auth'
import { Pagination } from '@/components/Pagination'
import { BREADCRUMBS_PADDING_STYLE } from '@/constants'
import { useEffect, useState } from 'react'
import { BulkDeleteInvoiceType } from '@/api/invoicing/schema'
import DeleteInvoicesModal from './DeleteInvoicesModal'
import { jobGenerateInvoiceAtom } from '@/store/job'
import { invoicePaginationAtom } from '@/store/paginationAtom'
import { invoiceSortByAtom, invoiceSortOrderAtom } from '@/store/invoice'

const Invoicing = () => {
    const navigate = useNavigate()

    const [searchKeyword, setSearchKeyword] = useState('')
    const [invoiceToUpdate, setInvoiceToUpdate] = useState<BulkDeleteInvoiceType>({
        invoiceIds: [],
    })
    const [isCheckAll, setIsCheckAll] = useState<boolean>(false)
    const [deleteInvoiceModal, setDeleteInvoiceModal] = useState<boolean>(false)

    const user = useAtomValue(userAtom)
    const [pagination, setPagination] = useAtom(invoicePaginationAtom)
    const setGenerateInvoiceData = useSetAtom(jobGenerateInvoiceAtom)
    const invoiceSortBy = useAtomValue(invoiceSortByAtom)
    const invoiceSortOrder = useAtomValue(invoiceSortOrderAtom)

    const {
        data: invoiceListType,
        isLoading,
        isSuccess,
    } = useQuery({
        queryKey: [
            'InvoiceType',
            searchKeyword,
            pagination,
            user?.businessId,
            invoiceSortBy,
            invoiceSortOrder,
        ],
        queryFn: () =>
            getAllInvoices(user?.businessId as string, searchKeyword, pagination, {
                sortBy: invoiceSortBy,
                sortOrder: invoiceSortOrder,
            }),
    })

    useEffect(() => {
        const quoteIdsPerPage = (): string[] => {
            const invoiceIds: string[] = []
            invoiceListType?.content?.forEach((item) => {
                if (item.invoiceId) {
                    invoiceIds.push(item.invoiceId)
                }
            })
            return invoiceIds
        }
        const areAllInvoiceChecked = () => {
            let allChecked = true
            quoteIdsPerPage()?.forEach((invoiceId) => {
                if (!invoiceToUpdate.invoiceIds.includes(invoiceId)) {
                    allChecked = false
                    return
                }
            })
            setIsCheckAll(allChecked)
        }

        areAllInvoiceChecked()
    }, [invoiceToUpdate, invoiceListType])

    return (
        <div className={BREADCRUMBS_PADDING_STYLE}>
            <DeleteInvoicesModal
                open={deleteInvoiceModal}
                setOpen={setDeleteInvoiceModal}
                invoices={invoiceToUpdate}
            />
            <BreadCrumbs titleName='Invoicing' />
            <div className='flex flex-row items-start text-left justify-between mt-10'>
                <SearchBar
                    placeHolder='Search for...'
                    onSearchChange={(e) => setSearchKeyword(e.target.value)}
                    value={searchKeyword}
                />
                <div className='flex flex-row items-end'>
                    <Button
                        type='button'
                        variant='ghost'
                        className='text-gray-500 px-8 py-6 border border-gray-500 font-semibold mr-5'
                        onClick={() => setDeleteInvoiceModal(true)}
                        disabled={invoiceToUpdate?.invoiceIds?.length === 0}
                    >
                        <CiTrash className='w-6 h-6 mr-2' />
                        Delete
                    </Button>
                    <Button
                        type='button'
                        onClick={() => {
                            setGenerateInvoiceData(null)
                            navigate('/financial-management/invoicing/add-invoice')
                        }}
                        className='border bg-zentive-green-dark text-white px-8 py-6'
                    >
                        + Add Invoice
                    </Button>
                </div>
            </div>

            <div className='mt-4 w-full'>
                {isSuccess && !isLoading ? (
                    <InvoicingTableContents
                        invoice={invoiceListType}
                        setInvoiceToUpdate={setInvoiceToUpdate}
                        invoiceToUpdate={invoiceToUpdate}
                        isCheckAll={isCheckAll}
                        setIsCheckAll={setIsCheckAll}
                    />
                ) : null}

                {!invoiceListType?.content?.length && isSuccess ? (
                    <div className='bg-white w-auto rounded-sm -mt-4'>
                        <div className='mt-4 w-[1260px]'> </div>
                        <div className='flex justify-center'>
                            <img
                                src={emptyPlaceholder}
                                alt='empty-table-placeholder'
                                className='my-12 h-60'
                            />
                        </div>
                        <p className='pb-4 text-center text-2xl text-gray-900'> Currently Empty </p>
                    </div>
                ) : null}

                <div className='mb-5 mt-3'>
                    {pagination.pageSize !== undefined && (
                        <Pagination
                            itemsPerPage={pagination.pageSize}
                            setPagination={setPagination}
                            totalRecords={invoiceListType?.meta?.totalRecords ?? 1}
                            pagination={pagination}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default Invoicing
