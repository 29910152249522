import { Card, CardFooter } from '@/components/Card'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import ViewInvoiceHeader from './CardHeader'
import { getInvoice } from '@/api/invoicing'
import { InvoiceType } from '@/api/invoicing/schema'
import { useEffect, useState } from 'react'
import { invoiceUserAtom } from '@/store/owner'
import { useAtomValue, useSetAtom } from 'jotai'
import ViewInvoiceContent from './CardContent'
import { Button } from '@/components/Button'
import { cn, formatFullName, formatRecordNumber } from '@/utils/helper'
import { BREADCRUMBS_PADDING_STYLE, INVOICE_STATUS, RECORD_PREFIX } from '@/constants'
import BreadCrumbs from '@/components/BreadCrumbs'
import { CustomerProfileType, FullProfileType } from '@/api/profile/schema'
import { useParams } from 'react-router-dom'
import MarkInvoiceAsPaidModal from '../MarkInvoiceAsPaidModal'
import PrintModal from '@/components/PrintModal'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { generateInvoicePdf } from '@/utils/pdf'
import InvoiceHeader from '@/pages/private/CustomerPortal/Invoice/PayInvoice/InvoiceDialog/InvoiceHeader'
import InvoiceContent from '@/pages/private/CustomerPortal/Invoice/PayInvoice/InvoiceDialog/InvoiceContent'
import InvoiceFooter from '@/pages/private/CustomerPortal/Invoice/PayInvoice/InvoiceDialog/InvoiceFooter'

const ViewInvoice = () => {
    const { id: invoiceId } = useParams()
    const [payInvoiceModal, setPayInvoiceModal] = useState<boolean>(false)
    const navigate = useNavigate()
    const setCurrentInvoiceAtom = useSetAtom(invoiceUserAtom)
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType

    const { data: invoice } = useQuery<unknown, AxiosError, InvoiceType>({
        queryKey: ['invoice', invoiceId],
        queryFn: () => getInvoice(invoiceId as string),
    })

    const handleDownloadInvoicePDF = () => {
        generateInvoicePdf(invoice as InvoiceType, user)
    }

    useEffect(() => {
        invoice && setCurrentInvoiceAtom(invoice)
    }, [invoice])

    if (invoice) {
        const invoiceProfile = invoice.profile as CustomerProfileType
        const fullName = formatFullName(invoiceProfile.firstName, invoiceProfile.lastName)

        return (
            <div className={cn('mb-6', BREADCRUMBS_PADDING_STYLE)}>
                <BreadCrumbs
                    titleName={fullName}
                    pathId={`${invoice?.invoiceId}`}
                    label={
                        RECORD_PREFIX.invoice + formatRecordNumber(invoice?.businessInvoiceNumber)
                    }
                />
                <MarkInvoiceAsPaidModal
                    invoice={invoice}
                    setOpen={setPayInvoiceModal}
                    open={payInvoiceModal}
                />
                <div className='mt-[16px]'>
                    <Card className='border-0 border-t-8 border-zentive-green-dark '>
                        <ViewInvoiceHeader
                            invoiceNumber={invoice.businessInvoiceNumber}
                            sharedNotes={invoice?.sharedNotes}
                            profile={invoice.profile as CustomerProfileType}
                            status={invoice?.status}
                        />
                        <ViewInvoiceContent />
                        <CardFooter className='lg:flex flex-row justify-between rounded-b-md bg-gray-200 '>
                            <div className='flex space-x-4 mt-6 ml-6'>
                                <Button
                                    type='button'
                                    variant={'outline'}
                                    className='w-full px-8'
                                    onClick={() => navigate('/financial-management/invoicing')}
                                >
                                    Cancel
                                </Button>
                            </div>
                            <div className='flex flex-row space-x-4 mt-6 mr-6'>
                                <PrintModal
                                    disabled={false}
                                    triggerTitle='Print'
                                    title='Invoice'
                                    triggerClassName='whitespace-nowrap px-8 border font-semibold rounded-md border-zentive-green-dark text-zentive-green-dark bg-background shadow-sm hover:bg-accent'
                                    handleDownloadPDF={handleDownloadInvoicePDF}
                                >
                                    <InvoiceHeader invoice={invoice} />
                                    <InvoiceContent invoice={invoice} />
                                    <InvoiceFooter />
                                </PrintModal>
                                {invoice?.status! === INVOICE_STATUS.OPEN && (
                                    <Button
                                        type='submit'
                                        onClick={() => setPayInvoiceModal(true)}
                                        className='w-full px-20'
                                    >
                                        Mark as Paid
                                    </Button>
                                )}
                            </div>
                        </CardFooter>
                    </Card>
                </div>
            </div>
        )
    }

    return null
}

export default ViewInvoice
