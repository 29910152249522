import { Button } from '@/components/Button'
import { Card, CardFooter } from '@/components/Card'
import { Form } from '@/components/Forms'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { toast } from '@/hooks/useToast'
import { useNavigate, useParams } from 'react-router-dom'
import BreadCrumbs from '@/components/BreadCrumbs'
import { getNextBusinessInvoiceNumber, saveInvoice } from '@/api/invoicing'
import { InvoiceType, invoiceSchema } from '@/api/invoicing/schema'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { customerInvoiceAtom, invoiceContentAtom, invoiceTaxAtom } from '@/store/owner'
import {
    BREADCRUMBS_PADDING_STYLE,
    DEFAULT_QUOTE_TAX_VALUE,
    DEFAULT_QUOTE_VALUE,
} from '@/constants'
import { useEffect } from 'react'
import { jobGenerateInvoiceAtom, jobInvoiceDiscountAtom } from '@/store/job'
import { getProfileWithQuoteByJobId } from '@/api/profile'
import { businessIdAtom, isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { calculateGrossIncome, calculateNetIncome } from '@/utils/calc'
import AddInvoiceFromJobHeader from './CardHeader'
import AddInvoiceFromJobContent from './CardContent'

const AddInvoiceFromJob = () => {
    const { jobId } = useParams()
    const navigate = useNavigate()
    const setCustomer = useSetAtom(customerInvoiceAtom)
    const setInvoiceContent = useSetAtom(invoiceContentAtom)
    const setGenerateInvoiceData = useSetAtom(jobGenerateInvoiceAtom)
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const jobInvoiceDiscount = useAtomValue(jobInvoiceDiscountAtom)
    const [user] = useAtom(isViewedAsOwner ? viewedAsUserAtom : userAtom)
    const businessId = useAtomValue(businessIdAtom)

    const { data: nextInvoiceNumber, isSuccess: isInvoiceNumberSuccess } = useQuery({
        queryKey: ['nextEmpNumber'],
        queryFn: () => getNextBusinessInvoiceNumber(businessId),
    })

    const { data: quote, isSuccess } = useQuery({
        enabled: !!jobId,
        queryKey: ['profileWithQuote', jobId],
        queryFn: () => getProfileWithQuoteByJobId(jobId as string),
    })

    const [taxes, setTaxes] = useAtom(invoiceTaxAtom)

    const methods = useForm<InvoiceType>({
        mode: 'onSubmit',
        resolver: zodResolver(invoiceSchema),
        defaultValues: {
            sharedNotes: '',
        },
    })

    const { mutate: saveInvoiceMu, isPending } = useMutation<ResponseType, AxiosError, InvoiceType>(
        {
            mutationFn: (data) => saveInvoice(data),
            onSuccess: () => {
                toast({
                    description: 'Invoice sent!',
                    variant: 'default',
                })
                setCustomer(null)
                setInvoiceContent(DEFAULT_QUOTE_VALUE)
                setTaxes(DEFAULT_QUOTE_TAX_VALUE)
                setGenerateInvoiceData(null)
                navigate('/financial-management/invoicing')
            },
        },
    )

    const {
        handleSubmit,
        reset,
        formState: { isValid },
        setValue,
    } = methods

    const onSubmit = (data: InvoiceType) => {
        data.productServiceGroup?.forEach((item) => {
            item.cost = item?.cost ? item?.cost * 100 : 0
        })

        const totalServices =
            quote?.productServiceGroup && quote?.productServiceGroup?.length > 0
                ? quote.productServiceGroup
                      .filter((service) => service.type === 'service')
                      .reduce((total, price) => total + (price.cost || 0), 0)
                : 0

        const totalProducts =
            quote?.productServiceGroup && quote?.productServiceGroup?.length > 0
                ? quote.productServiceGroup
                      .filter((product) => product.type === 'product')
                      .reduce((total, price) => total + (price.cost || 0), 0)
                : 0

        const totalInvoiceExpenses =
            quote?.expense && quote.expense?.length > 0
                ? quote?.expense
                      ?.filter((exp) => !exp.isNotIncludedInInvoice)
                      .reduce((total, expense) => total + (expense.total || 0), 0)
                : 0

        const totalExpense = Number(
            (data?.expense && data?.expense?.length > 0
                ? data.expense
                      .filter((exp) => !exp.isNotIncludedInInvoice)
                      .reduce((total, expense) => total + (expense.total || 0), 0)
                : 0
            ).toFixed(2),
        )

        const productsInvoiceTaxAmount = totalProducts * (taxes.productTax / 100)
        const servicesInvoiceTaxAmount = totalServices * (taxes.serviceTax / 100)
        const expensesInvoiceTaxAmount = totalInvoiceExpenses * (taxes.expenseTax / 100)
        const totalInvoiceTaxAmount =
            productsInvoiceTaxAmount + servicesInvoiceTaxAmount + expensesInvoiceTaxAmount
        data.expenseTax = taxes.expenseTax
        data.productTax = taxes.productTax
        data.serviceTax = taxes.serviceTax
        data.tax = Number(totalInvoiceTaxAmount.toFixed(2))
        data.discount = jobInvoiceDiscount
        data.subTotal = Number(data?.laborCost) + totalExpense + totalProducts + totalServices
        const total = data.subTotal - data.discount + totalInvoiceTaxAmount
        data.totalAmount = Number(total.toFixed(2))
        data.grossIncome = calculateGrossIncome(
            Number(data.totalAmount.toFixed(2)),
            Number(data.laborCost?.toFixed(2)),
            Number(totalProducts?.toFixed(2)),
        )
        ;(data.netIncome = calculateNetIncome(
            calculateGrossIncome(
                Number(data.totalAmount.toFixed(2)),
                Number(data.laborCost?.toFixed(2)),
                Number(totalProducts?.toFixed(2)),
            ),
            Number(totalInvoiceTaxAmount.toFixed(2)),
            Number(totalExpense?.toFixed(2)),
        )),
        saveInvoiceMu(data)
    }

    useEffect(() => {
        if (quote && isSuccess)
            reset({
                ...quote,
                businessId: user?.businessId,
                profile: {
                    ...quote?.profile,
                    address: quote?.address,
                },
            })
        setValue('businessInvoiceNumber', nextInvoiceNumber as number)
    }, [quote, isSuccess, nextInvoiceNumber])

    return (
        <div className={`${BREADCRUMBS_PADDING_STYLE}`}>
            <BreadCrumbs titleName='Add Invoice' />
            <div className='mt-4 w-full'>
                <Form {...methods}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Card className='border-0 border-t-8 border-zentive-green-dark '>
                            {isSuccess && quote && isInvoiceNumberSuccess && (
                                <AddInvoiceFromJobHeader invoiceNumber={nextInvoiceNumber} />
                            )}

                            {isSuccess && quote && <AddInvoiceFromJobContent />}

                            <CardFooter className='lg:flex lg:justify-end rounded-b-md bg-gray-200 '>
                                <div className='flex space-x-4 mt-6'>
                                    <Button
                                        type='button'
                                        variant={'outline'}
                                        className='w-full'
                                        onClick={() => {
                                            navigate('/financial-management/invoicing')
                                            setInvoiceContent(DEFAULT_QUOTE_VALUE)
                                            setTaxes(DEFAULT_QUOTE_TAX_VALUE)
                                            setGenerateInvoiceData(null)
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type='submit'
                                        className='w-full disabled:bg-zentive-gray-medium'
                                        disabled={!isValid || isPending}
                                    >
                                        Save and Send Invoice
                                    </Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </form>
                </Form>
            </div>
        </div>
    )
}

export default AddInvoiceFromJob
