import { getRevenueReport } from '@/api/reports'
import {
    RevenueReportFilterType,
    RevenueReportRecordType,
    RevenueReportType,
} from '@/api/reports/schema'
import { Button } from '@/components/Button'
import { Card, CardContent } from '@/components/Card'
import { Checkbox } from '@/components/Checkbox'
import { Pagination } from '@/components/Pagination'
import { PaginationType } from '@/components/Pagination/schema'
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '@/components/Table'
import { RECORD_PREFIX, US_FORMAT } from '@/constants'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { revenueReportExportAtom, revenueReportFilterAtom } from '@/store/reports'
import { cn, displayCurrency, formatRecordNumber } from '@/utils/helper'
import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useAtom, useAtomValue } from 'jotai'
import { useState } from 'react'

const tableHeader = [
    'Subscriber ID',
    'Business Name',
    'Plan Type',
    'Billing Cycle',
    'Sub. Start',
    'Sub Status',
    'Total Revenue',
]

const RevenueTable = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    const [isAllChecked, setIsAllChecked] = useState(false)

    const revenueReportFilter = useAtomValue(revenueReportFilterAtom)
    const [exportAtom, setExportAtom] = useAtom(revenueReportExportAtom)

    const [pagination, setPagination] = useState<PaginationType>({
        page: 1,
        pageSize: 5,
    })

    const { data: report } = useQuery<RevenueReportType>({
        queryKey: ['revenueReport', user?.businessId, revenueReportFilter, pagination],
        queryFn: () => getRevenueReport(revenueReportFilter as RevenueReportFilterType, pagination),
    })

    const handleCheckAllChange = (isChecked: boolean) => {
        setIsAllChecked(isChecked)

        if (isChecked) {
            // Add all available records to exportAtom
            const allRecords = report?.content ?? []

            setExportAtom({
                records: allRecords,
            })
        } else {
            // Clear all records from exportAtom
            setExportAtom({
                records: [],
            })
        }
    }

    const handleCheckChange = (owner: RevenueReportRecordType) => {
        setExportAtom((prev) => {
            if (!prev) {
                // If there is no existing filter, create one with the new invoiceId
                return {
                    records: [owner],
                }
            }

            const isInvoiceSelected = prev.records?.some(
                (record) => record.businessId === owner.businessId,
            )

            // Update records based on whether the job is being added or removed
            const updatedJobs = isInvoiceSelected
                ? prev.records.filter((record) => record.businessId !== owner.businessId) // Remove if exists
                : [...(prev.records || []), owner] // Add if it doesn't exist

            return {
                records: updatedJobs,
            }
        })
    }

    return (
        <>
            <Card>
                <CardContent className='overflow-x-scroll green-scrollbar rounded-t-none pb-0'>
                    <Table className='table-auto whitespace-normal mx-auto text-zentive-black text-base'>
                        <TableHeader className='whitespace-nowrap'>
                            <TableRow key='sub-status-tabs' className='whitespace-nowrap'>
                                {tableHeader.map((header, index) => (
                                    <TableHead
                                        key={index}
                                        className={cn(
                                            'font-semibold text-zentive-gray-medium text-base whitespace-nowrap',
                                        )}
                                    >
                                        <div className='flex flex-row items-center justify-start gap-2'>
                                            {' '}
                                            {index === 0 && (
                                                <Checkbox
                                                    onCheckedChange={handleCheckAllChange}
                                                    checked={
                                                        isAllChecked ||
                                                        exportAtom?.records?.length ===
                                                            report?.content?.length
                                                    }
                                                    className='mt-[1px]'
                                                />
                                            )}
                                            {header}
                                        </div>
                                    </TableHead>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {Array.isArray(report?.content) &&
                                report?.content?.length > 0 &&
                                report?.content?.map((r: RevenueReportRecordType, i: number) => (
                                    <TableRow
                                        key={('external' + r.businessId + '-' + i) as string}
                                        className='whitespace-nowrap'
                                    >
                                        <TableCell className='text-zentive-black  flex flex-row gap-2 items-center'>
                                            <Checkbox
                                                onCheckedChange={() => handleCheckChange(r)}
                                                checked={exportAtom?.records?.some(
                                                    (record) => r.businessId === record.businessId,
                                                )}
                                            />

                                            <Button
                                                className=' text-zentive-blue-dark font-semibold'
                                                variant={'link'}
                                            >
                                                {r.accountNumber
                                                    ? RECORD_PREFIX.subs +
                                                      formatRecordNumber(r.accountNumber)
                                                    : 'N/A'}
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            <p>{r.businessName}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{r.planType}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{r.billingCycle}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{dayjs(r.subStart).format(US_FORMAT)}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{r.subStatus}</p>
                                        </TableCell>
                                        <TableCell>
                                            <p>{displayCurrency(r.totalRevenue)}</p>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </CardContent>
                <div className='p-5 pb-0 flex flex-row items-center'>
                    <Pagination
                        pagination={pagination}
                        setPagination={setPagination}
                        itemsPerPage={report?.meta?.pageSize ?? 5}
                        totalRecords={report?.meta?.totalRecords ?? 0}
                    />
                </div>
            </Card>
        </>
    )
}
export default RevenueTable
