import { PaginationType } from '@/components/Pagination/schema'
import { axiosInstance } from '../axiosInstance'
import {
    CreateGroupAndMessagingType,
    CustomerAutoPayType,
    MessageListType,
    MessagesInboxListType,
} from './schema'
import { SortParams } from '../job'

export const getInvoiceTotalOwed = async (profileId: string, businessId: string) => {
    const response = await axiosInstance.get(
        `/invoice/owed?profileId=${profileId}&businessId=${businessId}`,
    )

    return response.data.data
}

export const getInvoiceList = async (profileId: string, businessId: string) => {
    const response = await axiosInstance.get(
        `/invoice?profileId=${profileId}&businessId=${businessId}`,
    )
    return response.data.data
}

export const payOwedInvoice = async (customerID: string, businessId: string) => {
    const response = await axiosInstance.post(
        `/stripe/invoice/pay?customerID=${customerID}&businessID=${businessId}`,
    )
    return response.data
}

export const updateCustomerAutoPay = async (updateAutoPay: CustomerAutoPayType) => {
    const res = await axiosInstance.post(`stripe/customer/autopay`, updateAutoPay)

    return res
}
export const createCustomerMessaging = async (params: CreateGroupAndMessagingType) => {
    const res = await axiosInstance.post(`customer/messaging`, params)
    return res.data as { message: string }
}

export const getCustomerWorkServiceHistory = async (profileId: string) => {
    const response = await axiosInstance.get(`/customer/servicehistory/${profileId}`)

    return response.data
}

export const getAllCustomerMessages = async (
    profileId: string,
    status: string,
    keyword: string,
    p: PaginationType,
    sort: SortParams
): Promise<MessagesInboxListType> => {
    const { page, pageSize } = p
    
    const params = {
        profileId: profileId,
        status: status,
        keyword: keyword,
        page: page,
        pageSize: pageSize,
        sortBy: sort.sortBy,
        sortOrder: sort.sortOrder,
    }

    const response = await axiosInstance.get(
        `customer/messaging`, { params }
    )

    return response.data
}

export const getCustomerMessageByID = async (messageID: string): Promise<MessageListType> => {
    const response = await axiosInstance.get(`customer/messaging/${messageID}`)
    return response.data.content
}

export const deleteMessage = async (messageId: string) => {
    await axiosInstance.delete('/customer/messaging/' + messageId)
}

export const getCustomerCsvTemplate = async () => {
    const response = await axiosInstance.get(`public/download-customer-import-template`)
    return response.data
}
