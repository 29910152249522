import './App.css'
import { RouterProvider } from 'react-router-dom'
import Routes from './routes'
import { useEffect } from 'react'
import { atom, useAtom } from 'jotai'
import i18n, { i18nConf } from './i18n'
// TO DO: Add route authentication.

const i18nAtom = atom<boolean>(false)

const App = () => {
    const [isInitialized, setIsInitialized] = useAtom(i18nAtom)
    console.log('version 1.1.5')

    useEffect(() => {
        const initTranslator = async () => {
            await i18n.init(i18nConf)
            setIsInitialized(true)
        }

        initTranslator()
    }, [])

    return isInitialized && <RouterProvider router={Routes} />
}

export default App
