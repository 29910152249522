import { getCustomerMessageByID } from '@/api/customer'
import {
    CreateGroupAndMessagingType,
    MessageListType,
    createGroupAndMessagingSchema,
} from '@/api/customer/schema'
import BreadCrumbs from '@/components/BreadCrumbs'
import { Button } from '@/components/Button'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { Input } from '@/components/Input'
import { useToast } from '@/hooks/useToast'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
// import MessageComposition from './MessageComposition'
import { cn } from '@/utils/helper'
import {
    BREADCRUMBS_PADDING_STYLE,
    FEATURE_RESTRICTIONS,
    MESSAGE_TYPE,
    MSG_TYPES,
} from '@/constants'
// import DiscardModal from './DiscardModal'
import { checkSubscriptionFeatureRestriction } from '@/api/subscription'
import FeatureRestrictionModal from '@/components/FeatureRestrictionModal'
import DiscardModal from './DiscardModal'
import MessageComposition from './MessageComposition'
import { createLeadMessage } from '@/api/lead'
import { selectedLeadsAtom } from '@/store/lead'
import useViewedAsUser from '@/hooks/useViewedAsUser'

type ResponseType = {
    message: string
}

type FeatureRestrictionProps = {
    businessId: string
    name: string
    msgType: string
}

const CreateEmail = () => {
    const { id } = useParams()
    const user = useViewedAsUser()
    const [messageType, setMessageType] = useState<string>(MESSAGE_TYPE.DRAFT)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const navigate = useNavigate()
    const [selected, setSelected] = useAtom(selectedLeadsAtom)
    const [restrictionModal, setRestrictionModal] = useState<boolean>(false)
    const { toast } = useToast()

    const { mutate: getMessage } = useMutation<MessageListType, AxiosError, string>({
        mutationKey: ['specificMessage'],
        mutationFn: () => getCustomerMessageByID(id ?? ''),
        onSuccess: (data: MessageListType) => {
            handleSuccess(data)
            // setSelected(data.recipients ?? [])
        },
    })

    const methods = useForm<CreateGroupAndMessagingType>({
        defaultValues: { status: 'sent', profileId: user.profileId },
        mode: 'onSubmit',
        resolver: zodResolver(createGroupAndMessagingSchema),
    })
    const [isDraft, setIsDraft] = useState<boolean>(false)
    const formValues = methods.watch()

    const { mutate: sendMessageMu } = useMutation<
        ResponseType,
        AxiosError,
        CreateGroupAndMessagingType
    >({
        mutationKey: ['SendMessage'],
        mutationFn: (data) => createLeadMessage(data),
        onSuccess: () => {
            const toastMessage = isDraft ? 'Draft saved' : 'Message sent successfully'
            toast({
                description: toastMessage,
                variant: 'default',
            })
            setSelected([])
            navigate('/customers/lead-management')
        },
    })

    const onSubmit = (data: CreateGroupAndMessagingType) => {
        if (isSubmitting) return

        setIsSubmitting(true)
        setIsDraft(false)

        setTimeout(() => {
            methods.setValue('profileId', user.profileId)
            sendMessageMu(data)
        }, 500)

        setTimeout(() => {
            setIsSubmitting(false)
        }, 1000)
    }

    const onSubmitDraft = () => {
        if (isSubmitting) return

        setIsSubmitting(true)
        setIsDraft(true)

        setTimeout(() => {
            methods.setValue('status', 'draft')
            sendMessageMu(methods.getValues())
        }, 500)

        setTimeout(() => {
            setIsSubmitting(false)
        }, 1000)
    }

    const handleSuccess = (data: MessageListType) => {
        methods.setValue('type', data.type)
        methods.setValue('emailBody', data.emailBody)
        methods.setValue('messageId', data.messageId)
        methods.setValue('title', data.title)
        methods.setValue('subject', data.subject)
        methods.setValue('smsBody', data.smsBody)
        setMessageType(data.type)
        // setSelected(data.recipients ?? [])
    }

    const isFormFilled = () => {
        const titleEmpty = formValues.title === '' || formValues.title === undefined
        const recipientsEmpty =
            formValues.recipients === undefined || formValues.recipients.length === 0

        const subjectEmpty = formValues.subject === '' || formValues.subject === undefined

        const emailBodyEmpty =
            formValues.emailBody === '' ||
            formValues.emailBody === undefined ||
            formValues.emailBody === '"<p></p>"'

        const smsBodyEmpty = formValues.smsBody === '' || formValues.smsBody === undefined

        const isDetailsEmpty = !titleEmpty && !recipientsEmpty

        if (messageType === MESSAGE_TYPE.EMAIL) {
            return isDetailsEmpty && !subjectEmpty && !emailBodyEmpty
        }
        if (messageType === MESSAGE_TYPE.SMS) {
            return isDetailsEmpty && !smsBodyEmpty
        }
        if (messageType === MESSAGE_TYPE.DRAFT) {
            return isDetailsEmpty && !subjectEmpty && !emailBodyEmpty
        }
        return isDetailsEmpty && !subjectEmpty && !smsBodyEmpty && !emailBodyEmpty
    }

    const handleDiscard = () => {
        setSelected([])
        setIsModalOpen(false)
        navigate('/customers/lead-management')
    }

    const { mutate: checkSubsRestrictionMu } = useMutation<
        boolean,
        AxiosError,
        FeatureRestrictionProps
    >({
        mutationFn: ({ businessId, name }) => checkSubscriptionFeatureRestriction(businessId, name),
        onSuccess: (res, variables) => {
            const { msgType } = variables

            setRestrictionModal(!res)

            if (res) {
                setMessageType(msgType)
            }
        },
    })

    const handleMessageTypeChange = (msgType: string) => {
        if (msgType === MSG_TYPES.sms || msgType === MSG_TYPES.both) {
            checkSubsRestrictionMu({
                businessId: user?.businessId,
                name: FEATURE_RESTRICTIONS.customerMessaging,
                msgType: msgType,
            })
        }
    }

    useEffect(() => {
        const initialType = messageType === 'draft' ? 'email' : messageType
        if (id) getMessage(id as string)
        methods.setValue('sendSchedule', 'now')
        methods.setValue('type', initialType)
    }, [messageType])

    useEffect(() => {
        if (selected && selected.length > 0) {
            const selectedIds = selected?.map((id) => {
                return id.leadId
            })
            methods.setValue('recipients', selectedIds)
            return
        }

        methods.setValue('recipients', [])
    }, [selected])

    return (
        <Form {...methods}>
            {/* <form> */}
            <div className={cn(BREADCRUMBS_PADDING_STYLE, 'flex flex-col gap-[36px]')}>
                <BreadCrumbs titleName='Create Message' />
                <div className='mt-10 pl-24 pr-8 py-24 bg-white rounded-md space-y-10'>
                    <div className='flex space-x-28 text-center items-center'>
                        <p>
                            Title<span className='text-red-500'>*</span>
                        </p>
                        <div className='flex-1'>
                            <FormField
                                control={methods.control}
                                name='title'
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input
                                                className='font-sans pl-3 w-full text-base h-11 border border-gray-20 rounded-sm'
                                                type='text'
                                                placeholder='Give your message a title'
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row space-x-28 text-center items-center'>
                        <p>Send</p>
                        <Button
                            type='button'
                            className='bg-gray-100 text-black hover:bg-gray-100 px-8 py-3'
                            onClick={() => methods.setValue('sendSchedule', 'now')}
                        >
                            Send Immediately
                        </Button>
                    </div>
                    <div className='flex flex-row space-x-28 text-center items-center'>
                        <p>Type</p>
                        <select
                            name='row'
                            value={messageType}
                            onChange={(val) => handleMessageTypeChange(val.target.value)}
                            className='bg-gray-100 pl-3 pr-24 py-1 border border-gray-200 rounded-md text-left'
                        >
                            <option disabled>Select</option>
                            <option role='text' value={MSG_TYPES.email}>
                                Email
                            </option>
                            {/* <option role='text' value={MSG_TYPES.sms}>
                                SMS
                            </option>
                            <option role='text' value={MSG_TYPES.both}>
                                Both
                            </option> */}
                        </select>
                    </div>
                    <hr />
                    <MessageComposition messageType={messageType} />
                    <div className='flex pt-5 justify-between'>
                        <Button
                            type='button'
                            onClick={() => setIsModalOpen(true)}
                            className='border border-green-800 text-green-800 bg-white hover:bg-white px-8 py-5'
                        >
                            Back
                        </Button>
                        <div className='space-x-3'>
                            <Button
                                type='button'
                                onClick={onSubmitDraft}
                                className={cn(
                                    !isFormFilled() ? 'cursor-not-allowed' : '',
                                    'border border-green-800 text-green-800 bg-white hover:bg-white px-8 py-5',
                                )}
                                disabled={!isFormFilled() || isSubmitting}
                            >
                                Save as Draft
                            </Button>
                            <Button
                                type='button'
                                onClick={methods.handleSubmit(onSubmit)}
                                className={cn(
                                    !isFormFilled() ? 'cursor-not-allowed' : '',
                                    'border bg-green-800 text-white hover:bg-green-800 px-8 py-5',
                                )}
                                disabled={!isFormFilled() || isSubmitting}
                                variant='default'
                            >
                                Send
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <DiscardModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                handleDiscard={handleDiscard}
            />
            <FeatureRestrictionModal open={restrictionModal} setOpen={setRestrictionModal} />
            {/* </form> */}
        </Form>
    )
}

export default CreateEmail
