import { ProfileBusinessType } from '@/api/business/schema'
import { getUnpaidInvoice } from '@/api/owner'
import { SubscriptionsInfoType } from '@/api/profile/schema'
import { SubscriptionType } from '@/api/subscription/schema'
import { Label } from '@/components/Label'
import { USER_STATUS, SUBSCRIPTION_TYPES, US_FORMAT } from '@/constants'
import { userAtom } from '@/store/auth'
import { numberFormatter, timestampToLocalDate } from '@/utils/helper'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useAtomValue } from 'jotai'
import { NavLink } from 'react-router-dom'
import dayjs from 'dayjs'
import { freeTrialAtom } from '@/store/owner'

interface UpdatePlanProps {
    businessProfileData: ProfileBusinessType
    plan: SubscriptionType
    recentSubscriptions: SubscriptionsInfoType[]
}

const UpdatePlan = ({ businessProfileData, plan, recentSubscriptions }: UpdatePlanProps) => {
    const { data: unpaidInvoice } = useQuery<string, AxiosError, string>({
        queryKey: ['unpaidInvoice'],
        queryFn: () => getUnpaidInvoice(businessProfileData?.profileId as string),
        enabled: !!businessProfileData?.profileId,
    })

    const user = useAtomValue(userAtom)
    const freeTrial = useAtomValue(freeTrialAtom)

    const latestPlan = recentSubscriptions?.[0]?.planName
    const latestPrice = recentSubscriptions?.[0]?.price

    return (
        <section>
            <Label className='text-zentive-gray-medium'>Account Overview</Label>
            <div className='w-full mb-[37px] ring-1 rounded-[4px] ring-[#EBEBEB]'>
                <div className='flex justify-between mt-[6.5px] pt-[16.5px]'>
                    <div className='flex-col justify-start pl-[16px]'>
                        <p className='text-base font-semibold'>Active Since</p>
                        <p className='text-zentive-gray-medium'>
                            {timestampToLocalDate(businessProfileData?.business?.createdAt || '--')}
                        </p>
                    </div>
                    <div className='flex-col justify-start'>
                        <p className='text-base font-semibold'>Account Status</p>
                        <p className='text-zentive-gray-medium'>
                            {
                                {
                                    ACT: 'Active',
                                    BP: 'Billing Paused',
                                    TER: 'Terminated',
                                    DEL: 'Deleted',
                                }[businessProfileData?.business?.status ?? USER_STATUS.INACTIVE]
                            }
                        </p>
                    </div>
                    <div className='flex-col fjustify-start pr-[70px]'>
                        <p className='text-base font-semibold'>Current Plan</p>
                        <p className='text-zentive-gray-medium'>
                            {user?.business?.subscriptionType?.name ===
                            SUBSCRIPTION_TYPES.FREE_TRIAL
                                ? `In ${latestPlan ?? user?.business?.subscriptionType?.name}`
                                : `${latestPlan ?? user?.business?.subscriptionType?.name} Plan`}
                        </p>
                        {plan?.interval !== 'days' ? (
                            <>
                                <p className='text-zentive-gray-medium'>
                                    {plan?.interval === 'month'
                                        ? 'Monthly Billing'
                                        : 'Annual Billing'}
                                </p>
                                <p className='text-zentive-gray-medium'>{`$${numberFormatter.format(
                                    latestPrice / 100 ?? 0,
                                )} per ${plan?.interval}`}</p>
                                <p className='text-zentive-gray-medium'>
                                    {businessProfileData?.status === USER_STATUS.ACTIVATED
                                        ? 'Automatic '
                                        : 'Cancelled '}
                                    renewal
                                </p>
                            </>
                        ) : (
                            <>
                                <p>Ends on {dayjs(freeTrial?.endOfTrial).format(US_FORMAT)}</p>
                            </>
                        )}

                        <div className='mt-[16px] mb-3'>
                            {businessProfileData?.business?.status !== USER_STATUS.TERMINATED ? (
                                <NavLink
                                    className='text-zentive-green-dark hover:cursor-pointer'
                                    onClick={() => {}}
                                    to={`/owner-subscription/change-plan`}
                                >
                                    Change Plan
                                </NavLink>
                            ) : (
                                <p className='text-zentive-green-dark hover:cursor-pointer'>
                                    Change Plan
                                </p>
                            )}
                        </div>

                        {(businessProfileData?.isAutoPay === undefined ||
                            !businessProfileData?.isAutoPay) &&
                        unpaidInvoice !== 'No Unpaid Invoice' ? (
                            <NavLink
                                className='text-zentive-green-dark hover:cursor-pointer'
                                onClick={() => {}}
                                to={`/owner-subscription/manual-payment?unpaidInvoice=${unpaidInvoice}`}
                            >
                                Pay Now
                            </NavLink>
                        ) : null}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default UpdatePlan
