import { CSVLink } from 'react-csv'
import dayjs from 'dayjs'
import { QuoteReportExportType, QuoteReportRecordType } from '@/api/reports/schema'
import { displayCurrency, formatPhoneNumber, formatRecordNumber } from '@/utils/helper'
import { RECORD_PREFIX, US_FORMAT } from '@/constants'
import { useAtomValue } from 'jotai'
import { quoteReportExportAtom } from '@/store/reports'
import { E164Number } from 'libphonenumber-js/core'

const ExportQuoteReportCSV = () => {
    const exportAtom = useAtomValue(quoteReportExportAtom)

    if (exportAtom === null || !exportAtom?.records?.length) return null

    const tableData: QuoteReportExportType | null =
        ((exportAtom &&
            exportAtom?.records &&
            exportAtom?.records.length > 0 && {
                records: exportAtom?.records.map((r) => ({
                    quoteNumber: r?.quoteNumber,
                    quoteId: r?.quoteId,
                    services: r?.services,
                    totalAmount: r?.totalAmount,
                    customerName: r?.customerName,
                    email: r?.email,
                    phoneNumber: r?.phoneNumber,
                    date: r?.date,
                    status: r?.status,
                    createdBy: r?.createdBy,
                })),
                acceptedCount: exportAtom?.acceptedCount,
                acceptedAmount: exportAtom?.acceptedAmount,
                deniedCount: exportAtom?.deniedCount,
                deniedAmount: exportAtom?.deniedAmount,
                pendingCount: exportAtom?.pendingCount,
                pendingAmount: exportAtom?.pendingAmount,
                totalRecords: exportAtom?.totalRecords,
                totalAmount: exportAtom?.totalAmount,
            }) as QuoteReportExportType) || null

    if (!tableData) return

    const csvHeaders = [
        'Quote Number',
        'Job Type',
        'Quote Price',
        'Customer Name',
        'Email Address',
        'Contact Number',
        'Date',
        'Status',
        'Processed By',
    ]

    const csvData =
    tableData?.records?.map((r: QuoteReportRecordType) => ({
        'Quote Number': RECORD_PREFIX.quote + formatRecordNumber(r.quoteNumber),
        'Job Type': r?.services?.join(','),
        'Quote Price': displayCurrency(r.totalAmount),
        'Customer Name': r.customerName,
        'Email Address': r.email,
        // Prepend a single quote to ensure Excel treats the phone number as text
        'Contact Number': r.phoneNumber ? `${
            r.phoneNumber.startsWith('+1')
                ? `(${r.phoneNumber.substring(0, 2)})${formatPhoneNumber(
                    r.phoneNumber as E164Number,
                ).substring(2)
                    .replace('0', '')
                    .replace(' ', '-')}`
            : `(${r.phoneNumber.substring(0, 3)})-${formatPhoneNumber(
                    r.phoneNumber as E164Number,
                )
                    .replace('0', '')
                    .replace(' ', '-')}`
        }` : 'N/A',
        Date: dayjs(r.date).format(US_FORMAT),
        Status: r.status as string,
        'Processed By': r.createdBy,
    })) || []



    csvData.push({
        'Quote Number': '',
        'Job Type': '',
        'Quote Price': '',
        'Customer Name': '',
        'Email Address': '',
        'Contact Number': '',
        Date: '',
        Status: '',
        'Processed By': '',
    })

    csvData.push({
        'Quote Number': 'Overview',
        'Job Type': '',
        'Quote Price': 'Accepted: ' + displayCurrency(exportAtom?.acceptedAmount),
        'Customer Name': '',
        'Email Address': 'Denied: ' + displayCurrency(exportAtom?.deniedAmount),
        'Contact Number': '',
        Date: 'Unanswered: ' + displayCurrency(exportAtom?.deniedAmount),
        Status: '',
        'Processed By': 'Total: ' + displayCurrency(exportAtom?.totalAmount),
    })

    return (
        <CSVLink
            data={csvData}
            headers={csvHeaders}
            filename={'quote-report.csv'}
            className={
                'w-[135px] h-[34px] text-zentive-black hover:bg-[#00000029] block px-5 py-1 text-base rounded-b-md'
            }
        >
            Export as CSV
        </CSVLink>
    )
}

export default ExportQuoteReportCSV
