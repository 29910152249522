import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from '@/components/Dialog'
import { QueryClient } from '@tanstack/react-query'
import { ComponentPropsWithoutRef, FC } from 'react'

const ExistingInvoiceModal: FC<
    ComponentPropsWithoutRef<typeof Dialog> & {
        queryClient: QueryClient
        quoteId: string
    }
> = ({ open, queryClient, quoteId }) => {
    const handleOnOpenChange = async (val: boolean) => {
        if (!val) {
            await queryClient.resetQueries({
                queryKey: ['checkExistingInvoice', quoteId],
                exact: true,
            })
        }
    }

    return (
        <Dialog open={open} onOpenChange={handleOnOpenChange}>
            <DialogContent withClose>
                <DialogHeader>
                    <DialogTitle>
                        An existing invoice has already been generated for this Job.{' '}
                    </DialogTitle>
                    <DialogDescription>
                        Please review or update the current invoice.
                    </DialogDescription>
                </DialogHeader>
            </DialogContent>
        </Dialog>
    )
}

export default ExistingInvoiceModal
