import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { jwtDecode } from 'jwt-decode'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import dayjs from 'dayjs'
import { E164Number } from 'libphonenumber-js/core'
import { EmployeeToCrewType } from '@/api/employee/schema'
import { ProductServiceGroupType } from '@/api/quoting/schema'
import { formatToUTCWithOffset } from './time'
import { INVOICE_STATUS, ISO_8601_WITH_UTC_OFFSET } from '@/constants'
import { SUBSCRIPTION_TYPES } from '@/constants'
// import utc from 'dayjs/plugin/utc'

// dayjs.extend(utc)

export const numberFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
})

export const formatPhoneNumber = (phoneNumber: E164Number) => {
    return formatPhoneNumberIntl(phoneNumber).replace(/ /g, '-')
}

export const cn = (...inputs: ClassValue[]) => {
    return twMerge(clsx(inputs))
}

export const convertImageToBase64 = (imagePath: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = imagePath
        img.onload = () => {
            const canvas = document.createElement('canvas')
            canvas.width = img.width
            canvas.height = img.height
            const ctx = canvas.getContext('2d')
            ctx?.drawImage(img, 0, 0, img.width, img.height)
            const base64String = canvas.toDataURL('image/png') // Change format if needed
            resolve(base64String)
        }
        img.onerror = (error) => {
            reject(error)
        }
    })
}

export const getExtension = (str: string) => str.slice(str.lastIndexOf('.'))

export const jwtDecoder = (jwtToken: string) => {
    const decodedJwt = jwtDecode(jwtToken)
    return decodedJwt
}

export const generatePassword = (length: number): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+'

    let password = ''
    for (let i = 0; i < length; i++) {
        password += characters.charAt(Math.floor(Math.random() * characters?.length))
    }

    return password
}

export const getFirstChar = (name: string | undefined | null): string =>
    name ? name[0].toUpperCase() : ''

export const hasMoreThanTwoDecimalPlaces = (value: number | string): boolean => {
    if (typeof value === 'number') {
        value = value.toString()
    }

    const decimalPart = value.split('.')[1]
    return decimalPart !== undefined && decimalPart?.length > 2
}

export const unixToLocalDate = (date: number, format?: string) => {
    if (!date) return ''
    return dayjs.unix(date).format(format || 'MMM DD, YYYY')
}

export const timestampToLocalDate = (timestamp: string, format?: string) => {
    if (!timestamp) return ''
    return dayjs(timestamp).format(format || 'MMM DD, YYYY')
}

export const formatRecordNumber = (num: number): string => {
    const limit = 4
    return num.toString().padStart(limit, '0')
}

export const formatFullName = (fName: string | undefined, lName: string | undefined) => {
    const firstName = fName ?? ''
    const lastName = lName ?? ''
    return firstName + ' ' + lastName
}

export const formatUSPhoneNumber = (employee: EmployeeToCrewType) => {
    const phoneNumber = employee.phoneNumber
    let formattedNumber

    if (phoneNumber.startsWith('+1')) {
        formattedNumber = `(${phoneNumber.substring(2, 5)})-${phoneNumber.substring(
            5,
            8,
        )}-${phoneNumber.substring(8, 12)}`
    } else {
        formattedNumber = `(${employee.phoneNumber.substring(0, 3)})-${formatPhoneNumber(
            employee.phoneNumber as E164Number,
        ).replace('+', '')}`
    }

    return formattedNumber
}

export const getQuoteServiceNames = (productServiceGroup?: ProductServiceGroupType[]) => {
    if (!productServiceGroup) return 'No product or service group'

    const serviceNames = productServiceGroup
        .filter((serv) => serv.type === 'service')
        ?.map((service) => service.name)

    return serviceNames?.length > 0 ? serviceNames.join(', ') : 'No services found'
}

export const displayCurrency = (
    amount: number,
    locales: string = 'en-US',
    currency: string = 'USD',
): string => {
    if (isNaN(amount)) {
        amount = 0
    }
    return amount.toLocaleString(locales, {
        style: 'currency',
        currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    })
}

export const countDecimals = (amount: number): number => {
    if (isNaN(amount)) return 0
    if (amount % 1 != 0)
        return amount.toString().split('.')[1] !== undefined
            ? amount.toString().split('.')[1].length
            : 0
    return 0
}

export const displayWithDecimals = (amount: number, decimalCount: number) => {
    if (isNaN(amount)) {
        amount = 0
    }
    return countDecimals(amount) === 1 ? amount : amount.toFixed(decimalCount)
}

export const toCurrency = (amount: number): string => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(amount)
}

export const isTimeSame = (dtrDate: string): boolean => {
    const currentDate = new Date()
    let currentDtrDate = dayjs(dtrDate).toDate()
    if (dtrDate.endsWith('Z')) {
        const cleanDTRDate = dtrDate.replace('Z', '+0800')
        currentDtrDate = dayjs(cleanDTRDate).toDate()
    }
    const formattedDate = formatToUTCWithOffset(
        currentDate.toISOString(),
        'Asia/Singapore',
        ISO_8601_WITH_UTC_OFFSET,
    )
    const finalDate = dayjs(formattedDate).toDate()
    const formatDtrDate = formatToUTCWithOffset(
        currentDtrDate.toISOString(),
        'Asia/Singapore',
        ISO_8601_WITH_UTC_OFFSET,
    )
    const finalDtrDate = dayjs(formatDtrDate).toDate()
    const hoursInput = finalDate.getHours()
    const minutesInput = finalDate.getMinutes()
    const hoursCurrent = finalDtrDate.getHours()
    const minutesCurrent = finalDtrDate.getMinutes()

    if (hoursInput == hoursCurrent && minutesInput == minutesCurrent) {
        return true
    } else {
        return false
    }
}

export const getPlanRank = (planName: string | undefined) => {
    switch (planName) {
        case SUBSCRIPTION_TYPES.GROWTH_PRO:
            return 3
        case SUBSCRIPTION_TYPES.STANDARD:
            return 2
        case SUBSCRIPTION_TYPES.STARTER:
            return 1
        default:
            return 0
    }
}

export const getInvoiceStatus = (status: string) => {
    switch (status) {
        case INVOICE_STATUS.OPEN:
            return 'Awaiting Payment'
        case INVOICE_STATUS.PAID:
            return 'Paid'
        case INVOICE_STATUS.PAID_OUT_OF_BAND:
            return 'Paid Externally'
        case INVOICE_STATUS.PAST_DUE:
            return 'Past Due';
        default:
            return 'Awaiting Payment'
    }
}