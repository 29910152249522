import { CSVLink } from 'react-csv'
import dayjs from 'dayjs'
import { NetGrossExportType, NetGrossRecordType } from '@/api/reports/schema'
import { displayCurrency, formatRecordNumber } from '@/utils/helper'
import { RECORD_PREFIX, US_FORMAT } from '@/constants'
import { useAtomValue } from 'jotai'
import { netGrossExportAtom } from '@/store/reports'

const ExportGrossIncomeCSV = () => {
    const exportAtom = useAtomValue(netGrossExportAtom)

    // const selectedTab = useAtomValue(expenseReportTabsAtom)

    if (exportAtom === null || !exportAtom?.records?.length) return null

    const tableData: NetGrossExportType | null =
        ((exportAtom &&
            exportAtom?.records &&
            exportAtom?.records.length > 0 && {
                records: exportAtom?.records.map((r) => ({
                    jobNumber: r.jobNumber,
                    services: r.services ?? [],
                    customerName: r.customerName,
                    date: r.date,
                    grossIncome: r.grossIncome,
                    netIncome: r.netIncome,
                    externalExpenses: r.externalExpenses,
                    internalExpenses: r.internalExpenses,
                    paidVia: r.paidVia,
                    invoiceNumber: r.invoiceNumber,
                    quoteNumber: r.quoteNumber,
                    invoiceId: r.invoiceId,
                    quoteId: r.quoteId,
                })),
                totalGrossIncome: exportAtom?.totalGrossIncome,
                totalExternalExpense: exportAtom?.totalExternalExpense,
                totalInternalExpense: exportAtom?.totalInternalExpense,
                totalNetIncome: exportAtom?.totalNetIncome,
            }) as NetGrossExportType) || null

    if (!tableData) return

    const csvHeaders = [
        'Job Number',
        'Job Type',
        'Customer Name',
        'Date',
        'Gross Income',
        'Paid Via',
        'Invoice Number',
        'Quote Number',
    ]

    const csvData =
        tableData?.records?.map((r: NetGrossRecordType) => ({
            'Job Number': RECORD_PREFIX.job + formatRecordNumber(r.jobNumber ?? 0),
            'Job Type': r.services.join(','),
            'Customer Name': r.customerName,
            Date: dayjs(r.date).format(US_FORMAT),
            'Gross Income': displayCurrency(r.grossIncome),
            'Paid Via': r.paidVia,
            'Invoice Number': RECORD_PREFIX.invoice + formatRecordNumber(r.invoiceNumber),
            'Quote Number': RECORD_PREFIX.quote + formatRecordNumber(r.quoteNumber),
        })) || []

    csvData.push({
        'Job Number': 'TOTAL',
        'Job Type': '',
        'Customer Name': '',
        Date: '',
        'Gross Income': displayCurrency(exportAtom?.totalGrossIncome),
        'Paid Via': '',
        'Invoice Number': '',
        'Quote Number': '',
    })

    return (
        <CSVLink
            data={csvData}
            headers={csvHeaders}
            filename={'gross-income-report.csv'}
            className={
                'w-[135px] h-[34px] text-zentive-black hover:bg-[#00000029] block px-5 py-1 text-base rounded-b-md'
            }
        >
            Export as CSV
        </CSVLink>
    )
}

export default ExportGrossIncomeCSV
