import { Button } from '@/components/Button'
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogOverlay,
    DialogPortal,
    DialogTitle,
    DialogTrigger,
} from '@/components/Dialog'
import { BsFunnelFill } from 'react-icons/bs'
import { Checkbox } from '@/components/Checkbox'
import { getRequestServices } from '@/api/service'
import { AxiosError } from 'axios'
import { RequestServiceType } from '@/api/service/schema'
import { useQuery } from '@tanstack/react-query'
import { useAtomValue, useSetAtom } from 'jotai'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { serviceProductFilter, serviceProductApprovedFilter } from '@/store/customer'
import { FullProfileType } from '@/api/profile/schema'
import { QUOTE_STATUS } from '@/constants'
import { useEffect, useState } from 'react'

const QuoteFilterDialog = () => {
    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom) as FullProfileType
    const setServiceProduct = useSetAtom(serviceProductFilter)
    const setServiceProductApproved = useSetAtom(serviceProductApprovedFilter)
    const [filteredProperties, setFilteredProperties] = useState<string[]>([])
    const [filterApproved, setFilterApproved] = useState<string[]>([])

    const { data: services } = useQuery<unknown, AxiosError, RequestServiceType>({
        queryKey: ['RequestServiceType'],
        queryFn: () => getRequestServices(user?.businessId as string),
    })

    const checkItems = (id: string) => {
        setFilteredProperties((prev) =>
            prev.includes(id) ? prev.filter((property) => property !== id) : [...prev, id],
        )
    }

    const checkApprovedFilter = (id: string) => {
        setFilterApproved((prev) =>
            prev.includes(id) ? prev.filter((property) => property !== id) : [...prev, id],
        )
    }

    const submitFilter = () => {
        const dataFilter = filteredProperties.join(',')
        const dataApproved = filterApproved.join('-')
        setServiceProduct(dataFilter)
        setServiceProductApproved(dataApproved)
    }

    const clearFilter = () => {
        setServiceProduct('')
        setServiceProductApproved('')
        setFilteredProperties([])
        setFilterApproved([])
    }

    useEffect(() => {
        clearFilter()
    }, [])

    return (
        <Dialog>
            <DialogTrigger asChild>
                <BsFunnelFill className='size-[25px] my-2 mr-5' />
            </DialogTrigger>
            <DialogPortal>
                <DialogOverlay>
                    <DialogContent className='w-1/4 max-h-[650px] h-300 rounded-md'>
                        <DialogHeader>
                            <DialogTitle className='mb-2'>
                                <div className='text-2xl text-left mb-6'>Filter</div>
                                <div className='text-left font-normal  text-base mb-4'>
                                    <span className='text-zentive-gray-medium font-semibold'>
                                        {' '}
                                        Status{' '}
                                    </span>
                                    {/*  make it horizontal */}
                                    <div className='flex flex-row space-x-3 mt-5'>
                                        <div>
                                            <Checkbox
                                                id='active'
                                                checked={filterApproved.includes(
                                                    QUOTE_STATUS.APPROVED,
                                                )}
                                                onCheckedChange={() =>
                                                    checkApprovedFilter(QUOTE_STATUS.APPROVED)
                                                }
                                            />
                                        </div>
                                        <div className='capitalize'> {QUOTE_STATUS.APPROVED} </div>
                                        <div>
                                            <Checkbox
                                                id='inactive'
                                                checked={filterApproved.includes(
                                                    QUOTE_STATUS.PENDING,
                                                )}
                                                onClick={() =>
                                                    checkApprovedFilter(QUOTE_STATUS.PENDING)
                                                }
                                            />
                                        </div>
                                        <div className='capitalize'> Not Approved </div>
                                    </div>
                                </div>
                            </DialogTitle>

                            <DialogDescription className='max-h-[350px] overflow-y-auto scrollbar border-white'>
                                <div className='text-left font-semibold text-zentive-gray-medium text-base mb-4'>
                                    Service Type
                                </div>

                                {services?.services?.map((data) => (
                                    <div>
                                        <div className='flex flex-row mb-4 text-zentive-black text-base'>
                                            <div className='flex justify-start space-x-2'>
                                                <div key={data.name}>
                                                    <Checkbox
                                                        id={'business-name'}
                                                        checked={filteredProperties.includes(
                                                            data.productServiceId as string,
                                                        )}
                                                        onCheckedChange={() =>
                                                            checkItems(
                                                                data.productServiceId as string,
                                                            )
                                                        }
                                                    ></Checkbox>
                                                </div>
                                                <div>{data.name}</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </DialogDescription>
                        </DialogHeader>
                        <DialogFooter className='w-full h-[50px] rounded-b-md flex flex-row'>
                            <div className='flex items-end'>
                                <DialogClose asChild>
                                    <Button
                                        type='button'
                                        variant={'link'}
                                        className='text-zentive-blue-dark font-semibold text-base'
                                        onClick={clearFilter}
                                    >
                                        Clear Filters
                                    </Button>
                                </DialogClose>
                            </div>
                            <div className='w-full flex flex-row justify-end'>
                                <div className='flex flex-row justify-between'>
                                    <DialogClose asChild>
                                        <Button
                                            type='button'
                                            variant={'outline'}
                                            className='w-full mr-4'
                                        >
                                            Cancel
                                        </Button>
                                    </DialogClose>
                                    <DialogClose asChild>
                                        <Button
                                            type='submit'
                                            onClick={submitFilter}
                                            className='w-full'
                                        >
                                            Submit
                                        </Button>
                                    </DialogClose>
                                </div>
                            </div>
                        </DialogFooter>
                    </DialogContent>
                </DialogOverlay>
            </DialogPortal>
        </Dialog>
    )
}

export default QuoteFilterDialog
