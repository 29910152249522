import {
    ResetPasswordType,
    UpdatePasswordType,
    resetPasswordSchema,
    updatePasswordSchema,
} from '@/api/auth/schema'
import goTrueClient from '@/api/goTrueClient'
import { getFullProfile, updatePasswordStatus } from '@/api/profile'
import { authEventAtom, businessIdAtom, sessionAtom, timezoneAtom, userAtom } from '@/store/auth'
import { extractUserReponse, getSession } from '@/utils/user'
import { zodResolver } from '@hookform/resolvers/zod'
import { AuthError } from '@supabase/gotrue-js'
import { useMutation } from '@tanstack/react-query'
import { useSetAtom } from 'jotai'
import { useForm } from 'react-hook-form'
import { useToast } from './useToast'
import { useAppNavigate } from './useAppNavigate'
import { signIn, signOut } from '@/api/auth'
import { INVALID_CURRENT_PASSWORD, NO_OLD_PASSWORD, ROLE, USER_STATUS } from '@/constants'
import { getItem, removeItem } from '@/utils/storage'
import { paymentStepAtom } from '@/store/payment'
import { useLocation, useNavigate } from 'react-router-dom'
import { RESET } from 'jotai/utils'
import { fetchTimezone } from './useGeocode'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'
import { hasActiveSubscription } from '@/api/subscription'

type StatusToSignOut = 'TER' | 'DEL'

const statusVerbiage = {
    BP: 'Billing Paused',
    TER: 'Terminated',
    DEL: 'Deleted',
}

const hasCurrentPassword = (
    formData: UpdatePasswordType | ResetPasswordType,
): formData is UpdatePasswordType => {
    return 'currentPassword' in formData
}

export const useUpdateProfile = () => {
    const { navigateTo } = useAppNavigate()
    const navigate = useNavigate()
    const { t } = useTranslation(['common'])
    const { toast } = useToast()
    const { pathname } = useLocation()
    const setBusinessId = useSetAtom(businessIdAtom)
    const setPaymentStep = useSetAtom(paymentStepAtom)
    const setUser = useSetAtom(userAtom)
    const setEvent = useSetAtom(authEventAtom)
    const setSession = useSetAtom(sessionAtom)
    const setTimezone = useSetAtom(timezoneAtom)

    const resetPasswordForm = useForm<ResetPasswordType>({
        mode: 'onChange',
        resolver: zodResolver(resetPasswordSchema),
    })

    const updatePasswordForm = useForm<UpdatePasswordType>({
        mode: 'onChange',
        resolver: zodResolver(updatePasswordSchema),
    })

    const { mutate: updatePasswordStatusMu } = useMutation<boolean, AxiosError, { email: string }>({
        mutationFn: async ({ email }) => {
            // Assuming updatePasswordStatus is a function that returns a boolean or a Promise<boolean>
            return await updatePasswordStatus(email)
        },
        onError: (err) => console.error(err),
        onSuccess: () => {
            const session = getSession()
            if (session?.user?.user_metadata?.role !== ROLE.CREW) {
                toast({
                    description: t('Password successfully updated'),
                    variant: 'default',
                })
            } else {
                navigate('/success')
            }

            if (pathname === '/reset-password') {
                setSession(null)
            }
        },
    })

    const { mutate: updatePasswordMu, isPending: isPassPending } = useMutation<
        boolean,
        AxiosError,
        { email?: string; newPassword: string; hasCurrentPassword: boolean }
    >({
        mutationFn: async ({ newPassword, hasCurrentPassword }) => {
            await goTrueClient.updateUser({ password: newPassword })
            if (hasCurrentPassword) {
                updatePasswordForm.reset()
            } else {
                resetPasswordForm.reset()
            }
            return true // Ensure the mutation returns a boolean as expected
        },
        onError: (err) => console.error(err),
        onSuccess: (_, variables) => {
            const { email } = variables
            updatePasswordStatusMu({ email: email as string })
        },
    })

    const destructiveToast = (description: string) => {
        toast({
            description,
            variant: 'destructive',
        })
    }

    const updateUserState = async () => {
        try {
            const getProfileRes = await getFullProfile()
            const hasActiveSubscriptionRes = await hasActiveSubscription(getProfileRes?.customerId)

            // Early return for statuses leading to sign out with a toast message
            const signOutStatuses = [USER_STATUS.TERMINATED, USER_STATUS.DELETED]
            if (
                signOutStatuses.includes(getProfileRes?.status as StatusToSignOut) ||
                (getProfileRes?.status === USER_STATUS.BILLING_PAUSED && !hasActiveSubscriptionRes)
            ) {
                toast({
                    description: `Account ${
                        statusVerbiage[getProfileRes.status as StatusToSignOut]
                    }. Unable to Sign in.`,
                })
                signOut()
                return
            }

            // Handle unpaid status
            if (getProfileRes?.status === USER_STATUS.UNPAID) {
                setPaymentStep(1)
                return navigateTo(
                    getProfileRes?.business?.subscriptionType?.name === 'Free Trial'
                        ? '/free-trial'
                        : '/payment',
                )
            }

            // if (getProfileRes?.status === USER_STATUS.BILLING_PAUSED && hasActiveSubscriptionRes) {
            //     return navigateTo('/dashboard')
            // }

            // Activation without Stripe connection for non-customers, or restricted Stripe account for owners
            const isActiveOwner =
                getProfileRes?.status === USER_STATUS.ACTIVATED &&
                (getProfileRes.role.roleName === ROLE.OWNER ||
                    getProfileRes?.role.roleName === ROLE.CO_OWNER)

            if (
                isActiveOwner &&
                !getProfileRes?.isStripeSetupLater &&
                !getProfileRes?.stripeConnectedAccId
            ) {
                if (getProfileRes?.role.roleName === ROLE.OWNER) {
                    const navigateToStep2 = () => {
                        setPaymentStep(2)
                        navigateTo('/payment')
                    }
                    return navigateToStep2()
                } else {
                    if (getProfileRes.isPasswordGenerated) {
                        navigateTo('/reset-password')
                    } else {
                        navigateTo('/dashboard')
                    }
                }
            }

            if (
                isActiveOwner &&
                (getProfileRes?.isStripeSetupLater || getProfileRes?.stripeConnectedAccId)
            ) {
                setBusinessId(getProfileRes?.businessId)
            }

            setUser(extractUserReponse(getProfileRes))

            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        const { latitude, longitude } = position.coords
                        const timezone = await fetchTimezone({ lat: latitude, lng: longitude })
                        setTimezone(timezone)
                    },
                    async () => {
                        const timezone = await fetchTimezone({
                            lat: getProfileRes.address.gmapLat as number,
                            lng: getProfileRes.address.gmapLng as number,
                        })

                        setTimezone(timezone)
                    },
                )
            }
        } catch (err: any) {
            const errorMessage = err.message
            if (errorMessage === 'Account is Deleted') {
                toast({
                    description: `Account Deleted. Unable to Sign in.`,
                })
                signOut()
                return
            } else {
                console.error('Error updating user state:', err)
                throw err
            }
        }
    }

    const updatePasswordWithCurrent = async (formData: UpdatePasswordType) => {
        try {
            const { currentPassword, newPassword } = formData
            const session = getSession()

            const { data, error } = await signIn({
                email: session?.user?.email as string,
                password: currentPassword,
            })

            // error from invalid login credentials
            if (error) {
                destructiveToast(INVALID_CURRENT_PASSWORD)
                return
            }

            // use boolean to prevent users from updating their passwords
            const doPasswordsMatch = currentPassword === newPassword

            // if user inputs correct current password
            // yet current password is still used as new password
            if (doPasswordsMatch && data.session && data.user) {
                destructiveToast(NO_OLD_PASSWORD)
                return
            }

            updatePasswordMu({
                newPassword,
                email: session?.user?.email as string,
                hasCurrentPassword: hasCurrentPassword(formData),
            })
        } catch (err) {
            if (err instanceof AuthError) {
                console.error('Authentication error: ', err.message)
            }
        }
    }

    const updatePasswordWithoutCurrent = async (formData: ResetPasswordType) => {
        try {
            const { newPassword } = formData
            const session = getSession()

            // signout if not creds dont match
            // clears token from local storage
            const { data, error } = await signIn({
                email: session?.user?.email as string,
                password: newPassword,
            })

            if (error) {
                console.error('error ', error.message)
                const refreshToken = getItem<string>('refresh_token') as string

                await goTrueClient.refreshSession({
                    refresh_token: refreshToken,
                })
            }

            // if user managed to login using the new password
            // it means the user used the old password
            if (data.session && data.user && error === null) {
                destructiveToast(NO_OLD_PASSWORD)
                return
            }

            updatePasswordMu({
                email: session?.user?.email as string,
                newPassword,
                hasCurrentPassword: hasCurrentPassword(formData),
            })

            removeItem('refresh_token')
            setEvent(RESET)
            // setSession(null)
        } catch (err) {
            if (err instanceof AuthError) {
                console.error('Authentication error: ', err.message)
            }
        }
    }

    const validatePasswordChange = (formData: ResetPasswordType | UpdatePasswordType) => {
        if (hasCurrentPassword(formData)) {
            return updatePasswordWithCurrent(formData)
        }

        return updatePasswordWithoutCurrent(formData)
    }

    const isPasswordChangePending = isPassPending

    return {
        isPasswordChangePending,
        updateUserState,
        // updateProfileForm,
        resetPasswordForm,
        updatePasswordForm,
        // updatePasswordMutator,
        // updateProfileMutator,
        validatePasswordChange,
    }
}
