import { useAtom, useAtomValue } from 'jotai'
import SearchBar from '@/components/InputSearchBox'
import { invoiceReportFilterAtom } from '@/store/reports'
import { ExportButton } from './ExportButton'
import dayjs from 'dayjs'
import { ISO_8601, ISO_8601_WITH_UTC_OFFSET } from '@/constants'
import { SelectSingleEventHandler } from 'react-day-picker'
import { OneJobDatePicker } from '@/components/OneOffJobDatePicker'
import { useEffect, useState } from 'react'
import { useToast } from '@/hooks/useToast'
import { CustomerFilter } from './CustomerFilter'
import { InvoiceStatusFilter } from './InvoiceStatusFilter'
import { timezoneAtom } from '@/store/auth'
import { formatToUTCWithOffset } from '@/utils/time'

export const InvoiceReportFilter = () => {
    const [invoiceReportFilter, setInvoiceReportFilter] = useAtom(invoiceReportFilterAtom)

    const tz = useAtomValue(timezoneAtom)

    const [start, setStart] = useState<string>('')
    const [end, setEnd] = useState<string>('')

    const { toast } = useToast()

    const setStartDateTime = (date: Date | undefined) => {
        // Extract the Month, Day, and Year from the date in ISO 8601 format
        const newValueDate = dayjs(date).format(ISO_8601)
        const formattedStart = formatToUTCWithOffset(
            newValueDate,
            tz?.timeZoneId as string,
            ISO_8601_WITH_UTC_OFFSET,
        )

        const present = dayjs().format(ISO_8601)
        const formattedPresent = dayjs(present).format(ISO_8601_WITH_UTC_OFFSET)

        if (dayjs(formattedStart).isAfter(present)) {
            toast({
                description: 'Start date cannot be a future date',
                variant: 'destructive',
                duration: 2000,
            })

            setTimeout(() => {
                setStart(formattedPresent)
            }, 1000)

            return
        }

        setStart(formattedStart)

        setInvoiceReportFilter((prev) => {
            if (!prev) {
                return {
                    profileIds: invoiceReportFilter?.profileIds || [],
                    start: formattedStart || '',
                    end: end || '',
                    keyword: invoiceReportFilter?.keyword || '',
                    status: invoiceReportFilter?.status || [],
                }
            }

            return {
                ...prev,
                start: formattedStart,
            }
        })

        return formattedStart
    }

    const setStartDate: SelectSingleEventHandler = (date: Date | undefined) =>
        setStartDateTime(date)

    const setEndDateTime = (date: Date | undefined) => {
        // Extract the Month, Day, and Year from the date in ISO 8601 format
        const newValueDate = dayjs(date).format(ISO_8601)
        const formattedEnd = formatToUTCWithOffset(
            newValueDate,
            tz?.timeZoneId as string,
            ISO_8601_WITH_UTC_OFFSET,
        )

        setEnd(formattedEnd)

        setInvoiceReportFilter((prev) => {
            if (!prev) {
                return {
                    profileIds: invoiceReportFilter?.profileIds || [],
                    start: start || '',
                    end: formattedEnd || '',
                    keyword: invoiceReportFilter?.keyword || '',
                    status: invoiceReportFilter?.status || [],
                }
            }

            return {
                ...prev,
                end: formattedEnd,
            }
        })

        return formattedEnd
    }

    const setEndDate: SelectSingleEventHandler = (date: Date | undefined) => setEndDateTime(date)

    useEffect(() => {
        if (dayjs(start).isAfter(dayjs(end))) {
            toast({
                description: 'Start date cannot be greater than the end date',
                variant: 'destructive',
                duration: 2000,
            })

            setTimeout(() => {
                setStart('')
                setEnd('')
            }, 500)

            setInvoiceReportFilter((prev) => {
                if (!prev) {
                    return {
                        profileIds: invoiceReportFilter?.profileIds || [],
                        start: '',
                        end: '',
                        keyword: invoiceReportFilter?.keyword || '',
                        status: invoiceReportFilter?.status || [],
                    }
                }

                return {
                    ...prev,
                    end: '',
                    start: '',
                }
            })
        }
    }, [start, end])

    return (
        <div className='flex flex-row justify-between p-5 bg-white border border-zentive-gray-medium/50 border-1'>
            <div className='flex items-center'>
                <SearchBar
                    onSearchChange={(e) => {
                        setInvoiceReportFilter({
                            profileIds: invoiceReportFilter?.profileIds || [],
                            start: start || '',
                            end: end || '',
                            keyword: e.target.value,
                            status: invoiceReportFilter?.status || [],
                        })
                    }}
                    placeHolder='Search for...'
                />
            </div>
            <div className='flex flex-row gap-5'>
                <div className='flex flex-col w-[10rem]'>
                    <OneJobDatePicker
                        setJobDate={setStartDate}
                        jobDate={start}
                        label='Start Date'
                        showOutsideDates={true}
                    />
                </div>
                <div className='flex flex-col w-[10rem]'>
                    <OneJobDatePicker
                        setJobDate={setEndDate}
                        jobDate={end}
                        label='End Date'
                        showOutsideDates={true}
                    />
                </div>
                <CustomerFilter />
                <InvoiceStatusFilter />
                <ExportButton />
            </div>
        </div>
    )
}
