import { convertImageToBase64 } from '@/utils/helper'
import zentiveLogo from '@/assets/common/Zentive_Horizontal_Logo@3x.png'
import { useAtomValue } from 'jotai'
import { quoteReportExportAtom } from '@/store/reports'
import { QuoteReportExportType } from '@/api/reports/schema'
import { isViewedAsAtom, userAtom, viewedAsUserAtom } from '@/store/auth'
import { GenerateQuoteReportPDF } from './GenerateExportReportPDF'

const ExportQuoteReportPDF = () => {
    const exportAtom = useAtomValue(quoteReportExportAtom)

    const isViewedAsOwner = useAtomValue(isViewedAsAtom)
    const user = useAtomValue(isViewedAsOwner ? viewedAsUserAtom : userAtom)

    if (exportAtom === null || !exportAtom?.records?.length) return null

    const handleExportToPDF = async () => {
        convertImageToBase64(zentiveLogo)
            .then((zentiveLogoUrl) => {
                const tableData: QuoteReportExportType = {
                    records:
                        exportAtom?.records?.map((r) => ({
                            quoteNumber: r.quoteNumber,
                            quoteId: r.quoteId,
                            services: r.services,
                            totalAmount: r.totalAmount,
                            customerName: r.customerName,
                            email: r.email,
                            phoneNumber: r.phoneNumber,
                            date: r.date,
                            status: r.status,
                            createdBy: r.createdBy,
                        })) ?? [],
                    acceptedCount: exportAtom.acceptedCount,
                    acceptedAmount: exportAtom.acceptedAmount,
                    deniedCount: exportAtom.deniedCount,
                    deniedAmount: exportAtom.deniedAmount,
                    pendingCount: exportAtom.pendingCount,
                    pendingAmount: exportAtom.pendingAmount,
                    totalRecords: exportAtom.totalRecords,
                    totalAmount: exportAtom.totalAmount,
                } as QuoteReportExportType

                GenerateQuoteReportPDF(
                    zentiveLogoUrl,
                    tableData,
                    user?.business?.businessName ?? 'Zentive',
                    "Quote"
                )
            })
            .catch((err) => console.error('Error converting image to Base64:', err.message))
    }

    return (
        <button
            className='w-[135px] h-[34px] text-gray-800 hover:bg-[#00000029] block px-4 py-2 text-base rounded-t-md'
            onClick={handleExportToPDF}
        >
            Export as PDF
        </button>
    )
}
export default ExportQuoteReportPDF
