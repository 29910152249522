import { CardHeader, CardTitle } from '@/components/Card'
import ClientDialog from './ClientModal'
import { useAtomValue } from 'jotai'
import { customerInvoiceAtom } from '@/store/owner'
import HeaderClientDetails from './ClientDetails'
import { jobGenerateInvoiceAtom } from '@/store/job'
import { InvoiceType } from '@/api/invoicing/schema'
import { useFormContext } from 'react-hook-form'
import { FormControl, FormField, FormItem, FormMessage } from '@/components/Forms'
import { Textarea } from '@/components/TextArea'

interface IAddInvoiceHeader {
    invoiceNumber: number
}
const AddInvoiceHeader = ({ invoiceNumber }: IAddInvoiceHeader) => {
    const customer = useAtomValue(customerInvoiceAtom)
    const generateInvoiceData = useAtomValue(jobGenerateInvoiceAtom)
    const { control } = useFormContext<InvoiceType>()


        return (
            <CardHeader className="border-b-8 border-zentive-gray-light">
            <div className="flex flex-col lg:flex-row lg:justify-between lg:items-start gap-2 lg:gap-16">
                {/* Invoice Title and Client Details */}
                <div className="flex flex-col gap-y-4">
                <CardTitle className="text-2xl font-bold">Invoice for</CardTitle>
                <div className="flex flex-row space-x-2 lg:space-x-10 mx-2 lg:mx-5">
                    {customer == null && generateInvoiceData == null ? (
                    <ClientDialog isEditing={false} />
                    ) : (
                    <HeaderClientDetails />
                    )}
                </div>
                </div>
        
                {/* Invoice Number and Notes */}
                <div className="flex flex-col w-full lg:w-auto mt-5">
                <div className="flex flex-col lg:flex-row text-2xl font-semibold space-y-2 lg:space-y-0 lg:space-x-2">
                    <p>Invoice Number:</p>
                    <p className="border-2 rounded-md pr-20 py-0">00{invoiceNumber}</p>
                </div>
                <p className="font-semibold mt-10">Shared Notes:</p>
                <FormField
                    control={control}
                    name="sharedNotes"
                    render={({ field }) => (
                    <FormItem>
                        <FormControl>
                        <Textarea
                            className="border p-4 h-24 w-full xl:w-[600px] lg:w-[350px] max-w-[500px] border-zentive-gray-light rounded-md text-zentive-black text-base focus-visible:ring-0 focus-visible:ring-offset-0 bg-white placeholder-transparent"
                            placeholder="Please add a note"
                            {...field}
                        />
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                    )}
                />
                </div>
            </div>
            </CardHeader>
        );
        
}

export default AddInvoiceHeader
