import { getDealStagesByBusinessId } from '@/api/deal_stage'
import { DealStage } from '@/api/deal_stage/schema'
import { SingleProductService } from '@/api/quoting/schema'
import { getProductsAndServices } from '@/api/service'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { CSVLink } from 'react-csv'

const LeadCSVTemplate = () => {
    const currentUser = useViewedAsUser()
    const { businessId = '' } = currentUser

    const { data: dealStages, isSuccess } = useQuery({
        enabled: !!businessId,
        queryKey: ['dealStages', businessId],
        queryFn: () => getDealStagesByBusinessId(businessId),
    })

    const { data: items, isSuccess: isProductsAndServiecsSuccess } = useQuery<
        SingleProductService[],
        AxiosError
    >({
        enabled: !!businessId,
        queryKey: ['productsAndServices', businessId],
        queryFn: () => getProductsAndServices(businessId),
    })

    let csvHeaders: string[] = []
    csvHeaders = [
        'First Name',
        'Last Name',
        'Email Address',
        'Contact Number',
        'Business Name',
        'Street 1',
        'Street 2',
        'City',
        'State',
        'Zip Code',
        'Deal Size',
        'Deal Stage ID',
        'Contact Type',
        'Source',
        'Status',
        'Product Service Type IDs',
    ]

    const csvInputGuide = [
        {
            'First Name': 'John',
            'Last Name': 'Doe',
            'Email Address': 'johndoe@gmail.com',
            'Contact Number': '122518836',
            'Business Name': 'Business One',
            'Street 1': 'Street Parking',
            'Street 2': '',
            City: 'Los Angeles',
            State: 'California',
            'Zip Code': '90027',
            'Deal Size': '500',
            'Deal Stage ID': dealStages?.[0]?.label as string,
            'Contact Type': 'Company',
            Source: 'Facebook',
            Status: 'subscribed',
            'Product Service Type IDs': '',
        },
    ]

    csvInputGuide.push({
        'First Name': '',
        'Last Name': '',
        'Email Address': '',
        'Contact Number': '',
        'Business Name': '',
        'Street 1': '',
        'Street 2': '',
        City: '',
        State: '',
        'Zip Code': '',
        'Deal Size': '',
        'Deal Stage ID': '',
        'Contact Type': '',
        Source: '',
        Status: '',
        'Product Service Type IDs': '',
    })

    csvInputGuide.push({
        'First Name': 'Input guide for Deal Stage ID and Product Service Type IDs',
        'Last Name': 'Delete everything from input guide when saving',
        'Email Address': '',
        'Contact Number': '',
        'Business Name': '',
        'Street 1': '',
        'Street 2': '',
        City: '',
        State: '',
        'Zip Code': '',
        'Deal Size': '',
        'Deal Stage ID': '',
        'Contact Type': '',
        Source: '',
        Status: '',
        'Product Service Type IDs': '',
    })

    csvInputGuide.push({
        'First Name': 'Copy and paste the chosen id below for Deal Stage ID column',
        'Last Name': 'Deal Stage Name',
        'Email Address': '',
        'Contact Number': '',
        'Business Name': '',
        'Street 1': '',
        'Street 2': '',
        City: '',
        State: '',
        'Zip Code': '',
        'Deal Size': '',
        'Deal Stage ID': '',
        'Contact Type': '',
        Source: '',
        Status: '',
        'Product Service Type IDs': '',
    })

    if (isSuccess && dealStages?.length > 0) {
        dealStages.forEach((stage: DealStage) => {
            csvInputGuide.push({
                'First Name': stage.dealStageId,
                'Last Name': stage.label,
                'Email Address': '',
                'Contact Number': '',
                'Business Name': '',
                'Street 1': '',
                'Street 2': '',
                City: '',
                State: '',
                'Zip Code': '',
                'Deal Size': '',
                'Deal Stage ID': '',
                'Contact Type': '',
                Source: '',
                Status: '',
                'Product Service Type IDs': '',
            })
        })
    }

    csvInputGuide.push({
        'First Name': '',
        'Last Name': '',
        'Email Address': '',
        'Contact Number': '',
        'Business Name': '',
        'Street 1': '',
        'Street 2': '',
        City: '',
        State: '',
        'Zip Code': '',
        'Deal Size': '',
        'Deal Stage ID': '',
        'Contact Type': '',
        Source: '',
        Status: '',
        'Product Service Type IDs': '',
    })

    csvInputGuide.push({
        'First Name': '',
        'Last Name': '',
        'Email Address': '',
        'Contact Number': '',
        'Business Name': '',
        'Street 1': '',
        'Street 2': '',
        City: '',
        State: '',
        'Zip Code': '',
        'Deal Size': '',
        'Deal Stage ID': '',
        'Contact Type': '',
        Source: '',
        Status: '',
        'Product Service Type IDs': '',
    })

    csvInputGuide.push({
        'First Name':
            'Copy and paste the chosen ids below for Product Service Type IDs column. Example: eda92704-7246-41e1-9962-62be17195433, 13f71dc3-3dd2-4c44-bb9d-9112997a8ef9',
        'Last Name': 'Product / Service Name',
        'Email Address': '',
        'Contact Number': '',
        'Business Name': '',
        'Street 1': '',
        'Street 2': '',
        City: '',
        State: '',
        'Zip Code': '',
        'Deal Size': '',
        'Deal Stage ID': '',
        'Contact Type': '',
        Source: '',
        Status: '',
        'Product Service Type IDs': '',
    })

    if (isProductsAndServiecsSuccess && items?.length > 0) {
        items.forEach((item: SingleProductService) => {
            csvInputGuide.push({
                'First Name': item.productServiceId as string,
                'Last Name': item.name as string,
                'Email Address': '',
                'Contact Number': '',
                'Business Name': '',
                'Street 1': '',
                'Street 2': '',
                City: '',
                State: '',
                'Zip Code': '',
                'Deal Size': '',
                'Deal Stage ID': '',
                'Contact Type': '',
                Source: '',
                Status: '',
                'Product Service Type IDs': '',
            })
        })
    }

    return (
        <CSVLink
            data={csvInputGuide}
            headers={csvHeaders}
            filename={'import-lead-template.csv'}
            className='relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50'
        >
            Download CSV Template
        </CSVLink>
    )
}

export default LeadCSVTemplate
