import { InvoiceType } from '@/api/invoicing/schema'
import { CardTitle } from '@/components/Card'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/Table'
import { cn } from '@/utils/helper'
import { useFormContext } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

const tableHeader = [
    { textAlign: 'text-start', title: 'Service Type', width: 'w-[40%]' },
    { textAlign: 'text-center', title: 'Quantity', width: 'w-[15%]' },
    { textAlign: 'text-center', title: 'Price', width: 'w-[15%]' },
    { textAlign: 'text-center', title: 'Markup %', width: 'w-[15%]' },
    { textAlign: 'text-end', title: 'Total', width: 'w-[15%]' },
]

const ProductsAndServicesTableWithForm = () => {
    const { watch } = useFormContext<InvoiceType>()
    const { pathname } = useLocation()

    const quote = watch()

    return (
        <>
            <CardTitle className='text-lg font-bold mb-4'>Services and Products</CardTitle>
            <div className='p-[20px] bg-clip-content shadow-[inset_0_0_0_20px_#F5F5F5]'>
                <Table className='rounded-lg'>
                    <TableHeader>
                        <TableRow className='whitespace-nowrap !border-0'>
                            {tableHeader?.map((header) => (
                                <TableHead
                                    key={header.title}
                                    className={cn(
                                        header.textAlign,
                                        header.width,
                                        'flex-row font-semibold text-zentive-gray-medium text-base items-start',
                                        pathname.startsWith(
                                            '/financial-management/invoicing/add-invoice',
                                        ) &&
                                            (header.title === 'Price' ||
                                                header.title === 'Markup %') &&
                                            'hidden',
                                    )}
                                >
                                    <div className='inline-flex p-0 m-0'>{header.title}</div>
                                </TableHead>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {quote?.productServiceGroup?.map((data) => (
                            <TableRow
                                key={data.productServiceId}
                                className='whitespace-break-spaces'
                            >
                                <TableCell className='flex-row items-start text-end text-zentive-black text-base'>
                                    <div className='text-left flex flex-col'>
                                        <span className='font-semibold'>{data?.name}</span>
                                        <span className='text-sm text-zentive-gray-medium'>
                                            {data?.description}
                                        </span>
                                    </div>
                                </TableCell>
                                <TableCell className='flex-row items-start text-end text-zentive-black text-base'>
                                    {data?.quantity && data?.quantity > 0 ? data?.quantity : 1}
                                </TableCell>
                                {pathname.startsWith(
                                    '/financial-management/invoicing/view-invoice',
                                ) && (
                                    <>
                                        <TableCell className='flex-row items-start text-end text-zentive-black text-base'>
                                            ${' '}
                                            {data?.unitPrice?.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </TableCell>
                                        <TableCell className='flex-row items-start text-end text-zentive-black text-base'>
                                            {data?.markup}%
                                        </TableCell>
                                    </>
                                )}
                                <TableCell className='flex-row items-start text-end text-zentive-black text-base'>
                                    ${' '}
                                    {data?.cost?.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </>
    )
}

export default ProductsAndServicesTableWithForm
