import { Card, CardContent, CardFooter } from '@/components/Card'
import { useForm } from 'react-hook-form'
import { Form } from '@/components/Forms'
import { Button } from '@/components/Button'
import Fields from './Fields'
import { RichTextEditorWrapper } from './RichTextEditor'
import {
    RequestServiceType,
    customerRequestServiceParamType,
    customerRequestServiceSchema,
} from '@/api/service/schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { sendEmailRequest } from '@/api/service'
import { toast } from '@/hooks/useToast'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

interface RequestServiceFormProps {
    services: RequestServiceType
}

const RequestServiceForm: React.FC<RequestServiceFormProps> = ({ services }) => {
    const navigate = useNavigate()
    const [emptyTextBox, setEmptyTextBox] = useState(true)

    const methods = useForm<customerRequestServiceParamType>({
        mode: 'onSubmit',
        resolver: zodResolver(customerRequestServiceSchema),
    })

    useEffect(() => {
        methods.setValue('email', services?.ownerEmail!)
        methods.setValue('senderName', `${services?.firstName} ${services?.lastName}`)
        methods.setValue(
            'address',
            `${services?.streetAddress}, ${services?.city}, ${services?.state} ${services?.zipCode}`,
        )
    }, [services])

    const { mutate: sendEmailMu } = useMutation<
        ResponseType,
        AxiosError,
        customerRequestServiceParamType
    >({
        mutationKey: ['SendMessage'],
        mutationFn: (data) => sendEmailRequest(data),
        onSuccess: () => {
            toast({
                description: 'Request Sent!',
                variant: 'default',
            })
            // setSelected([])
            navigate('/customer-portal/dashboard')
        },
    })

    const onSubmit = (data: customerRequestServiceParamType) => {
        sendEmailMu(data)
    }

    return (
        <Form {...methods}>
            <Card className='w-[50%] lg:max-w-[420px] xl:max-w-[490px] 2xl:max-w-[746px]'>
                <CardContent className='p-4 pt-[36px] space-y-8'>
                    <Fields services={services} />
                    <RichTextEditorWrapper service={services} setEmptyBox={setEmptyTextBox} />
                </CardContent>
                <CardFooter className='p-4 mt-[20px] lg:flex lg:justify-end rounded-b-md bg-zentive-gray-light'>
                    <div className='flex space-x-4 my-auto'>
                        <Button
                            onClick={methods.handleSubmit(onSubmit)}
                            type='submit'
                            className='w-full'
                            disabled={emptyTextBox}
                        >
                            Submit
                        </Button>
                    </div>
                </CardFooter>
            </Card>
        </Form>
    )
}
export default RequestServiceForm
