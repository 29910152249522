import { z } from 'zod'
import { profileSchema } from '../profile/schema'
import { jobSchema } from '../job/schema'
import { phoneNumberValidator } from '@/utils/regex'
import { productServiceSchema, productServiceSchemaOnSettingsSchema } from '../quoting/schema'
import { PaginationSchema } from '@/components/Pagination/schema'

export const requestServiceSchema = z.object({
    profileId: z.string(),
    businessId: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    phoneNumber: phoneNumberValidator,
    ownerEmail: z.string(),
    streetAddress: z.string(),
    streetTwoAddress: z.string().optional(),
    city: z.string(),
    state: z.string(),
    zipCode: z.string(),
    services: z.array(productServiceSchema),
})

export const emailServiceParamSchema = z.object({
    email: z.string(),
    senderName: z.string(),
    address: z.string(),
    body: z.string(),
    jobId: z.string()
})

export const customerRequestServiceSchema = z.object({
    email: z.string(),
    senderName: z.string(),
    address: z.string(),
    body: z.string(),
})


export const workServiceHistoryDataSchema = z.object({
    crewDetails: profileSchema,
    jobHistory: z.array(jobSchema),
})

export const WorkServiceHistorySchema = z.object({
    data: workServiceHistoryDataSchema,
    message: z.string(),
})

export const productServiceListSchema = z.object({
    content: z.array(productServiceSchema),
    meta: PaginationSchema,
})

export type ProductServiceListType = z.infer<typeof productServiceListSchema>
export type ProductServiceType = z.infer<typeof productServiceSchema>
export type RequestServiceType = z.infer<typeof requestServiceSchema>
export type EmailServiceParamType = z.infer<typeof emailServiceParamSchema>
export type customerRequestServiceParamType = z.infer<typeof customerRequestServiceSchema>
export type WorkServiceHistoryType = z.infer<typeof WorkServiceHistorySchema>
export type ProductServiceOnSettingsType = z.infer<typeof productServiceSchemaOnSettingsSchema>
