import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { getPricingById, checkCustomerPaymentMethod } from '@/api/subscription'
import { SubscriptionType } from '@/api/subscription/schema'
import ChangePlanButton from './ChangePlanButton'
import { ProfileSelfResponseType } from '@/api/business/schema'
import { getBusinessProfile } from '@/api/profile'
import useViewedAsUser from '@/hooks/useViewedAsUser'
import Spinner from '@/components/Spinner'

const ActionButton = ({ selected }: { selected: SubscriptionType[] }) => {
    const user = useViewedAsUser()
    const { customerId = '', profileId = '' } = user

    const {
        data: businessProfile,
        isSuccess: isBusinessProfileSuccess,
        isError: isBusinessProfileError,
        error: businessProfileError,
    } = useQuery<unknown, AxiosError, ProfileSelfResponseType>({
        enabled: !!profileId,
        queryKey: ['businessProfile', profileId],
        queryFn: () => getBusinessProfile(profileId),
    })

    const {
        data: currentPlan,
        isError: isPricingByIdError,
        error: pricingByIdError,
    } = useQuery<unknown, AxiosError, SubscriptionType>({
        enabled: !!businessProfile?.business?.subscriptionTypeId && isBusinessProfileSuccess,
        queryKey: ['pricingById', businessProfile?.business?.subscriptionTypeId],
        queryFn: () => getPricingById(businessProfile?.business.subscriptionTypeId as string),
    })

    const {
        data: hasPayment,
        isSuccess: isHasPaymentSuccess,
        isError: isHasPaymentError,
        error: hasPaymentError,
    } = useQuery({
        enabled: !!customerId,
        queryKey: ['hasPayment', customerId],
        queryFn: () => checkCustomerPaymentMethod(customerId),
    })

    const hasError = isBusinessProfileError || isPricingByIdError || isHasPaymentError

    if (hasError) {
        const errorMessage =
            (businessProfileError || pricingByIdError || hasPaymentError)?.message ||
            'An error occurred'
        return <p className='text-[16px] text-zentive-red-dark font-semibold'>{errorMessage}</p>
    }

    if (!currentPlan) return <Spinner size='50' variant='normal' />

    const renderChangePlanButton = (plan: SubscriptionType, changePlanType: string) => (
        <ChangePlanButton
            hasPayment={isHasPaymentSuccess ? hasPayment : false}
            plan={plan}
            changePlanType={changePlanType}
            isChangePlan={businessProfile?.business?.isChangePlan as string}
        />
    )

    const getChangePlanType = (plan: SubscriptionType) => {
        if (currentPlan.name === plan.name) {
            return currentPlan.interval === plan.interval
                ? 'Current Plan'
                : plan.interval === 'month'
                  ? 'Downgrade'
                  : 'Upgrade'
        }
        return currentPlan.interval === 'month' ? 'Downgrade' : 'Upgrade'
    }

    const renderPlanButtons = () => {
        return selected
            ?.sort((a, b) => {
                const order = ['Starter', 'Standard', 'Growth Pro']
                return order.indexOf(a.name as string) - order.indexOf(b.name as string)
            })
            ?.map((plan) => (
                <div key={plan?.subscriptionTypeId}>
                    {renderChangePlanButton(plan, getChangePlanType(plan))}
                </div>
            ))
    }

    return (
        <div className='flex justify-end w-full'>
            <div className='inline-flex xl:gap-x-[22px] 2xl:gap-x-[85px] w2xl:gap-x-[110px] 3xl:gap-x-[195px] 4xl:gap-x-[408px]'>
                {renderPlanButtons()}
            </div>
        </div>
    )
}

export default ActionButton
